import Vue from 'vue'
import Vuex from 'vuex'

import * as getters from './getters'
import mutations from './mutations'
import actions from './actions'
import auth from './modules/auth'
import candidate from './modules/candidate'
import test from './modules/test'
import plan from './modules/plan'
import tag from './modules/tag'
import country from './modules/country'
import campaign from './modules/campaign'
import product from './modules/product'
import customer from './modules/customer'
import global from './modules/global'
import affiliate from './modules/affiliate'

Vue.use(Vuex)

const initialState = {
  isAppLoaded: false,
  global_settings: null
}

export default new Vuex.Store({
  strict: true,
  state: initialState,
  getters,
  mutations,
  actions,

  modules: {
    auth,
    candidate,
    test,
    plan,
    country,
    tag,
    campaign,
    product,
    customer,
    global,
    affiliate
  }
})
