import * as types from './mutation-types'
//import * as appType from '../../mutation-types'
//import jwtToken from "../../../helpers/jwt-token";

// eslint-disable-next-line no-unused-vars
export const fetchPlans = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    window.axios.get('/api/plans').then((res) => {
      commit(types.SET_PLANS, res.data.data);
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
