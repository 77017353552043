<template>
    <v-container>
        <v-card
            :loading="isLoading"
            elevation="5"
            outlined
        >
            <v-card-title>
                Email Verification
            </v-card-title>
            <v-card-text>
                <div v-if="isLoading">
                    <p class="text-center">Verifying ...</p>
                </div>
                <div v-else-if="!isLoading && verified">
                    <p class="text-left mb-3">Your email has been verified. Please login again.</p>
                    <router-link :to="{name: 'login'}" style="text-decoration: none;">
                        <v-btn
                        block
                        class="theme-btn-color text-transform-unset"
                        >
                            Login
                        </v-btn>
                    </router-link>
                </div>
                <div v-else-if="!isLoading && !verified">
                    <p class="text-left mb-3">Sorry, the verification token expired within 72 hours of the initial verification email. Please register again to receive new verification email.</p>
                    <router-link :to="{name: 'login', query: { register:true }}" style="text-decoration: none;">
                        <v-btn
                        block
                        class="theme-btn-color text-transform-unset"
                        >
                            Register
                        </v-btn>
                    </router-link>
                </div>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: "VerifiedUser",

    data() {
      return {
        message: null,
        token: null,
        isLoading: false,
        verified: false,
      }
    },

    mounted () {
      // check if route has token
      console.log('here');
      if (typeof this.$route.params.token !== 'undefined') {
        // has token param, change password mode
        this.token = this.$route.params.token;
        this.isLoading = true;

        this.verify(this.token)
          .then((res) => {
            if (res.data.success) {
              this.isLoading = false
              window.toastr['success']('Account verified, please proceed to login')
              this.verified = res.data.success;
            }
          })
          .catch((err) => {
            if (typeof err.response != 'undefined' && err.response.status === 422) {
              // eslint-disable-next-line no-unused-vars
              Object.keys(this.errors).forEach((key, index) => {
                if (typeof err.response.data[key] !== 'undefined') {
                  window.toastr['error'](err.response.data[key])
                }
              });
            }
            this.isLoading = false
          })
      }
    },
    methods: {
      ...mapActions('auth', [
        'verify'
      ]),
      verifyEmail() {

      }
    }
  }
</script>

<style scoped>

</style>
