<template>
  <div class="pa-4">
    <v-row>
      <v-col>
        <div class="d-flex align-center">
          <v-btn
            class="mr-5 pa-1 form-button"
            href="/products/Index"
            height="auto"
            min-width="auto"
          >
            <v-icon>
              mdi-arrow-left
            </v-icon>
          </v-btn>
          <div class="text--primary text-h5">New Product</div>
        </div>
      </v-col>
    </v-row>
    <v-container class="pa-0" fluid>
      <v-form ref="productFrom" v-model="valid">
        <v-row>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : ''">
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <div class="text--primary font-weight-black">
                      Product Name
                    </div>
                    <v-text-field
                      v-model="formData.name"
                      label="Product name"
                      :rules="[
                        (value) => !!value || 'Product Name is required.',
                      ]"
                      outlined
                      dense
                      flat
                      hide-details="auto"
                      solo
                    ></v-text-field>
                    <div class="text--primary mt-4 font-weight-black">
                      Description
                    </div>
                    <v-textarea
                      v-model="formData.description"
                      label="Description"
                      :rules="[
                        (value) =>
                          !!value || 'Product Description is required.',
                      ]"
                      outlined
                      flat
                      auto-grow
                      clearable
                      hide-details="auto"
                      solo
                    ></v-textarea>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <div class="text--primary font-weight-black mb-3">
                          Media
                        </div>
                      </v-col>
                      <v-col>
                        <div class="d-flex align-center justify-end">
                          <a
                            v-if="formData.is_image_upload"
                            @click="switchFileUpload"
                          >
                            Add media from URL
                            <v-icon color="blue">
                              mdi-chevron-down
                            </v-icon>
                          </a>
                          <a v-else @click="switchFileUpload">
                            Add media from Computer
                            <v-icon color="blue">
                              mdi-chevron-down
                            </v-icon>
                          </a>
                        </div>
                      </v-col>
                    </v-row>
                    <div v-if="formData.is_image_upload">
                      <div v-if="!formData.product_image">
                        <div
                          :class="['dropZone', dragging ? 'dropZone-over' : '']"
                          @dragenter="dragging = true"
                          @dragleave="dragging = false"
                        >
                          <div class="dropZone-info" @drag="onChange">
                            <span
                              class="fa fa-cloud-upload dropZone-title"
                            ></span>
                            <span class="dropZone-title"
                              >Drop file or click to upload</span
                            >
<!--                            <div class="dropZone-upload-limit-info">
                              <div>maximum file size: 5 MB</div>
                            </div>-->
                          </div>
                          <input type="file" @change="onChange" />
                        </div>
                      </div>
                      <div v-else class="dropZone-uploaded">
                        <div class="dropzone-preview">
                          <img :src="product_image" />
                        </div>
                        <div class="dropZone-uploaded-info">
                          <button
                            type="button"
                            class="btn btn-primary removeFile"
                            @click="removeFile"
                          >
                            Remove File
                          </button>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <v-text-field
                        v-model="formData.product_image"
                        label="Image URL"
                        outlined
                        dense
                        flat
                        hide-details="auto"
                        solo
                      ></v-text-field>
                    </div>

                    <div class="uploadedFile-info">
                      <!-- <div>fileName: {{ file.name }}</div>
                      <div>fileZise(bytes): {{ file.size }}</div>
                      <div>extension：{{ extension }}</div> -->
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <div class="text--primary font-weight-black mb-3">
                      Pricing
                    </div>
                    <v-row>
                      <v-col>
                        <div class="text--primary">Price</div>
                        <v-text-field
                          v-model="formData.price"
                          :rules="[
                            (value) => !!value || 'Product Price is required.',
                          ]"
                          label="0.00"
                          outlined
                          dense
                          flat
                          hide-details="auto"
                          solo
                          prefix="RM"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <div class="text--primary">Compare at price</div>
                        <v-text-field
                          v-model="formData.compare_price"
                          label="0.00"
                          outlined
                          dense
                          flat
                          hide-details="auto"
                          solo
                          prefix="RM"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-checkbox
                      v-model="formData.taxable"
                      class="text--primary"
                      hide-details="auto"
                      label="Charge Tax"
                    ></v-checkbox>
                    <v-divider class="mt-2"></v-divider>
                    <v-checkbox
                      v-model="formData.dynamic_price"
                      class="mt-2"
                      hide-details="auto"
                      label="Dynamic Pricing"
                    ></v-checkbox>
                    <div class="text--primary mt-4">
                      Type
                    </div>
                    <v-select
                      v-model="formData.pricing_type_id"
                      :rules="[
                        (value) => !!value || 'Product Type is required.',
                      ]"
                      :items="getProductPricingType"
                      label="Price increase over time"
                      outlined
                      flat
                      dense
                      hide-details="auto"
                      solo
                    ></v-select>
                    <v-row class="mt-2">
                      <v-col>
                        <div class="text--primary">Price change</div>
                        <v-text-field
                          v-model="formData.price_change"
                          label="0.00"
                          outlined
                          dense
                          flat
                          hide-details="auto"
                          solo
                          prefix="+RM"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <div class="text--primary">Over Time</div>
                        <v-select
                          label="Please pick one"
                          v-model="formData.over_time"
                          :items="getProductOverTime"
                          outlined
                          dense
                          flat
                          hide-details="auto"
                          solo
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <div class="text--primary mb-3 font-weight-black">
                      Inventory
                    </div>
                    <div class="text--primary">Type</div>
                    <v-select
                      v-model="formData.inventory_type_id"
                      :items="getProductTypes"
                      :rules="[
                        (value) => !!value || 'Inventory Type is required.',
                      ]"
                      item-value="id"
                      item-text="name"
                      label="Physical product"
                      outlined
                      dense
                      flat
                      hide-details="auto"
                      solo
                    ></v-select>
                    <div class="text--primary mt-3">
                      SKU (Stock Keeping Unit)
                    </div>
                    <v-text-field
                      v-model="formData.sku"
                      label="1234"
                      outlined
                      dense
                      flat
                      hide-details="auto"
                      solo
                    ></v-text-field>
                    <v-checkbox
                      v-model="formData.track_quantity"
                      class="text--primary"
                      hide-details="auto"
                      label="Track Quantity"
                    ></v-checkbox>
                    <v-divider class="mt-2"></v-divider>
                    <div class="text--primary mt-3 mb-3 font-weight-black">
                      Quantity
                    </div>
                    <div class="text--primary">Available</div>
                    <v-text-field
                      v-model="formData.quantity"
                      :rules="[
                        (value) => !!value || 'Product Quantity is required.',
                      ]"
                      label="0"
                      outlined
                      dense
                      flat
                      hide-details="auto"
                      solo
                      class="w-50"
                      type="number"
                    ></v-text-field>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <div class="text--primary font-weight-black">
                      Variants
                    </div>
                    <v-checkbox
                      v-model="formData.has_variants"
                      class="text--primary"
                      hide-details="auto"
                      label="Has Different Variants"
                    ></v-checkbox>
                    <div v-if="formData.has_variants">
                      <v-divider class="mt-2"></v-divider>
                      <div class="text--primary my-3 font-weight-black">
                        Options
                      </div>
                      <div v-if="formData.options.length === 0">
                        No options
                      </div>
                      <div
                        v-else
                        v-for="(option, index) in formData.options"
                        :key="option"
                      >
                        <div class="text--primary">Option {{ index + 1 }}</div>
                        <v-row>
                          <v-col cols="4">
                            <v-text-field
                              v-model="option.name"
                              placeholder="Size"
                              outlined
                              dense
                              flat
                              hide-details="auto"
                              solo
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="option.value"
                              label="Separate options with a comma"
                              outlined
                              dense
                              flat
                              hide-details="auto"
                              solo
                            ></v-text-field>
                          </v-col>
                          <v-col cols="1">
                            <v-btn color="error" @click="deleteOption(index)">
                              <v-icon class="mr-2">mdi-close</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </div>

                      <v-divider class="mt-4"></v-divider>
                      <v-btn
                        class="form-button mt-4 pa-2"
                        flat
                        outlined
                        @click="addOption"
                        >Add option</v-btn
                      >
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : ''">
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <div class="text--primary font-weight-black">
                      Product Status
                    </div>
                    <v-select
                      v-model="formData.status_id"
                      label="Status"
                      :items="getProductStatuses"
                      :rules="[
                        (value) => !!value || 'Product Status is required.',
                      ]"
                      outlined
                      dense
                      flat
                      solo
                      hide-details="auto"
                    ></v-select>
                    <p class="mt-3 mb-0">
                      Draft status is hidden from campaign.
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <div class="text--primary font-weight-black">
                      Commision Group
                    </div>
                    <v-select
                      label="Group Name"
                      outlined
                      dense
                      flat
                      solo
                      hide-details="auto"
                    ></v-select>
                    <v-divider class="mt-4"></v-divider>
                    <div class="text--primary mt-3 font-weight-black">
                      Affiliate Group
                    </div>
                    <v-select
                      label="Group Name"
                      outlined
                      dense
                      flat
                      solo
                      hide-details="auto"
                    ></v-select>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <div class="text--primary font-weight-black">
                          Tags
                        </div>
                      </v-col>
                      <v-col>
                        <div class="d-flex align-center justify-end">
                          <a>
                            View all tags
                          </a>
                        </div>
                      </v-col>
                    </v-row>
                    <v-text-field
                      v-model="tagValue"
                      class="mb-5"
                      label="VIP, sale, segment, etc"
                      outlined
                      dense
                      flat
                      solo
                      hide-details="auto"
                      @keydown.enter="addTag"
                    ></v-text-field>
                    <v-chip
                      close
                      class="mr-2"
                      outlined
                      v-for="(tag, index) in formData.product_tags"
                      :key="tag"
                      @click:close="formData.product_tags.splice(index, 1)"
                    >
                      <span>{{ tag }}</span>
                    </v-chip>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? '10' : ''">
            <v-divider class="mt-0"></v-divider>
            <div class="d-flex align-center justify-end mt-5">
              <div>
                <v-btn
                  class="ml-3"
                  color="primary"
                  dark
                  depressed
                  :loading="isLoading"
                  @click="onSubmit"
                >
                  Save
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import utilities from "../../helpers/utilities";
  export default {
    data: () => ({
      valid: false,
      tagValue: null,
      allTags: [],
      isLoading: false,
      file: "",
      dragging: false,
      list: null,
      product_image: null,
      page: 1,
      pageCount: 0,
      productPerPage: 10,
      isEdit: false,
      formData: {
        id: null,
        name: null,
        status_id: null,
        is_image_upload: true,
        description: null,
        product_image: null,
        price: 0,
        compare_price: 0,
        price_change: 0,
        taxable: false,
        dynamic_price: false,
        over_time: null,
        inventory_type_id: null,
        pricing_type_id: null,
        sku: null,
        track_quantity: false,
        quantity: null,
        has_variants: false,
        commission_group_id: null,
        affiliate_group_id: null,
        options: [],
        product_tags: [],
      },
      cards: [
        { title: "Total", icon: "mdi-arrow-left", text: "12" },
        { title: "Digital", icon: "mdi-thumb-down", text: "8" },
        { title: "Physical", icon: "mdi-thumb-up", text: "4" },
        { title: "Archived", icon: "mdi-minus-circle", text: "2" },
      ],
    }),
    mounted() {
      if (this.$route.query.product_id) {
        this.isEdit = true;
        this.formData.id = this.$route.query.product_id;
        this.loadProduct();
      }
    },
    computed: {
      ...mapGetters("product", [
        "getProductTypes",
        "getProductStatuses",
        "getProductOverTime",
        "getProductPricingType",
      ]),
      dateRangeText() {
        return this.datePick.join(" - ");
      },
      extension() {
        return this.formData.product_image
          ? this.formData.product_image.name.split(".").pop()
          : "";
      },
    },
    methods: {
      ...mapActions("product", [
        "fetchProduct",
        "postProduct",
        "updateProduct",
      ]),
      loadProduct() {
        this.isLoading = true;
        this.fetchProduct(this.formData.id).then((res) => {
          if (res.data.success) {
            let product = res.data.product;
            this.formData.name = product.name;
            this.formData.status_id = product.status;
            this.formData.description = product.description;
            this.formData.is_image_upload = product.is_image_upload;
            this.formData.product_image = product.product_image;
            this.formData.price = product.price.replace(/[^\d.-]/g, "");
            this.formData.compare_price = product.compare_price.replace(
              /[^\d.-]/g,
              ""
            );
            this.formData.price_change = product.price_change.replace(
              /[^\d.-]/g,
              ""
            );
            this.formData.taxable = product.taxable;
            this.formData.dynamic_price = product.dynamic_price;
            this.formData.over_time = product.over_time_name;
            this.formData.inventory_type_id = product.inventory_type_id;
            this.formData.pricing_type_id = product.pricing_type;
            this.formData.track_quantity = product.track_quantity;
            this.formData.quantity = product.quantity;
            this.formData.has_variants = product.has_variants;
            this.formData.options = product.product_options;

            product.tags.forEach((obj) => {
              this.formData.product_tags.push(obj.name);
            });
            //this.formData.commission_group_id = product.commission_group_id
            //this.formData.affiliate_group_id = product.affiliate_group_id
          }

          this.isLoading = false;
        });
      },
      onSubmit() {
        this.$refs.productFrom.validate();

        if (!this.valid) {
          window.toastr["error"]("Please fill up all the required field.");
          return true;
        }
        this.isLoading = true;

        let formData = new FormData();
        utilities.buildFormData(formData, this.formData); //for file field
        if (!this.isEdit) {
          this.postProduct(formData)
            .then((res) => {
              if (res.data.success) {
                window.toastr["success"]("Product added successfully.");
                this.$router.push({ name: "products.index" });
              } else {
                window.toastr["success"]("Failed to add product.");
              }
              this.isLoading = false;
            })
            .catch((err) => {
              if (
                typeof err.response != "undefined" &&
                err.response.status === 400 &&
                err.response.data.message
              ) {
                // eslint-disable-next-line no-unused-vars
                Object.keys(err.response.data.message).forEach((key, index) => {
                  if (typeof err.response.data.message[key] !== "undefined") {
                    window.toastr["error"](err.response.data.message[key]);
                  }
                });
              }
              this.isLoading = false;
            });
        } else {
          this.updateProduct(formData)
            .then((res) => {
              if (res.data.success) {
                window.toastr["success"]("Product updated successfully.");
                this.$router.push({ name: "products.index" });
              } else {
                window.toastr["success"]("Failed to update product.");
              }
              this.isLoading = false;
            })
            .catch((err) => {
              if (
                typeof err.response != "undefined" &&
                err.response.status === 400 &&
                err.response.data.message
              ) {
                // eslint-disable-next-line no-unused-vars
                Object.keys(err.response.data.message).forEach((key, index) => {
                  if (typeof err.response.data.message[key] !== "undefined") {
                    window.toastr["error"](err.response.data.message[key]);
                  }
                });
              }
              this.isLoading = false;
            });
        }
      },
      getColor(type) {
        if (type == "Archived") return "grey";
        else return "green";
      },
      onChange(e) {
        var files = e.target.files || e.dataTransfer.files;

        if (!files.length) {
          this.dragging = false;
          return;
        }

        this.createFile(files[0]);
      },
      switchFileUpload() {
        this.formData.is_image_upload = !this.formData.is_image_upload;
      },
      createFile(file) {
        if (file.size > 5000000) {
          alert("please check file size no over 5 MB.");
          this.dragging = false;
          return;
        }

        this.formData.product_image = file;
        this.dragging = false;

        var reader = new FileReader();

        reader.onload = (e) => {
          this.product_image = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      removeFile() {
        this.formData.product_image = null;
      },
      addTag() {
        this.formData.product_tags.push(this.tagValue);
        this.tagValue = null;
      },
      addOption() {
        this.formData.options.push({
          name: null,
          value: null,
        });
      },
      deleteOption(key) {
        this.formData.options.splice(key, 1);
      },
    },
  };
</script>

<style scoped>

</style>
