<template>
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.2393 7.16663C15.2393 5.84054 14.7125 4.56877 13.7748 3.63109C12.8371 2.69341 11.5653 2.16663 10.2393 2.16663C8.91318 2.16663 7.64141 2.69341 6.70372 3.63109C5.76604 4.56877 5.23926 5.84054 5.23926 7.16663C5.23926 13 2.73926 14.6666 2.73926 14.6666H17.7393C17.7393 14.6666 15.2393 13 15.2393 7.16663Z" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.6812 18C11.5347 18.2526 11.3244 18.4622 11.0714 18.6079C10.8184 18.7537 10.5315 18.8304 10.2395 18.8304C9.94754 18.8304 9.66068 18.7537 9.40766 18.6079C9.15465 18.4622 8.94436 18.2526 8.79785 18" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "app-bell-icon"
});
</script>

<style scoped>

</style>
