import { render, staticRenderFns } from "./appPackageIcon.vue?vue&type=template&id=c93cc930&scoped=true&"
import script from "./appPackageIcon.vue?vue&type=script&lang=js&"
export * from "./appPackageIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c93cc930",
  null
  
)

export default component.exports