<template>
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.3333 3H1.66666L8.33332 10.8833V16.3333L11.6667 18V10.8833L18.3333 3Z" stroke="#0075FF" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "app-filter-icon"
});
</script>

<style scoped>

</style>
