<template>
  <div id="dashboard-page" class="pa-4">
    <v-row>
      <v-col :cols="$vuetify.breakpoint.smAndUp ? '' : '12'">
        <h2>Dashboard</h2>
      </v-col>
      <v-col>
        <div
          :class="
            $vuetify.breakpoint.smAndUp
              ? 'd-flex align-center justify-end'
              : 'd-flex align-center '
          "
        >
          <div>Monitoring period:</div>
          <div class="w-50">
            <v-menu
              ref="menu"
              :close-on-content-click="false"
              :return-value.sync="datePick"
              transition="scale-transition"
              origin="top right"
              offset-y
              left
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  v-model="dateRangeText"
                  label="Date Range"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  class="ml-3 white"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="datePick" no-title scrollable range>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(datePick)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-container class="pa-0" fluid>
      <v-row class="campaign-card-row">
        <v-col
          v-for="card in cards"
          :key="card"
          :cols="12"
          :sm="4"
          :md="4"
          :lg="3"
          class="px-5"
        >
          <v-card :loading="isLoading">
            <v-row no-gutters>
              <v-col md="2" cols="4" class="campaign-card-icon">
                <v-btn fab icon class="blue lighten-5 ml-2" width="40px">
                  <v-icon color="blue">{{ card.icon }}</v-icon>
                </v-btn>
              </v-col>
              <v-col md="10" cols="8">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-card-title class="text-h5 pb-0" v-text="card.text">
                    </v-card-title>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col>
                        <v-card-subtitle
                          class="font-weight-bold pt-0"
                          v-text="card.title"
                        >
                        </v-card-subtitle>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-row class="mt-5">
      <v-col :cols="$vuetify.breakpoint.smAndUp ? '' : '8'">
        <h2>All Campaigns</h2>
      </v-col>
      <v-col class="text-right">
        <v-btn color="primary" :to="{ name: 'campaign.create' }" depressed dark>
          <v-icon v-if="!$vuetify.breakpoint.mdAndUp"> mdi-plus </v-icon>
          <div v-else>+ New Campaign</div>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="campaign-card-row">
      <v-col
        v-for="campaign in campaigns"
        :key="campaign"
        class="px-5 my-1"
        :cols="$vuetify.breakpoint.smAndUp ? '3' : '12'"
      >
        <v-card
          class="mx-auto"
          max-width="400"
          elevation="0"
          color="transparent"
        >
          <v-img
            class="white--text align-end rounded-lg elevation-2"
            height="200px"
            :src="campaign.image"
          >
          </v-img>
          <div>
            <v-row class="mt-1">
              <v-col>
                <div class="text-subtitle-2 pb-0">
                  <div>{{ campaign.campaign_name }}</div>
                </div>
                <div
                  class="py-0 text-caption grey--text text-decoration-underline"
                >
                  {{ campaign.campaign_domain }}
                </div>
              </v-col>
              <v-col class="d-flex justify-end">
                <v-menu top :offset-y="true">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="campaign-card-option-btn"
                      elevation="2"
                      fab
                      icon
                      color="black"
                      outlined
                      absolute
                      text
                      width="40px"
                      v-bind="attrs"
                      v-on="on"
                      :loading="isLoading"
                    >
                      <v-icon color="black">mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>

                  <v-list min-width="150px">
                    <v-list-item
                      link
                      v-if="campaign.campaign_status_name === 'Publish'"
                      @click="changeCampaignStatus(campaign.id, 'Unpublish')"
                    >
                      Unpublish
                    </v-list-item>
                    <v-list-item
                      dense
                      link
                      v-else-if="campaign.campaign_status_name === 'Unpublish'"
                      @click="changeCampaignStatus(campaign.id, 'Publish')"
                    >
                      Publish
                    </v-list-item>
                    <v-list-item
                      dense
                      link
                      v-else
                      @click="changeCampaignStatus(campaign.id, 'Draft')"
                      >Draft</v-list-item
                    >
                    <v-list-item dense link @click="onPreview(campaign)"
                      >Preview</v-list-item
                    >
                    <v-list-item
                      dense
                      link
                      :to="{
                        name: 'campaign.setting',
                        query: { campaign_id: campaign.id },
                      }"
                      >Setting</v-list-item
                    >
                    <v-list-item
                      dense
                      link
                      class="bg-black"
                      :to="{
                        name: 'funnel.builder',
                        query: { campaign_id: campaign.id },
                      }"
                    >
                      Funnel Builder
                      <v-icon class="pl-2" small>mdi-hammer-wrench</v-icon>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      dense
                      link
                      class="delete-item"
                      @click="removeCampaign(campaign.id)"
                    >
                      <v-list-item-icon>
                        <v-icon color="red">mdi-trash-can-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content> Delete </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapActions } from "vuex";

  export default {
    data: () => ({
      isLoading: false,
      datePick: ["", ""],
      cards: [
        { name:"total_sales", title: "Total Sales", icon: "mdi-cash", text: "12" },
        { name:"new_customer", title: "New Customers", icon: "mdi-account-group", text: "RM14,200" },
        { name:"total_campaign", title: "Total Campaign", icon: "mdi-book-open-page-variant", text: "503" },
        { name:"subscribe_customer", title: "Subscribed Customer", icon: "mdi-newspaper-variant-outline", text: "12" },
        //{ title: "Support Ticket", icon: "mdi-cancel", text: "0" },
      ],
      campaigns: [],
    }),
    computed: {
      dateRangeText() {
        return this.datePick.join(" - ");
      },
    },
    mounted() {
      this.loadCampaign();
    },
    methods: {
      ...mapActions("campaign", [
        "fetchCampaigns",
        "updateCampaignStatus",
        "deleteCampaign",
      ]),
      loadCampaign() {
        this.isLoading = true;
        this.fetchCampaigns()
          .then((res) => {
            if (res.data.success) {
              this.campaigns = res.data.campaigns;
              this.cards.forEach((obj, key) => {
                this.cards[key].text = res.data.analytics[obj.name]
              });
            } else {
              window.toastr["error"]("Failed to fetch campaigns.");
            }
            this.isLoading = false;
          })
          .catch((err) => {
            console.log(err);
            this.isLoading = false;
            window.toastr["error"]("Failed to fetch campaigns.");
          });
      },
      changeCampaignStatus(id, status) {
        this.isLoading = true;
        this.updateCampaignStatus({ id: id, status: status })
          .then((res) => {
            if (res.data.success) {
              this.loadCampaign();
              window.toastr["success"]("Campaign updated successfully.");
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            window.toastr["error"]("Failed to update campaigns.");
          });
      },
      removeCampaign(id) {
        this.isLoading = true;

        this.$swal({
          title: "Delete this campaign?",
          text: "Are you sure? You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Yes, Delete it!",
        }).then((result) => {
          // <--
          if (result.value) {
            // <-- if confirmed
            this.deleteCampaign(id)
              .then((res) => {
                if (res.data.success) {
                  this.loadCampaign();
                  window.toastr["success"]("Campaign deleted successfully.");
                }
                this.isLoading = false;
              })
              .catch((err) => {
                console.log(err);
                this.isLoading = false;
                window.toastr["error"]("Failed to delete campaigns.");
              });
          }
        });
      },
      onPreview(campaign) {
        window.open("http://" + campaign.campaign_domain);
      },
    },
  };
</script>

<style lang="scss">
  .delete-item {
    .v-list-item__icon {
      margin-right: 5px !important;
    }
    .v-list-item__content {
      color: #f44336;
    }
  }
</style>
