import * as types from './mutation-types'

export default {
    [types.SET_COUNTRIES](state, countries) {
        state.countries = countries
    },
    [types.SET_TIMEZONES](state, timezones) {
        state.timezones = timezones
    },
}
