import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import appGoogleIcon from './icons/appGoogleIcon'
import appFacebookIcon from './icons/appFacebookIcon'
import appHomeIcon from './icons/appHomeIcon'
import appPackageIcon from './icons/appPackageIcon'
import appUsersIcon from './icons/appUsersIcon'
import appShareIcon from './icons/appShareIcon'
import appZapIcon from './icons/appZapIcon'
import appLinkIcon from './icons/appLinkIcon'
import appCreditCardIcon from './icons/appCreditCardIcon'
import appSettingIcon from './icons/appSettingIcon'
import appBellIcon from './icons/appBellIcon'
import appLifeBuoyIcon from './icons/appLifeBuoyIcon'
import appSearchIcon from './icons/appSearchIcon'
import appChevronDownIcon from './icons/appChevronDownIcon'
import appVectorStrokeIcon from './icons/appVectorStrokeIcon'
import appArrowBackIcon from './icons/appArrowBackIcon'
import appFilterIcon from './icons/appFilterIcon'
import appBarChartIcon from './icons/appBarChartIcon'
import appHelpCircleIcon from './icons/appHelpCircleIcon'
import appCheckIcon from './icons/appCheckIcon'
import appMousePointerIcon from './icons/appMousePointerIcon'
import appPlusIcon from './icons/appPlusIcon'
import appLayerIcon from './icons/appLayerIcon'
import appZoomOutIcon from './icons/appZoomOutIcon'
import appZoomInIcon from './icons/appZoomInIcon'
import appFilePlusIcon from './icons/appFilePlusIcon'
import appMailIcon from './icons/appMailIcon'
import appClockIcon from './icons/appClockIcon'
import appFileIcon from './icons/appFileIcon'
import appTagIcon from './icons/appTagIcon'
import appSendIcon from './icons/appSendIcon'
import appMessageCircleIcon from './icons/appMessageCircleIcon'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    values: {
      appGoogleIcon: {component: appGoogleIcon},
      appFacebookIcon: {component: appFacebookIcon},
      appHomeIcon: {component: appHomeIcon},
      appPackageIcon: {component: appPackageIcon},
      appUsersIcon: {component: appUsersIcon},
      appShareIcon: {component: appShareIcon},
      appZapIcon: {component: appZapIcon},
      appLinkIcon: {component: appLinkIcon},
      appCreditCardIcon: {component: appCreditCardIcon},
      appSettingIcon: {component: appSettingIcon},
      appSearchIcon: {component: appSearchIcon},
      appBellIcon: {component: appBellIcon},
      appLifeBuoyIcon: {component: appLifeBuoyIcon},
      appChevronDownIcon: {component: appChevronDownIcon},
      appVectorStrokeIcon: {component: appVectorStrokeIcon},
      appArrowBackIcon: {component: appArrowBackIcon},
      appFilterIcon: {component: appFilterIcon},
      appBarChartIcon: {component: appBarChartIcon},
      appHelpCircleIcon: {component: appHelpCircleIcon},
      appCheckIcon: {component: appCheckIcon},
      appMousePointerIcon: {component: appMousePointerIcon},
      appPlusIcon: {component: appPlusIcon},
      appLayerIcon: {component: appLayerIcon},
      appZoomOutIcon: {component: appZoomOutIcon},
      appZoomInIcon: {component: appZoomInIcon},
      appFilePlusIcon: {component: appFilePlusIcon},
      appMailIcon: {component: appMailIcon},
      appClockIcon: {component: appClockIcon},
      appFileIcon: {component: appFileIcon},
      appTagIcon: {component: appTagIcon},
      appSendIcon: {component: appSendIcon},
      appMessageCircleIcon: {component: appMessageCircleIcon},
    }
  },
});
