<template>
  <div class="pa-4">
    <v-row>
      <v-col>
        <div class="d-flex align-center">
          <v-btn
            class="mr-5 pa-1 form-button"
            href="/affiliates/Index"
            height="auto"
            min-width="auto"
          >
            <v-icon>
              mdi-arrow-left
            </v-icon>
          </v-btn>
          <h2>New Affiliates Program</h2>
        </div>
      </v-col>
    </v-row>
    <v-container class="pa-0" fluid>
      <v-form ref="formValidate" v-model="formValidate">
        <v-row>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : ''">
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <div class="text--primary">
                      Affiliates Program Name
                    </div>
                    <v-text-field
                      label="Affiliates Program Name"
                      v-model="form.name"
                      :rules="[value => !!value || 'Affiliates Program Name is required.']"
                      outlined
                      dense
                      flat
                      hide-details="auto"
                      solo
                    ></v-text-field>
                    <v-checkbox
                      class="text--primary my-3"
                      hide-details="auto"
                      v-model="form.link_campaign"
                      label="Link to existing campaign?"
                    ></v-checkbox>
                    <div class="text--primary">URL</div>
                    <v-select
                      label="Marketing Book"
                      v-model="form.url"
                      outlined
                      dense
                      flat
                      hide-details="auto"
                      solo
                    ></v-select>
                    <div class="text--primary mt-4">
                      URL
                    </div>
                    <v-text-field
                      label="marketingbook.fusion.io"
                      v-model="form.url"
                      :rules="[value => !!value || 'URL is required.']"
                      outlined
                      dense
                      flat
                      hide-details="auto"
                      solo
                    ></v-text-field>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <div class="text--primary font-weight-black mb-3">
                      Referral Setting
                    </div>
                    <v-row>
                      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '' : '12'">
                        <div class="text--primary">Referral Variable</div>
                        <v-text-field
                          label="ref"
                          v-model="form.referral"
                          outlined
                          dense
                          flat
                          hide-details="auto"
                          solo
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <div class="text--primary">Referral ID</div>
                        <v-select
                          label="user ID"
                          v-model="form.referral_user_id"
                          outlined
                          dense
                          flat
                          hide-details="auto"
                          solo
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-checkbox
                      class="text--primary"
                      hide-details="auto"
                      label="Pretty Affiliate URLs"
                      v-model="form.is_pretty_affiliate_url"
                    ></v-checkbox>
                    <p class="mt-3 mb-0">
                      https://marketingbook.fusion.io/ref/userID
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <div class="text--primary mb-3 font-weight-black">
                      Commission Level
                    </div>
                    <div v-for="tier in form.tiers" v-bind:key="tier.tier">
                      <div class="text--primary text-subtitle-1 my-1">
                        Tier {{ tier.tier }}
                      </div>
                      <v-row>
                        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
                          <div class="text--primary">Referral Rate Type</div>
                          <v-select
                              v-model="tier.rate_type"
                              :rules="[value => !!value || 'Referral Rate Type is required.']"
                              :items="rate_types"
                              item-text="name"
                              item-value="value"
                              outlined
                              dense
                              flat
                              hide-details="auto"
                              solo
                          ></v-select>
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
                          <div class="text--primary">
                            Referral Rate
                          </div>
                          <v-text-field
                              label="50"
                              v-model="tier.rate"
                              :rules="[value => !!value || 'Referral Rate is required.']"
                              outlined
                              dense
                              flat
                              hide-details="auto"
                              solo
                              type="number"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-checkbox
                          v-model="tier.charge_tax"
                          class="text--primary"
                          hide-details="auto"
                          label="Charge Tax"
                      ></v-checkbox>
                      <v-divider class="my-3"></v-divider>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <div class="text--primary mb-3 font-weight-black">
                      Cookie Rule
                    </div>
                    <v-row>
                      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
                        <div class="text--primary">Cookie Rule</div>
                        <v-select
                          label="Days"
                          v-model="form.cookie_rule"
                          :items="cookie_rules"
                          item-text="name"
                          item-value="value"
                          :rules="[value => !!value || 'Cookie Rule is required.']"
                          outlined
                          dense
                          flat
                          hide-details="auto"
                          solo
                        ></v-select>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
                        <div class="text--primary">
                          Cookie Expiration
                        </div>
                        <v-text-field
                          label="50"
                          v-model="form.cookie_expiry"
                          :rules="[value => !!value || 'Cookie Expiration is required.']"
                          outlined
                          dense
                          flat
                          hide-details="auto"
                          solo
                          type="number"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <p class="mt-3 mb-0">
                      Enter cookie validity for referral tracking cookie.
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : ''">
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <div class="text--primary font-weight-black">
                      Affiliates Status
                    </div>
                    <v-select
                      label="Status"
                      v-model="form.status_id"
                      :items="statuses"
                      item-text="name"
                      item-value="value"
                      :rules="[value => !!value || 'Affiliates Status is required.']"
                      outlined
                      dense
                      flat
                      solo
                      hide-details="auto"
                    ></v-select>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <div class="text--primary font-weight-black">
                      Affiliates Page
                    </div>
                    <v-select
                      label="Page"
                      v-model="form.page_id"
                      outlined
                      dense
                      flat
                      solo
                      hide-details="auto"
                    ></v-select>
                    <p class="mt-3 mb-0">
                      Referral can visit this page to register.
                    </p>
                    <v-divider class="my-2"></v-divider>
                    <div class="text--primary mt-3 font-weight-black">
                      Affiliates Group
                    </div>
                    <v-select
                      label="Affiliate group 1"
                      outlined
                      dense
                      flat
                      solo
                      hide-details="auto"
                    ></v-select>
                    <p class="mt-3 mb-0">
                      Choose affiliate group associate with this affiliate
                      program.
                    </p>
                    <v-divider class="my-2"></v-divider>
                    <v-dialog
                      scrollable
                      v-model="addAffiliateDialog"
                      max-width="555"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          dense
                          depressed
                          class="form-button"
                        >
                          <v-icon left>mdi-plus</v-icon>
                          Affiliate Group
                        </v-btn>
                      </template>
                      <v-card>
                        <!-- Dialog Header -->
                        <v-card-title>
                          NEW AFFILIATES GROUP
                        </v-card-title>

                        <v-card-text class="overflow-auto">
                          <v-form
                            ref="affiliate_form"
                            v-model="affiliateValidated"
                          >
                            <v-row>
                              <v-col>
                                <div class="text--primary">
                                  Affiliate Group Name
                                </div>
                                <v-text-field
                                  label="Affiliate group name"
                                  outlined
                                  dense
                                  flat
                                  hide-details="auto"
                                  solo
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <div class="text--primary">
                                  Group Slug
                                </div>
                                <v-text-field
                                  label="affiliate-group-name"
                                  outlined
                                  dense
                                  flat
                                  hide-details="auto"
                                  solo
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                        <v-divider class="mx-6"></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            class="form-button mr-2"
                            text
                          >
                            Close
                          </v-btn>
                          <v-btn
                            class="mr-2"
                            color="primary"
                            :loading="isLoading"
                          >
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <p class="mt-3 mb-0">
                      Setup affiliate settings by creating affiliate group.
                      Affiliate group can be used for multiple affiliates
                      program.
                    </p>
                    <br />
                    <p>
                      Affiliates group has different settings like commision
                      level, cookie rule and more.
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <div class="text--primary font-weight-black">
                      Terms and Condition
                    </div>
                    <v-select
                      label="Terms and condition page"
                      v-model="form.tnc_id"
                      outlined
                      dense
                      flat
                      solo
                      hide-details="auto"
                    ></v-select>
                    <p class="mt-3 mb-0">Description</p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? '10' : ''">
            <v-divider class="mt-0"></v-divider>
            <div class="d-flex align-center justify-end mt-5">
              <div>
                <v-btn
                  class="ml-3"
                  color="primary"
                  dark
                  depressed
                  @click="submit"
                >
                  Save
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
  import {mapActions} from "vuex";

  export default {
    data: () => ({
      file: "",
      isLoading: false,
      dragging: false,
      formValidate: false,
      affiliateValidated: false,
      addAffiliateDialog: null,
      statuses: [],
      rate_types: [{name: 'Flat', value: 'flat'}, {name: 'Percentage', value: 'percentage'}],
      cookie_rules: [{name: 'Days', value: 'day'}, {name: 'Weeks', value: 'week'}, {name: 'Months', value: 'month'}],
      isEdit: false,
      form: {
        id: null,
        name: null,
        url: null,
        link_campaign: 0,
        campaign_id: null,
        status_id: '1',
        referral: null,
        referral_user_id: null,
        is_pretty_affiliate_url: 0,
        tnc_id: 0,
        page_id: null,
        cookie_rule: 'day',
        cookie_expiry: null,
        tiers : [{
          tier: 1,
          rate_type: 'flat',
          rate: 0,
          charge_tax: 0
        },
        {
          tier: 2,
          rate_type: 'percentage',
          rate: 0,
          charge_tax: 0
        }]
      }
    }),
    mounted() {
      this.loadReference()
      if (this.$route.query.affiliate_id) {
        this.isEdit = true;
        this.form.id = this.$route.query.affiliate_id;
        this.loadAffiliate();
      }
    },
    computed: {
      dateRangeText() {
        return this.datePick.join(" - ");
      },
      extension() {
        return this.file ? this.file.name.split(".").pop() : "";
      },
    },
    methods: {
      ...mapActions('affiliate', [
        'postAffiliate',
        'updateAffiliate',
        'fetchReferences',
        'fetchAffiliate'
      ]),
      loadAffiliate () {
        this.loading = true
        this.fetchAffiliate(this.form.id).then((res) => {
          if (res.data.success) {
            let affiliate = res.data.affiliate
            this.form.name = affiliate.name
            this.form.url = affiliate.url
            this.form.link_campaign = affiliate.link_campaign
            this.form.campaign_id = affiliate.campaign_id
            this.form.status_id = affiliate.status_id
            this.form.referral = affiliate.referral
            this.form.referral_user_id = affiliate.referral_user_id
            this.form.is_pretty_affiliate_url = affiliate.is_pretty_affiliate_url
            this.form.tnc_id = affiliate.tnc_id
            this.form.page_id = affiliate.page_id
            this.form.cookie_rule = affiliate.cookie_rule
            this.form.cookie_expiry = affiliate.cookie_expiry

            console.log(this.statuses)
            Object.keys(this.form.tiers).forEach((key) => {
              this.form.tiers[key].tier = affiliate.tiers[key].tier
              this.form.tiers[key].rate_type = affiliate.tiers[key].rate_type
              this.form.tiers[key].rate = affiliate.tiers[key].rate
              this.form.tiers[key].charge_tax = affiliate.tiers[key].charge_tax
            });
          }
        })
      },
      loadReference () {
        this.loading = false
        this.fetchReferences()
          .then((res) => {
            this.loading = true
            if(res.data.success) {
              for(var k in res.data.statuses) {
                this.statuses.push({ value: parseInt(k), name: res.data.statuses[k]});
              }
            }
          })
      },
      submit () {
        this.isLoading = true;
        this.$refs.formValidate.validate()

        if (!this.formValidate) {
          return true
        }

        if (!this.isEdit) {
          this.postAffiliate(this.form)
              .then((res) => {
                if(res.data.success) {
                  this.loading = false
                  this.$router.push({name: 'affiliates.index'})
                }
              })
              .catch((err) => {
                if (typeof err.response != 'undefined' && err.response.status === 400 && err.response.data.message) {
                  // eslint-disable-next-line no-unused-vars
                  Object.keys(err.response.data.message).forEach((key,index) => {
                    if (typeof err.response.data.message[key] !== 'undefined') {
                      window.toastr['error'](err.response.data.message[key])
                    }
                  });
                }
                this.isLoading = false
              })
        } else {
          this.updateAffiliate(this.form)
              .then((res) => {
                if(res.data.success) {
                  this.loading = false
                  this.$router.push({name: 'affiliates.index'})
                }
              })
              .catch((err) => {
                if (typeof err.response != 'undefined' && err.response.status === 400 && err.response.data.message) {
                  // eslint-disable-next-line no-unused-vars
                  Object.keys(err.response.data.message).forEach((key,index) => {
                    if (typeof err.response.data.message[key] !== 'undefined') {
                      window.toastr['error'](err.response.data.message[key])
                    }
                  });
                }
                this.isLoading = false
              })
        }

      },
    },
  };
</script>

<style scoped></style>
