<template>
  <div class="email-configurator-component">
    <div class="d-flex justify-space-between align-center">
      <div class="text-subtitle-1">Configure Email</div>
      <v-btn x-small color="grey lighten-2" fab>
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-divider class="my-3"></v-divider>
    <label for="email-title">Title</label>
    <v-text-field
      id="email-title"
      outlined
      dense
      :hide-details="true"
      v-model="formData.title"
    ></v-text-field>
    <div
      class="text-caption font-italic text-muted mb-3"
      v-html="'Use <b>{{customer_name}}</b> in field for customer name'"
    ></div>

    <label for="email-pre-header" class="">Pre-header</label>
    <v-text-field
      id="email-pre-header"
      outlined
      dense
      :hide-details="true"
      placeholder="Your email pre-header here"
      v-model="formData.preHeader"
    ></v-text-field>
    <div
      class="text-caption font-italic text-muted mb-3"
      v-html="
        'Pre-header will appear as a short text under email title in inbox.'
      "
    ></div>

    <v-divider class="my-3"></v-divider>

    <label>Send email to</label>
    <v-row class="mb-1">
      <v-col cols="6">
        <v-checkbox
          v-model="formData.sendToOption"
          value="Customer"
          label="Customer"
          hide-details
        ></v-checkbox>
      </v-col>
      <v-col cols="6">
        <v-checkbox
          hide-details
          v-model="formData.sendToOption"
          value="Custom"
          label="Custom"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-text-field
      outlined
      dense
      :hide-details="true"
      placeholder="Enter emails separate by comma"
      v-model="formData.emails"
      class="mb-3"
    ></v-text-field>

    <div class="d-flex justify-space-between align-center my-4">
      <div class="text-body-2">Email body</div>
      <v-btn solo color="white" small>Edit</v-btn>
    </div>

    <v-divider class="my-3"></v-divider>

    <div class="text-caption text-muted">INTEGRATION</div>

    <v-checkbox
      hide-details
      v-model="formData.enableMailchimp"
      :value="true"
      label="Enable Mailchimp"
      class="mb-3"
    ></v-checkbox>

    <div v-if="formData.enableMailchimp" class="mb-3">
      <v-btn small color="white">
        <v-icon small class="mr-2">$appZapIcon</v-icon>
        Connect to Mailchimp
      </v-btn>

      <div>
        <v-btn small color="white" class="red--text mb-2">
          <v-icon small color="red" class="mr-2">mdi-close</v-icon>
          Disconnect to Mailchimp
        </v-btn>
      </div>

      <label for="email-select-template" class="text-caption"
        >Select email template</label
      >
      <v-select
        id="email-select-template"
        dense
        outlined
        item-text="text"
        item-value="value"
        :items="templateOptions"
      >
      </v-select>

      <div class="text-caption font-italic text-muted mb-3">
        These email template list are from connected Mailchimp account.
      </div>
    </div>

    <v-divider class="my-3"></v-divider>
  </div>
</template>

<script>
export default {
  name: "Email",
  data() {
    return {
      templateOptions: [
        { text: "Template 1", value: "template_1" },
        { text: "Template 2", value: "template_2" },
      ],
      currentNodeId: null,
      formData: {
        title: null,
        preHeader: null,
        sendToOption: [],
        emails: null,
        enableMailchimp: false,
      },
    };
  },
  watch: {
    formData: {
      deep: true,
      handler () {
        this.pushUpdates()
      }
    }
  },
  methods: {
    // please prepare this methods in other configuration
    // this method has to fire before the drawer appear
    loadSettingStubs (nodeId) {
      this.currentNodeId = nodeId
      this.formData.title = null
      this.formData.preHeader = null
      this.formData.sendToOption = []
      this.formData.emails = null
      this.formData.enableMailchimp = null
    },
    // this method to include
    setCurrentNode (nodeId, settings) {
      this.currentNodeId = nodeId
      this.formData = {...settings}
    },
    pushUpdates () {
      this.$emit('UpdateConfiguration', {
        nodeId: this.currentNodeId,
        settings: {...this.formData}
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.email-configurator-component {
  opacity: 0.85;
}
</style>