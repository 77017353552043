<template>
  <v-app>
    <v-main class="main-login-layout">
      <v-container
          class="fill-height"
          fluid
      >
        <v-row
            align="center"
            justify="center"
        >
          <v-col
              cols="12"
              sm="8"
              md="5"
              lg="4"
          >
            <div class="text-center">
              <img src="/assets/app/img/logo.png" alt="fusion-logo" />
            </div>
            <router-view/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script type="text/babel">
export default {
  watch: {
    $route: 'onRouteChange'
  },
  mounted () {
    this.setLayoutClasses()
  },
  methods: {
    setLayoutClasses () {
    }
  },
  onRouteChange () {
  }
}
</script>

