import * as types from './mutation-types'

export default {
    [types.SET_PRODUCTS](state, products) {
        state.products = products
    },
    [types.SET_PRODUCT_TYPES](state, types) {
        state.types = types
    },

}
