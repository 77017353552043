<template>
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0666 16.3333C13.7485 16.3333 16.7332 13.3486 16.7332 9.66667C16.7332 5.98477 13.7485 3 10.0666 3C6.38467 3 3.3999 5.98477 3.3999 9.66667C3.3999 13.3486 6.38467 16.3333 10.0666 16.3333Z" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.3999 18L14.7749 14.375" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "app-search-icon"
});
</script>

<style scoped>

</style>
