//import * as types from './mutation-types'

// eslint-disable-next-line no-unused-vars
export const fetchCustomers = ({ commit, dispatch, state }, params) => {
    return new Promise((resolve, reject) => {
        window.axios.get('/api/customer'+params).then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const fetchCustomer = ({ commit, dispatch, state }, customer_id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('/api/customer/'+customer_id).then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const postCustomer = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('/api/customer/store', data).then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const updateCustomer = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.put('/api/customer/'+data.id, data).then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const updateCustomerStatus = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('/api/customer/status/update', data).then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const deleteCustomer = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete(`/api/customer/${id}`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}