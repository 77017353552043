<template>
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0001 18.8332C14.6025 18.8332 18.3334 15.1022 18.3334 10.4998C18.3334 5.89746 14.6025 2.1665 10.0001 2.1665C5.39771 2.1665 1.66675 5.89746 1.66675 10.4998C1.66675 15.1022 5.39771 18.8332 10.0001 18.8332Z" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.57495 7.99999C7.77087 7.44304 8.15758 6.97341 8.66658 6.67426C9.17558 6.37512 9.77403 6.26577 10.3559 6.36558C10.9378 6.46539 11.4656 6.76792 11.8458 7.21959C12.2261 7.67126 12.4342 8.24292 12.4333 8.83332C12.4333 10.5 9.93328 11.3333 9.93328 11.3333" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 14.6665H10.0093" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "app-help-circle-icon"
});
</script>

<style scoped>

</style>
