<template>
  <div class="pa-4">
    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
        <div class="d-flex align-center">
          <v-btn
            class="mr-5 pa-1 form-button"
            :to="{ name: 'membership.index' }"
            height="auto"
            min-width="auto"
          >
            <v-icon>
              mdi-arrow-left
            </v-icon>
          </v-btn>
          <div class="text--primary text-h5">New Membership</div>
        </div>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : ''">
        <div
          :class="
            $vuetify.breakpoint.mdAndUp
              ? 'd-flex align-center justify-end mr-3'
              : 'd-flex align-center'
          "
        >
          <p class="mt-1">
            <v-icon color="green">
              mdi-check
            </v-icon>
            Changes Saved 4:23PM
          </p>
        </div>
      </v-col>
    </v-row>
    <v-container class="pa-0" fluid>
      <form>
        <v-row>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <div class="text--primary font-weight-black mb-3">
                          Media
                        </div>
                      </v-col>
                      <v-col>
                        <div class="d-flex align-center justify-end">
                          <a>
                            Add media from URL
                            <v-icon color="blue">
                              mdi-chevron-down
                            </v-icon>
                          </a>
                        </div>
                      </v-col>
                    </v-row>
                    <div v-if="!file">
                      <div
                        :class="['dropZone', dragging ? 'dropZone-over' : '']"
                        @dragenter="dragging = true"
                        @dragleave="dragging = false"
                      >
                        <div class="dropZone-info" @drag="onChange">
                          <span
                            class="fa fa-cloud-upload dropZone-title"
                          ></span>
                          <span class="dropZone-title"
                            >Drop file or click to upload</span
                          >
                          <div class="dropZone-upload-limit-info">
                            <div>maximum file size: 5 MB</div>
                          </div>
                        </div>
                        <input type="file" @change="onChange" />
                      </div>
                    </div>
                    <div v-else class="dropZone-uploaded">
                      <div class="dropZone-uploaded-info">
                        <span class="dropZone-title">Uploaded</span>
                        <button
                          type="button"
                          class="btn btn-primary removeFile"
                          @click="removeFile"
                        >
                          Remove File
                        </button>
                      </div>
                    </div>

                    <div class="uploadedFile-info">
                      <!-- <div>fileName: {{ file.name }}</div>
                      <div>fileZise(bytes): {{ file.size }}</div>
                      <div>extension：{{ extension }}</div> -->
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <div class="text--primary font-weight-black mb-3">
                      Membership Details
                    </div>
                    <v-row>
                      <v-col>
                        <div class="text--primary">Membership Name</div>
                        <v-text-field
                          label="Membership Name"
                          outlined
                          dense
                          flat
                          hide-details="auto"
                          solo
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <div class="text--primary">Membership Description</div>
                        <v-textarea
                          label="Description"
                          outlined
                          flat
                          auto-grow
                          clearable
                          hide-details="auto"
                          solo
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <div class="text--primary mt-4">
                      Promotional Page
                    </div>
                    <v-row>
                      <v-col class="pr-0">
                        <v-select
                          label="Fusion Page"
                          outlined
                          flat
                          dense
                          hide-details="auto"
                          solo
                        ></v-select>
                      </v-col>
                      <v-col class="pr-0">
                        <v-select
                          label="Marketing Book"
                          outlined
                          flat
                          dense
                          hide-details="auto"
                          solo
                        ></v-select>
                      </v-col>
                      <v-col>
                        <v-select
                          label="Page 1"
                          outlined
                          flat
                          dense
                          hide-details="auto"
                          solo
                        ></v-select>
                      </v-col>
                    </v-row>
                    <p class="mt-3 mb-0">
                      You may choose promotional page from external URL or Pages
                      created in Fusion.
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <div class="text--primary font-weight-black">
                      Membership Status
                    </div>
                    <v-select
                      label="Draft"
                      outlined
                      dense
                      flat
                      solo
                      hide-details="auto"
                    ></v-select>
                    <p class="mt-3 mb-0">
                      Draft status is hidden.
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <div class="text--primary font-weight-black">
                      Contents
                    </div>
                    <v-btn class="form-button" depressed>
                      Go to Portal Builder
                    </v-btn>
                    <p class="mt-2 mb-0">
                      <v-icon class="mr-0">
                        mdi-content-save-outline
                      </v-icon>
                      Last saved 7 Feb 5:04pm
                    </p>
                    <p class="mt-2 mb-0">
                      Adding contents into your help adding values to your
                      subscribers overtime.
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <div class="text--primary font-weight-black">
                      Membership Portal
                    </div>
                    <v-btn class="form-button" depressed>
                      Go to Portal Editor
                    </v-btn>
                    <p class="mt-2 mb-0">
                      <v-icon class="mr-0">
                        mdi-content-save-outline
                      </v-icon>
                      Last saved 7 Feb 5:04pm
                    </p>
                    <p class="mt-2 mb-0">
                      This is where your members will login, access contents and
                      make in-membership purchase.
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? '10' : '12'">
            <v-divider class="mb-2"></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="$vuetify.breakpoint.mdAndUp"
            :cols="$vuetify.breakpoint.mdAndUp ? '10' : '12'"
          >
            <v-card class="form-card mr-3 mb-3">
              <v-card-text>
                <table style="width:100%;">
                  <tr>
                    <td style="width:40%;" class="pb-2 text--primary td-r td-b">
                      Access Level:
                    </td>
                    <td
                      style="width:12%;text-align:center;"
                      class="px-4 pb-2 text--primary font-weight-black td-r td-b"
                    >
                      Standard
                    </td>
                    <td
                      style="width:12%;text-align:center;"
                      class="px-4 pb-2 text--primary font-weight-black td-r td-b"
                    >
                      Gold
                    </td>
                    <td style="width:10%;" class="px-4 pb-2 td-b">
                      <v-btn class="form-button" small depressed>Add</v-btn>
                    </td>
                    <td
                      style="width:34%;"
                      class="pb-2 text--primary text-right td-b"
                    >
                      Access Setting
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-5 text--primary font-weight-black td-r">
                      Module 1: Introduction
                    </td>
                    <td class="td-r"></td>
                    <td class="td-r"></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td class="td-r">
                      <div class="d-flex align-center">
                        <v-icon small class="ml-2" style="font-size:21px;"
                          >mdi-file-document-outline </v-icon
                        >Lesson 1 - About course
                      </div>
                    </td>
                    <td class="td-r">
                      <div class="d-flex justify-center align-center">
                        <v-checkbox
                          class="ma-0 pa-0"
                          dense
                          hide-details="auto"
                        ></v-checkbox>
                      </div>
                    </td>
                    <td class="td-r">
                      <div class="d-flex justify-center align-center">
                        <v-checkbox
                          class="ma-0 pa-0"
                          dense
                          hide-details="auto"
                        ></v-checkbox>
                      </div>
                    </td>
                    <td></td>
                    <td>
                      <v-select
                        label="Instant Access"
                        outlined
                        flat
                        dense
                        hide-details="auto"
                        solo
                      ></v-select>
                    </td>
                  </tr>
                  <tr>
                    <td class="td-r">
                      <v-icon small class="ml-2" style="font-size:21px;"
                        >mdi-file-document-outline </v-icon
                      >Lesson 2 - About course
                    </td>
                    <td class="td-r">
                      <div class="d-flex justify-center align-center">
                        <v-checkbox
                          class="ma-0 pa-0"
                          dense
                          hide-details="auto"
                        ></v-checkbox>
                      </div>
                    </td>
                    <td class="td-r">
                      <div class="d-flex justify-center align-center">
                        <v-checkbox
                          class="ma-0 pa-0"
                          dense
                          hide-details="auto"
                        ></v-checkbox>
                      </div>
                    </td>
                    <td></td>
                    <td>
                      <v-select
                        label="Instant Access"
                        outlined
                        flat
                        dense
                        hide-details="auto"
                        solo
                      ></v-select>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-5 text--primary font-weight-black td-r">
                      Module 2: High Value Offer Content
                    </td>
                    <td class="td-r"></td>
                    <td class="td-r"></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td class="td-r">
                      <v-icon small class="ml-2" style="font-size:21px;"
                        >mdi-file-document-outline </v-icon
                      >Lesson 1 - About HVOC
                    </td>
                    <td class="td-r">
                      <div class="d-flex justify-center align-center">
                        <v-checkbox
                          class="ma-0 pa-0"
                          dense
                          hide-details="auto"
                        ></v-checkbox>
                      </div>
                    </td>
                    <td class="td-r">
                      <div class="d-flex justify-center align-center">
                        <v-checkbox
                          class="ma-0 pa-0"
                          dense
                          hide-details="auto"
                        ></v-checkbox>
                      </div>
                    </td>
                    <td></td>
                    <td>
                      <v-select
                        label="Unlock 24 Feb"
                        outlined
                        flat
                        dense
                        hide-details="auto"
                        solo
                      ></v-select>
                    </td>
                  </tr>
                  <tr>
                    <td class="td-r">
                      <v-icon small class="ml-2" style="font-size:21px;"
                        >mdi-file-document-outline </v-icon
                      >Lesson 2 - About HVOC
                    </td>
                    <td class="td-r">
                      <div class="d-flex justify-center align-center">
                        <v-checkbox
                          class="ma-0 pa-0"
                          dense
                          hide-details="auto"
                        ></v-checkbox>
                      </div>
                    </td>
                    <td class="td-r">
                      <div class="d-flex justify-center align-center">
                        <v-checkbox
                          class="ma-0 pa-0"
                          dense
                          hide-details="auto"
                        ></v-checkbox>
                      </div>
                    </td>
                    <td></td>
                    <td>
                      <v-select
                        label="Unlock 24 Feb"
                        outlined
                        flat
                        dense
                        hide-details="auto"
                        solo
                      ></v-select>
                    </td>
                  </tr>
                </table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-else>
            <v-card class="form-card mr-3 mb-3">
              <v-card-text>
                <div class="text--primary font-weight-black">
                  Access Level
                </div>
                <v-divider class="my-3"></v-divider>
                <v-expansion-panels focusable>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <div class="text--primary font-weight-black">
                        Module 1: Introduction
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="integration-font my-2">
                        Lesson 1 - About course
                      </div>
                      <div
                        class="mt-2 d-flex align-center justify-space-between"
                      >
                        <label class="switch-label mt-1">Standard</label>
                        <v-switch
                          class="mt-0"
                          hide-details="auto"
                          inset
                        ></v-switch>
                      </div>
                      <div
                        class="mt-2 d-flex align-center justify-space-between"
                      >
                        <label class="switch-label mt-1">Gold</label>
                        <v-switch
                          class="mt-0"
                          hide-details="auto"
                          inset
                        ></v-switch>
                      </div>
                      <v-select
                        class="my-3"
                        label="Instant Access"
                        outlined
                        flat
                        dense
                        hide-details="auto"
                        solo
                      ></v-select>
                      <v-divider class="my-4"></v-divider>
                      <div class="integration-font my-2">
                        Lesson 2 - About course
                      </div>
                      <div
                        class="mt-2 d-flex align-center justify-space-between"
                      >
                        <label class="switch-label mt-1">Standard</label>
                        <v-switch
                          class="mt-0"
                          hide-details="auto"
                          inset
                        ></v-switch>
                      </div>
                      <div
                        class="mt-2 d-flex align-center justify-space-between"
                      >
                        <label class="switch-label mt-1">Gold</label>
                        <v-switch
                          class="mt-0"
                          hide-details="auto"
                          inset
                        ></v-switch>
                      </div>
                      <v-select
                        class="my-3"
                        label="Instant Access"
                        outlined
                        flat
                        dense
                        hide-details="auto"
                        solo
                      ></v-select>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <div class="text--primary font-weight-black">
                        Module 2: High Value Offer Content
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="integration-font my-2">
                        Lesson 1 - About HVOC
                      </div>
                      <div
                        class="mt-2 d-flex align-center justify-space-between"
                      >
                        <label class="switch-label mt-1">Standard</label>
                        <v-switch
                          class="mt-0"
                          hide-details="auto"
                          inset
                        ></v-switch>
                      </div>
                      <div
                        class="mt-2 d-flex align-center justify-space-between"
                      >
                        <label class="switch-label mt-1">Gold</label>
                        <v-switch
                          class="mt-0"
                          hide-details="auto"
                          inset
                        ></v-switch>
                      </div>
                      <v-select
                        class="my-3"
                        label="Unlock 24 Feb"
                        outlined
                        flat
                        dense
                        hide-details="auto"
                        solo
                      ></v-select>
                      <v-divider class="my-4"></v-divider>
                      <div class="integration-font my-2">
                        Lesson 2 - About HVOC
                      </div>
                      <div
                        class="mt-2 d-flex align-center justify-space-between"
                      >
                        <label class="switch-label mt-1">Standard</label>
                        <v-switch
                          class="mt-0"
                          hide-details="auto"
                          inset
                        ></v-switch>
                      </div>
                      <div
                        class="mt-2 d-flex align-center justify-space-between"
                      >
                        <label class="switch-label mt-1">Gold</label>
                        <v-switch
                          class="mt-0"
                          hide-details="auto"
                          inset
                        ></v-switch>
                      </div>
                      <v-select
                        class="my-3"
                        label="Unlock 24 Feb"
                        outlined
                        flat
                        dense
                        hide-details="auto"
                        solo
                      ></v-select>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <div class="text--primary font-weight-black">
                      Membership Progress
                    </div>
                    <v-checkbox
                      class="my-1"
                      hide-details="auto"
                      label="Enable progress tracking"
                    ></v-checkbox>
                    <p class="mt-1">
                      Enable completion tracker to track your members progress.
                    </p>
                    <v-divider class="my-3"></v-divider>
                    <div class="text--primary font-weight-black">
                      Completion Certificate
                    </div>
                    <v-checkbox
                      class="my-1"
                      hide-details="auto"
                      label="Send completion certificate email"
                    ></v-checkbox>
                    <p class="mt-1">
                      Required to complete modules:
                    </p>
                    <v-card class="form-small-card mr-3 mb-3">
                      <v-card-text class="pa-1">
                        <v-checkbox
                          class="my-1"
                          hide-details="auto"
                          label="Module 1: Introduction"
                        ></v-checkbox>
                        <v-checkbox
                          class="my-1"
                          hide-details="auto"
                          label="Module 2: High Value Offer Content"
                        ></v-checkbox>
                      </v-card-text>
                    </v-card>
                    <v-divider class="my-3"></v-divider>
                    <div class="text--primary font-weight-black">
                      Email Notification
                    </div>
                    <v-checkbox
                      class="my-1"
                      hide-details="auto"
                      label="Enable email notification"
                    ></v-checkbox>
                    <p class="mt-1 mb-0">
                      Send push notification once members completed certain
                      module.
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <div class="text--primary font-weight-black">
                      Comments
                    </div>
                    <v-checkbox
                      class="my-1"
                      hide-details="auto"
                      label="Enable comments"
                    ></v-checkbox>
                    <v-divider class="my-3"></v-divider>
                    <div class="text--primary font-weight-black">
                      Comments Moderation
                    </div>
                    <v-checkbox
                      class="my-1"
                      hide-details="auto"
                      label="Auto approve all comments"
                    ></v-checkbox>
                    <p class="mt-1">
                      Adding contents into your help adding values to your
                      subscribers overtime.
                    </p>
                    <v-checkbox
                      class="my-1"
                      hide-details="auto"
                      label="Comments need admin approval"
                    ></v-checkbox>
                    <div class="text-caption text--primary mt-3">
                      Notify admin email
                    </div>
                    <v-text-field
                      label="admin@gmail.com"
                      outlined
                      dense
                      flat
                      hide-details="auto"
                      solo
                    ></v-text-field>
                    <v-divider class="my-5"></v-divider>
                    <div class="integration-font">
                      INTEGRATION
                    </div>
                    <v-checkbox
                      class="my-1"
                      hide-details="auto"
                      label="Enable email notification"
                    ></v-checkbox>
                    <p class="mt-1 mb-0">
                      Enable Facebook Comments
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? '10' : '12'">
            <v-divider class="mt-0"></v-divider>
            <div class="d-flex align-center justify-end mt-5">
              <div>
                <v-btn
                  href="/products/Create"
                  class="ml-3"
                  color="primary"
                  dark
                  depressed
                >
                  Save
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </form>
    </v-container>
  </div>
</template>

<script>
  export default {
    data: () => ({
      tagValue: null,
      allTags: [],
      file: "",
      dragging: false,
      list: null,
      page: 1,
      pageCount: 0,
      productPerPage: 10,
      tabs: [
        { name: "All" },
        { name: "Digital" },
        { name: "Physical" },
        { name: "Services" },
        { name: "Tickets" },
        { name: "Membership" },
        { name: "Archived" },
      ],
      cards: [
        { title: "Total", icon: "mdi-arrow-left", text: "12" },
        { title: "Digital", icon: "mdi-thumb-down", text: "8" },
        { title: "Physical", icon: "mdi-thumb-up", text: "4" },
        { title: "Archived", icon: "mdi-minus-circle", text: "2" },
      ],
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        { text: "Type", value: "type" },
        { text: "Date Modified", value: "date_update" },
        { text: "Inventory", value: "inventory" },
        { text: "Atr 2", value: "status" },
        { text: "Action", value: "action", sortable: false },
      ],
      products: [
        {
          name: "Frozen Yogurt",
          type: "Digital",
          date_update: 6.0,
          inventory: 24,
          status: 4.0,
        },
        {
          name: "Ice cream sandwich",
          type: "Digital",
          date_update: 9.0,
          inventory: 37,
          status: 4.3,
        },
        {
          name: "Eclair",
          type: "Physical",
          date_update: 16.0,
          inventory: 23,
          status: 6.0,
        },
        {
          name: "Cupcake",
          type: "Physical",
          date_update: 3.7,
          inventory: 67,
          status: 4.3,
        },
        {
          name: "Gingerbread",
          type: "Services",
          date_update: 16.0,
          inventory: 49,
          status: 3.9,
        },
        {
          name: "Jelly bean",
          type: "Tickets",
          date_update: 0.0,
          inventory: 94,
          status: 0.0,
        },
        {
          name: "Lollipop",
          type: "Tickets",
          date_update: 0.2,
          inventory: 98,
          status: 0,
        },
        {
          name: "Honeycomb",
          type: "Membership",
          date_update: 3.2,
          inventory: 87,
          status: 6.5,
        },
        {
          name: "Donut",
          type: "Membership",
          date_update: 25.0,
          inventory: 51,
          status: 4.9,
        },
        {
          name: "KitKat",
          type: "Archived",
          date_update: 26.0,
          inventory: 65,
          status: 7,
        },
      ],
    }),
    computed: {
      dateRangeText() {
        return this.datePick.join(" - ");
      },
      extension() {
        return this.file ? this.file.name.split(".").pop() : "";
      },
    },
    methods: {
      getColor(type) {
        if (type == "Archived") return "grey";
        else return "green";
      },
      onChange(e) {
        var files = e.target.files || e.dataTransfer.files;

        if (!files.length) {
          this.dragging = false;
          return;
        }

        this.createFile(files[0]);
      },
      createFile(file) {
        if (file.size > 5000000) {
          alert("please check file size no over 5 MB.");
          this.dragging = false;
          return;
        }

        this.file = file;
        console.log(this.file);
        this.dragging = false;
      },
      removeFile() {
        this.file = "";
      },
      addTag() {
        this.allTags.push(this.tagValue);
        this.tagValue = null;
      },
    },
  };
</script>

<style scoped>
  .td-r {
    border-right: 1px solid rgba(229, 229, 229, 1);
  }
  .td-b {
    border-bottom: 1px solid rgba(229, 229, 229, 1);
  }
</style>
