<template>
  <div class="pa-4">
    <v-row>
      <v-col>
        <h2>Customers</h2>
      </v-col>
      <v-col>
        <div
          :class="
            $vuetify.breakpoint.mdAndUp
              ? 'd-flex align-center justify-end'
              : 'd-flex align-center'
          "
        >
          <div>
            <v-btn href="#" text class="form-button mr-3">
              <v-icon v-if="!$vuetify.breakpoint.mdAndUp">
                mdi-home-export-outline
              </v-icon>
              <div v-else>
                Export
              </div>
            </v-btn>
          </div>
          <div>
            <v-btn href="#" text class="form-button">
              <v-icon v-if="!$vuetify.breakpoint.mdAndUp">
                mdi-home-import-outline
              </v-icon>
              <div v-else>
                Import Customers
              </div>
            </v-btn>
          </div>
          <div>
            <v-btn
              href="/contacts/Create"
              class="ml-3"
              color="primary"
              depressed
              dark
            >
              <v-icon v-if="!$vuetify.breakpoint.mdAndUp">
                mdi-plus
              </v-icon>
              <div v-else>
                Add Customer
              </div>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-container class="pa-0" fluid>
      <v-row class="campaign-card-row">
        <v-col
          v-for="card in cards"
          :key="card"
          :cols="12"
          :sm="4"
          :md="4"
          :lg="3"
          class="px-5"
        >
          <v-card class="form-card">
            <v-row no-gutters>
              <v-col md="2" cols="4" class="campaign-card-icon">
                <v-btn fab icon class="blue lighten-5 ml-2" width="40px">
                  <v-icon color="blue">{{ card.icon }}</v-icon>
                </v-btn>
              </v-col>
              <v-col md="10" cols="8">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-card-title class="text-h5 pb-0" v-text="card.text">
                    </v-card-title>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col>
                        <v-card-subtitle
                          class="font-weight-bold pt-0"
                          v-text="card.title"
                        >
                        </v-card-subtitle>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-card class="mt-10 form-card">
      <div>
        <v-tabs class="px-4" v-model="list">
          <v-tab class="d-flex" v-for="tab in tabs" :key="tab" @click="loadCustomer(tab.name)">
            {{ tab.name }}
          </v-tab>
        </v-tabs>
      </div>
      <v-divider></v-divider>
      <div class="pa-4">
        <v-row no-gutters class="mb-5">
          <v-col :cols="$vuetify.breakpoint.smAndUp ? '7' : '12'" class="mr-2">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Search"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            :cols="$vuetify.breakpoint.smAndUp ? '3' : '12'"
            :class="$vuetify.breakpoint.smAndUp ? 'mr-2' : 'mt-3'"
          >
            <v-select
              dense
              outlined
              label="Filters"
              hide-details="auto"
            ></v-select>
          </v-col>
          <v-col
            :cols="$vuetify.breakpoint.smAndUp ? '1' : '5'"
            :class="$vuetify.breakpoint.smAndUp ? 'mr-2' : 'mt-3'"
          >
            <v-btn dense block outlined><v-icon></v-icon>Save Segment</v-btn>
          </v-col>
        </v-row>
        <v-row :class="$vuetify.breakpoint.smAndUp ? 'ma-0' : 'pa-0'">
          <v-col :cols="$vuetify.breakpoint.smAndUp ? '' : '12'">
            <div class="align-center">
              <v-checkbox
                class="mx-0 my-1"
                label="Showing 10 of 100 customers"
                hide-details="auto"
              >
              </v-checkbox>
            </div>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.smAndUp">
            <div class="d-flex align-center justify-end">
              <div>Sort By:</div>
              <div class="w-50">
                <v-select
                  dense
                  outlined
                  class="ml-3 white"
                  label="Newest first"
                  hide-details="auto"
                ></v-select>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="customers"
          :search="search"
          :page.sync="page"
          show-select
          hide-default-footer
          @page-count="pageCount = $event"
          :loading="isLoading"
        >
          <template v-slot:item.name="{ item }">
            <div class="font-weight-regular">{{ item.name }}</div>
            <div class="font-weight-regular email-style">
              {{ item.email }}
            </div>
          </template>
          <template v-slot:item.action="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="2"
                  fab
                  icon
                  color="white"
                  outlined
                  width="40px"
                  v-bind="attrs"
                  v-on="on"
                  @click="editItem(item)"
                >
                  <v-icon color="black">mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  link
                  :to="{ name: 'contacts.view', params: { id: item.id } }"
                  >View</v-list-item
                >
              </v-list>
              <v-divider></v-divider>
              <v-list dense>
                <v-list-item link>
                  <v-icon class="mr-2" color="red">
                    mdi-delete
                  </v-icon>
                  <div style="color:red;">Delete</div>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:item.atr1="{ item }">
            <v-icon color="green" v-if="item.atr1 == true">mdi-check</v-icon>
            <v-icon v-else>mdi-close </v-icon>
          </template>
          <template v-slot:item.atr2="{ item }">
            <v-icon color="green" v-if="item.atr2 == true">mdi-check </v-icon>
            <v-icon v-else>mdi-close </v-icon>
          </template>
          <template v-slot:item.atr3="{ item }">
            <v-icon color="green" v-if="item.atr3 == true">mdi-check </v-icon>
            <v-icon v-else>mdi-close </v-icon>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
  import { mapActions } from "vuex";

  export default {
    data: () => ({
      list: null,
      page: 1,
      pageCount: 0,
      projectPerPage: 10,
      isLoading: false,
      tab_type_id: null,
      search: null,
      tabs: [
        { name: "All" },
        { name: "New" },
        { name: "Returning" },
        { name: "Email Subscribers" },
        { name: "Segment 2" },
      ],
      cards: [
        { name: 'new_customer',title: "New Customers", icon: "mdi-arrow-left", text: "503" },
        { name: 'lifetime_customer', title: "Lifetime Customers", icon: "mdi-thumb-down", text: "520" },
        { name: 'statistic', title: "Statistic 1", icon: "mdi-thumb-up", text: "12" },
        { name: 'unsubscribe_customer', title: "Unsubscribers", icon: "mdi-minus-circle", text: "2" },
      ],
      headers: [
        { text: "First Name", align: "start", value: "first_name" },
        { text: "Last Name", align: "start", value: "last_name" },
        { text: "Email", align: "start", value: "email" },
        { text: "Date Created", value: "created_at" },
        // { text: "Atr 1", value: "atr1", width: "7%" },
        // { text: "Atr 2", value: "atr2", width: "7%" },
        // { text: "Atr 3", value: "atr3", width: "7%" },
        { text: "Action", value: "action", sortable: false, width: "5%" },
      ],
      customers: [],
    }),
    mounted() {
      this.loadCustomer();
    },
    computed: {
      dateRangeText() {
        return this.datePick.join(" - ");
      },
    },
    methods: {
      ...mapActions("customer", ["fetchCustomers"]),
      loadCustomer(type_id = null) {
        let filterObj = {};
        let filter = "";
        this.tab_type_id = type_id;
        if (this.tab_type_id) {
          filterObj.product_type_id = this.tab_type_id;
          filter = "?" + new URLSearchParams(filterObj).toString();
        }
        this.isLoading = true;
        this.fetchCustomers(filter).then((res) => {
          this.isLoading = false;
          this.customers = res.data.customers;
          this.cards.forEach((obj, key) => {
            this.cards[key].text = res.data.analytics[obj.name]
          });

        });
      },
      editItem(customer) {
        console.log(customer);
      }
    },
  };
</script>

<style scoped></style>
