import * as types from './mutation-types'
import * as authTypes from './modules/auth/mutation-types'
import * as planTypes from './modules/plan/mutation-types'
import * as countryTypes from './modules/country/mutation-types'
import * as tagTypes from './modules/tag/mutation-types'
import * as productTypes from './modules/product/mutation-types'
import * as globalTypes from './modules/global/mutation-types'
// import jwtToken from "./../helpers/jwt-token";


export default {
  // eslint-disable-next-line no-unused-vars
  bootstrap ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      window.axios.get('/api/app/bootstrap').then((res) => {
        commit('auth/' + authTypes.SET_USER, res.data.user)
        commit('plan/' + planTypes.SET_PLANS, res.data.plans)
        commit('country/' + countryTypes.SET_COUNTRIES, res.data.countries)
        commit('country/' + countryTypes.SET_TIMEZONES, res.data.timezones)
        commit('tag/' + tagTypes.SET_TAGS, res.data.tags)
        commit('product/' + productTypes.SET_PRODUCT_TYPES, res.data.product_types)
        commit('global/' + globalTypes.SET_APP_DOMAIN, res.data.app_domain)
        // commit('role/' + roleTypes.SET_ROLES, res.data.roles)
        // commit('profile/' + profileTypes.SET_PROFILE, res.data.profile)
        commit(types.UPDATE_APP_LOADING_STATUS, true)
        // commit(types.SET_GLOBAL_SETTINGS, res.data.global_settings)
        resolve(true);
      }).catch(err => {

        // eslint-disable-next-line no-empty
        if(err.response.status===401){
            // jwtToken.removeToken();
            // commit(types.UNSET_USER)
            // commit(types.UNSET_TOKEN)
            // commit(types.UPDATE_APP_LOADING_STATUS, true)
            // resolve(true);
          // eslint-disable-next-line no-empty
        } else if (err.response.data.error === 'invalid_credentials') {

        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', err);
          // console.log('Error status', err.status);
          // console.log('Error message', err.message);
        }
        console.log('here>')
        reject(err)
      })
    })
  }
}
