<template>
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.23975 8L10.2397 13L15.2397 8" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "app-chevron-down-icon"
});
</script>

<style scoped>

</style>
