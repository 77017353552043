<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.3333 5.00016C18.3333 4.0835 17.5833 3.3335 16.6666 3.3335H3.33329C2.41663 3.3335 1.66663 4.0835 1.66663 5.00016M18.3333 5.00016V15.0002C18.3333 15.9168 17.5833 16.6668 16.6666 16.6668H3.33329C2.41663 16.6668 1.66663 15.9168 1.66663 15.0002V5.00016M18.3333 5.00016L9.99996 10.8335L1.66663 5.00016" stroke="black" stroke-width="1.38889" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "app-mail-icon"
});
</script>

<style scoped>

</style>
