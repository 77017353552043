<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.3334 1.6665L9.16669 10.8332" stroke="black" stroke-width="1.38889" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.3334 1.6665L12.5 18.3332L9.16669 10.8332L1.66669 7.49984L18.3334 1.6665Z" stroke="black" stroke-width="1.38889" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "app-send-icon"
});
</script>

<style scoped>

</style>
