<template>
    <div class="pa-4">
        <h2>Get Tests</h2>
        <p>bla bla bla</p>
        
        
        <h3>Available tests</h3>
          
        <v-row dense>
          <v-col cols="12" v-for="item in items" :key="item">
            <v-hover>
              <template v-slot="{ hover }">
                <v-card
                  :elevation="hover ? 24 : 6"
                  color="#385F73"
                  dark
                >
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                      <v-card-title
                        class="headline"
                        v-text="item.progName"
                      ></v-card-title>

                      <v-card-subtitle v-text="item.desc"></v-card-subtitle>
                    </div>
                    <div class="float-right">
                      <v-list-item-avatar
                        class="rounded-xl"
                        size="80"
                        tile
                        color="black"
                        style="margin:12px 16px 0 16px;"
                      >
                        <h2>{{item.time}}</h2>
                      </v-list-item-avatar>
                      <p class="text-center">Minutes</p>
                    </div>
                  </div>

                  <v-progress-linear
                    v-model="item.progression"
                    height="20"
                  >
                    <template v-slot="{ value }">
                      <strong>{{ Math.ceil(value) }}%</strong>
                    </template>
                  </v-progress-linear>
                </v-card>
              </template>
            </v-hover>
          </v-col>
        </v-row>
    </div>
</template>

<script>
  export default {
    data: () => ({
      items:[
        {
          progName: "HTML",
          progression: 50,
          desc: "testing",
          time: "20",
        },
        {
          progName: "CSS",
          progression: 50,
          desc: "testing",
          time: "20",
        },
        {
          progName: "Javascript",
          progression: 50,
          desc: "testing",
          time: "20",
        },
        
      ]

    }),
  }
</script>

<style scoped>

</style>
