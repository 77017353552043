<template>
  <div class="campaign-create">

    <!-- Step 1 -->
    <div v-if="step === 1">
      <v-row class="justify-center">
        <v-col md="6" lg="6" sm="12">
          <stepper-dot :current-step="1" class="mt-10 mb-16" :dots-count="2"></stepper-dot>
          <div class="text-h5 text-center">What is your campaign name?</div>
      <v-form ref="stepOneForm" v-model="stepOneValid">
        <v-text-field
            v-model="formData.campaign_name"
            :rules="[value => !!value || 'Campaign Name is required.']"
            label="Campaign Name"
            outlined
            dense
            width
            class="mt-5 mb-10 w-50 mx-auto"
        />
      </v-form>

        </v-col>
      </v-row>

      <v-row class="justify-center">
        <v-col md="6" lg="6" sm="12">
          <div class="text-h5 text-center">Campaign tags</div>
          <v-chip-group
              v-model="formData.tags"
              column
              center
              multiple
              active-class="campaign-chip-active"
          >
            <v-chip v-for="(tag, i) in getTags" filter :value="tag.id" class="px-5" :key="i"> {{ tag.name }} </v-chip>
          </v-chip-group>

          <v-combobox multiple
                      ref="tagRef"
                      v-model="formData.custom_tags"
                      label="Type to add custom tags"
                      prepend-inner-icon="search"
                      chips
                      deletable-chips
                      outlined
                      dense
                      width
                      @blur="onComboboxBlur"
                      class="custom-tags-input mt-5 mb-10 w-50 mx-auto"
          >
          </v-combobox>

        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <div class="d-flex justify-center">
            <v-btn class="mx-2" :to="{ name: 'dashboard.index'}">Cancel</v-btn>
            <v-btn class="mx-2" color="primary" @click="proceedStepTwo">Next</v-btn>
          </div>
        </v-col>
      </v-row>
    </div>

    <!-- Step 2 -->
    <div v-else>
      <v-row class="justify-center">
        <v-col md="6" lg="6" sm="12">
          <stepper-dot :current-step="2" class="mt-10 mb-16" :dots-count="2"></stepper-dot>
          <div class="text-h5 text-center">Link your domain</div>
          <v-form ref="stepTwoForm" v-model="stepTwoValid">
            <v-text-field
                prepend-inner-icon="$appLinkIcon"
                v-model="formData.domain"
                :rules="[value => !!value || 'Domain Link is required.']"
                placeholder="Domain link"
                :suffix="getAppDomain"
                outlined
                dense
                width
                class="mt-5 mb-10 w-50 mx-auto"
            />
          </v-form>
        </v-col>
      </v-row>

      <v-row class="mt-10">
        <v-col>
          <div class="d-flex justify-center">
            <v-btn class="mx-2" @click="backToStepOne">
              <v-icon small class="mr-2">$appArrowBackIcon</v-icon>
              Back
            </v-btn>
            <v-btn class="mx-2" :loading="isLoading" color="primary" @click="submitCampaign">Next</v-btn>
          </div>
        </v-col>
      </v-row>
    </div>

  </div>
</template>

<script>
import StepperDot from "@/components/StepperDot";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "Create",
  components: {
    StepperDot,
  },
  data() {
    return {
      step: 1,
      stepOneValid: false,
      stepTwoValid: false,
      isLoading: false,
      formData: {
        campaign_name: null,
        tags: [],
        domain: null,
        custom_tags: []
      },
    };
  },
  computed: {
    ...mapGetters("tag", [
        'getTags'
    ]),
    ...mapGetters("global", [
        'getAppDomain'
    ]),
  },
  methods: {
    ...mapActions('campaign', [
       'postCampaign'
    ]),
    proceedStepTwo() {
      this.$refs.stepOneForm.validate()

      if (!this.stepOneValid) {
        return true
      }
      this.step = 2
      console.log(this.formData.custom_tags)

    },
    backToStepOne () {
      this.step = 1
    },
    submitCampaign () {
      this.isLoading = true;
      this.$refs.stepTwoForm.validate()

      if (!this.stepTwoValid) {
        return true
      }

      this.postCampaign(this.formData)
          .then((res) => {
            if(res.data.success) {
              this.isLoading = false
              this.$router.push({name: 'funnel.builder', query: {campaign_id: res.data.campaign.id}})
            }
      })
      .catch((err) => {
        if (typeof err.response != 'undefined' && err.response.status === 400 && err.response.data.message) {
          // eslint-disable-next-line no-unused-vars
          Object.keys(err.response.data.message).forEach((key,index) => {
            if (typeof err.response.data.message[key] !== 'undefined') {
              window.toastr['error'](err.response.data.message[key])
            }
          });
        }
        this.isLoading = false
      })
    },
    onComboboxBlur(e) {
      this.formData.custom_tags.push(e.srcElement.value)
    }
  }
};
</script>

<style lang="scss">
.campaign-create {
  .mdi-menu-down {
    display: none;
  }
  .v-select.v-input--dense .v-chip {
    margin: 5px 2px 0px 2px;
    background: #28a745;
    color: #FFFFFF !important;
    .v-icon {
      color: #FFFFFF !important;
    }
  }
  .v-input__append-inner {
    width: 0px;
  }
}

</style>
