<template>
  <div class="register">
    <div
        class="dot"
        v-for="(dot, index) in Array(dotsCount).fill(0)"
        :key="dot+'_'+index+'_'"
        :class="{'active-dot': currentStep === index + 1}"
    ></div>
  </div>
</template>

<script>
export default {
  name: "RegisterStep",
  props: {
    currentStep: {
      type: Number,
      default: 1
    },
    dotsCount: {
      type: Number,
      default: 3
    }
  }
}
</script>

<style lang="scss" scoped>
.register {
  display: flex;
  justify-content: center;

  .dot {
    margin: 0 5px;
    width: 11px;
    height: 11px;
    background-color: #e5e5e5;
    border-radius: 50px;

    &.active-dot {
      background-color: #0275ff !important;
    }
  }
}
</style>
