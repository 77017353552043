import * as types from './mutation-types'
//import * as appType from '../../mutation-types'
//import jwtToken from "../../../helpers/jwt-token";

// eslint-disable-next-line no-unused-vars
export const fetchTests = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    window.axios.get('/api/v1/tests', {params: {fullData: true}}).then((res) => {
      commit(types.SET_TESTS, res.data.data);
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

// eslint-disable-next-line no-unused-vars
export const fetchTestWithToken = ({ commit, dispatch, state }, token) => {
  return new Promise((resolve, reject) => {
    window.axios.post('/api/v1/invitation-test', token).then((res) => {
      resolve(res)
    })
      .catch((err) => {
        reject(err)
      })
  })
}
// resume your test, go to latest pause point
// export const getCurrentTestProgress
