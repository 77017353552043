<template>
  <div class="email-configurator-component">
    <div class="d-flex justify-space-between align-center">
      <div class="text-subtitle-1">Configure Delay Timer</div>
      <v-btn x-small color="grey lighten-2" fab>
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-divider class="my-3"></v-divider>
    <label for="email-title">Wait at least</label>
    <v-text-field
      id="email-title"
      outlined
      dense
      :hide-details="true"
      v-model="formData.title"
    ></v-text-field>

    

    <v-divider class="my-3"></v-divider>
  </div>
</template>

<script>
export default {
  name: "Email",
  data() {
    return {
      templateOptions: [
        { text: "Template 1", value: "template_1" },
        { text: "Template 2", value: "template_2" },
      ],
      currentNodeId: null,
      formData: {
        title: null,
        preHeader: null,
        sendToOption: [],
        emails: null,
        enableMailchimp: false,
      },
    };
  },
  watch: {
    formData: {
      deep: true,
      handler () {
        this.pushUpdates()
      }
    }
  },
  methods: {
    // please prepare this methods in other configuration
    // this method has to fire before the drawer appear
    loadSettingStubs (nodeId) {
      this.currentNodeId = nodeId
      this.formData.title = null
      this.formData.preHeader = null
      this.formData.sendToOption = []
      this.formData.emails = null
      this.formData.enableMailchimp = null
    },
    // this method to include
    setCurrentNode (nodeId, settings) {
      this.currentNodeId = nodeId
      this.formData = {...settings}
    },
    pushUpdates () {
      this.$emit('UpdateConfiguration', {
        nodeId: this.currentNodeId,
        settings: {...this.formData}
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.email-configurator-component {
  opacity: 0.85;
}
</style>