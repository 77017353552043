<template>
  <div class="pa-4">
    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '' : '8'">
        <h2>All Memberships</h2>
      </v-col>
      <v-spacer></v-spacer>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '' : '4'" class="text-right">
        <v-btn
          color="primary"
          depressed
          dark
          :to="{ name: 'membership.create' }"
          ><v-icon v-if="!$vuetify.breakpoint.mdAndUp">
            mdi-plus
          </v-icon>
          <div v-else>
            + New Membership
          </div>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="campaign-card-row">
      <v-col
        v-for="membership in memberships"
        :key="membership"
        class="px-5 my-1"
        :cols="12"
        :sm="4"
        :md="4"
        :lg="3"
      >
        <v-card
          class="mx-auto"
          max-width="400"
          elevation="0"
          color="transparent"
        >
          <v-hover v-slot="{ hover }">
            <v-img
              class="white--text align-end rounded-lg elevation-2"
              height="200px"
              :src="membership.image"
            >
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out grey darken-3 v-card--reveal text-h2 white--text"
                  style="height: 200px;"
                >
                  <v-btn class="primary mx-auto" depressed dark style="top:40%">
                    View Membership</v-btn
                  >
                </div>
                <v-row class="pa-4">
                  <v-col>
                    <v-btn small rounded class="white--text" color="blue">
                      <v-icon small color="white"
                        >mdi-account-multiple-outline</v-icon
                      >
                      {{ membership.member }}
                    </v-btn>
                  </v-col>
                  <v-col>
                    <div class="d-flex align-center justify-end">
                      <v-btn
                        small
                        rounded
                        class="white--text"
                        color="teal accent-4"
                      >
                        <v-icon small color="white"
                          >mdi-file-document-outline</v-icon
                        >
                        {{ membership.doc }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-expand-transition>
            </v-img>
          </v-hover>
          <div>
            <v-row class="mt-1">
              <v-col>
                <div class="text-subtitle-2 pb-0">
                  <div>{{ membership.title }}</div>
                </div>
                <div
                  class="py-0 text-caption grey--text text-decoration-underline"
                >
                  {{ membership.text }}
                </div>
              </v-col>
              <v-col class="d-flex justify-end">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      elevation="2"
                      fab
                      icon
                      color="white"
                      outlined
                      width="40px"
                      v-bind="attrs"
                      v-on="on"
                      @click="editItem(item)"
                    >
                      <v-icon color="black">mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item link>Unpublish</v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-list dense>
                    <v-list-item link>Page Builder</v-list-item>
                    <v-list-item link>Portal Editor</v-list-item>
                    <v-list-item link>Setting</v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-list dense>
                    <v-list-item link>
                      <v-icon class="mr-2" color="red">
                        mdi-delete
                      </v-icon>
                      <div style="color:red;">Delete</div>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    data: () => ({
      datePick: ["", ""],
      memberships: [
        {
          title: "Book Club Membership V1",
          image: "https://cdn.vuetifyjs.com/images/cards/house.jpg",
          text: "bookclubv1.fusion.io",
          member: "5",
          doc: "5",
        },
        {
          title: "Marketing Masterclass",
          image: "https://cdn.vuetifyjs.com/images/cards/road.jpg",
          text: "marketingmasterclass.fusion.io",
          member: "1.2k",
          doc: "13",
        },
        {
          title: "Home Cooking Course",
          image: "https://cdn.vuetifyjs.com/images/cards/plane.jpg",
          text: "homecooking.fusion.io",
          member: "45",
          doc: "15",
        },
        {
          title: "Facebook Ads 101",
          image: "https://cdn.vuetifyjs.com/images/cards/house.jpg",
          text: "facebookads101.fusion.io",
          member: "32",
          doc: "9",
        },
        {
          title: "Marketing",
          image: "https://cdn.vuetifyjs.com/images/cards/road.jpg",
          text: "marketingbook.fusion.io",
          member: "32",
          doc: "9",
        },
        {
          title: "Plans",
          image: "https://cdn.vuetifyjs.com/images/cards/house.jpg",
          text: "plan.fusion.io",
          member: "5",
          doc: "5",
        },
      ],
    }),
    computed: {
      dateRangeText() {
        return this.datePick.join(" - ");
      },
    },
  };
</script>

<style scoped></style>
