import Vue from "vue";
import Router from "vue-router";
import jwtToken from "./helpers/jwt-token";
import store from "./store/index";

Vue.use(Router);

// Auth
// -------------------------------------
import LayoutLogin from "./views/layouts/LayoutLogin";
import AuthMain from "./views/auth/Main";
import Plan from "./views/auth/register/Plan";
import RegistrationPayment from "./views/auth/register/Payment";

// Dashboard
// -------------------------------------
import DashboardIndex from "./views/dashboard/Index";
import DashboardCreate from "./views/dashboard/Create";
import DashboardSetting from "./views/dashboard/Setting";

// Affiliates
//--------------------------------------
import AffiliateIndex from "./views/affiliates/Index";
import AffiliateCreate from "./views/affiliates/Create";
import AffiliateProjectSum from "./views/affiliates/ProjectSummary";
import AffiliateReferrer from "./views/affiliates/Referrer";

// Membership
//--------------------------------------
import MembershipIndex from "./views/membership/Index";
import MembershipView from "./views/membership/View";
import MembershipCreate from "./views/membership/Create";

// Projects
//--------------------------------------
import ProductIndex from "./views/products/Index";
import ProductCreate from "./views/products/Create";

//Contacts
//--------------------------------------
import ContactIndex from "./views/contacts/Index";
import ContactCreate from "./views/contacts/Create";
import ContactView from "./views/contacts/View";

// Job
// -------------------------------------
import JobIndex from "./views/job/Index";

// Test
// -------------------------------------
import TestIndex from "./views/test/Index";
// import TestProgramming from './views/test/programming'
// import TestMCQ from './views/test/MCQ'

// Discover
// -------------------------------------
import DiscoverIndex from "./views/discover/Index";

// Campaign
// -------------------------------------
import CampaignCreate from "./views/campaign/Create";
import CampaignSetting from "./views/campaign/Setting";

// Funnels
// -------------------------------------
import FunnelBuilder from "./views/funnel/Builder";
import FunnelAnalytic from "./views/funnel/analytics/Index";

//import Programming from './views/test/programming'
import LayoutTest from "./views/layouts/LayoutTest";
import LayoutApp from "./views/layouts/LayoutApp";
import JoinTest from "./views/test/JoinTest";
import VerifiedUser from "../../app/src/views/auth/VerifiedUser";
import NotFoundPage from "./views/errors/404";
import LayoutRegister from "./views/layouts/LayoutRegister";
import Register from "./views/auth/Register";
import LayoutNoNav from "./views/layouts/LayoutNoNav";
import LayoutFunnel from "./views/layouts/LayoutFunnel";

const routes = [
  {
    path: "/login",
    component: LayoutLogin,
    meta: { redirectIfAuthenticated: true },
    children: [
      {
        path: "/",
        name: "login",
        component: AuthMain,
      },
    ],
  },
  {
    path: "/register",
    component: LayoutRegister,
    children: [
      {
        path: "/",
        name: "register.index",
        meta: { redirectIfAuthenticated: true },
        component: Register,
      },
      {
        path: "plan",
        name: "register.plan",
        meta: { requiresAuth: true },
        component: Plan,
      },
      {
        path: "payment",
        name: "register.payment",
        meta: { requiresAuth: true },
        component: RegistrationPayment,
      },
    ],
  },
  {
    path: "/register/verify/:token?",
    component: LayoutLogin,
    meta: { redirectIfAuthenticated: true },
    children: [
      {
        path: "/",
        name: "verify-user",
        component: VerifiedUser,
      },
    ],
  },
  {
    path: "/",
    component: LayoutApp,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/dashboard/Index",
        name: "dashboard.index",
        component: DashboardIndex,
      },
      {
        path: "/dashboard/Create",
        name: "dashboard.create",
        component: DashboardCreate,
      },
      {
        path: "/dashboard/Setting",
        name: "dashboard.setting",
        component: DashboardSetting,
      },
      {
        path: "/products/Index",
        name: "products.index",
        component: ProductIndex,
      },
      {
        path: "/products/Create",
        name: "products.create",
        component: ProductCreate,
      },
      {
        path: "/affiliates/Index",
        name: "affiliates.index",
        component: AffiliateIndex,
      },
      {
        path: "/affiliates/Create",
        name: "affiliates.create",
        component: AffiliateCreate,
      },
      {
        path: "/affiliates/ProjectSummary",
        name: "affiliates.projectSummary",
        component: AffiliateProjectSum,
      },
      {
        path: "/affiliates/Referrer",
        name: "affiliates.referrer",
        component: AffiliateReferrer,
      },
      {
        path: "/membership/Index",
        name: "membership.index",
        component: MembershipIndex,
      },
      {
        path: "/membership/View",
        name: "membership.view",
        component: MembershipView,
      },
      {
        path: "/membership/Create",
        name: "membership.create",
        component: MembershipCreate,
      },
      {
        path: "/contacts/index",
        name: "contacts.index",
        component: ContactIndex,
      },
      {
        path: "/contacts/create",
        name: "contacts.create",
        component: ContactCreate,
      },
      {
        path: "/contacts/view/:id",
        name: "contacts.view",
        component: ContactView,
      },
      {
        path: "/test",
        name: "test.index",
        component: TestIndex,
      },
      {
        path: "/job",
        name: "job.index",
        component: JobIndex,
      },
      {
        path: "/discover",
        name: "discover.index",
        component: DiscoverIndex,
      },
    ],
  },
  {
    path: "/campaign",
    component: LayoutNoNav,
    meta: { requiresAuth: true },
    children: [
      {
        path: "create",
        name: "campaign.create",
        component: CampaignCreate,
      },
    ],
  },
  {
    path: "/funnel",
    component: LayoutFunnel,
    meta: { requiresAuth: true },
    children: [
      {
        path: "builder",
        name: "funnel.builder",
        component: FunnelBuilder,
      },
      {
        path: "analytic",
        name: "funnel.analytic",
        component: FunnelAnalytic,
      },
      {
        path: "/campaign/Setting",
        name: "campaign.setting",
        component: CampaignSetting,
      },
    ],
  },
  // {
  //   path:'/test',
  //   component: LayoutTest,
  //   meta:{requiresAuth:true},
  //   children:[
  //     {
  //       path: 'programming',
  //       name: 'test.programming',
  //       component: TestProgramming
  //     },
  //     {
  //       path: 'MCQ',
  //       name: 'test.MCQ',
  //       component: TestMCQ
  //     },
  //   ]
  // },
  {
    path: "/test-join/:token?",
    component: LayoutTest,
    children: [
      {
        path: "/",
        name: "test.join",
        component: JoinTest,
      },
    ],
  },
  { path: "*", component: NotFoundPage },
];

const router = new Router({
  routes,
  mode: "history",
  base: "/",
  linkExactActiveClass: "active",
});

router.beforeEach(async (to, from, next) => {
  if (jwtToken.getToken() && !store.getters["isAppLoaded"]) {
    await store.dispatch("bootstrap");
  }

  if (
    to.matched.some((m) => m.meta.redirectIfAuthenticated) &&
    store.getters["auth/isAuthenticated"]
  ) {
    if (store.getters["auth/getIsUserManagement"]) {
      return next("/v1"); // admin check
    } else {
      return next({ name: "dashboard.index" });
    }
  }

  // requires authentication
  if (to.matched.some((m) => m.meta.requiresAuth)) {
    if (store.getters["isAppLoaded"] || jwtToken.getToken()) {
      // redirect admin user to backend panel side
      if (store.getters["auth/getIsUserManagement"]) {
        return next("/v1"); // admin homepage
      }
    } else {
      return next({ name: "login" });
    }
  }

  if (
    jwtToken.getToken() &&
    !store.getters["auth/getPlan"] &&
    to.name !== "register.plan"
  ) {
    return next({ name: "register.plan" });
  } else if (
    jwtToken.getToken() &&
    store.getters["auth/getPlan"] &&
    !store.getters["auth/getBillingInfo"] &&
    to.name !== "register.payment"
  ) {
    return next({ name: "register.payment" });
  }

  return next();
});

export default router;
