import * as types from './mutation-types'

// eslint-disable-next-line no-unused-vars
export const fetchCandidate = ({ commit, dispatch, state }, token) => {
  return new Promise((resolve, reject) => {
    window.axios.get('/api/v1/candidate/join', {params: {token: token}}).then((res) => {
      commit(types.SET_CANDIDATE, res.data.candidate);
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

// eslint-disable-next-line no-unused-vars
export const saveCurrentTestAnswer = ({commit, dispatch, state}, payload) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/v1/candidate/save-current-answer`, payload).then((res) => {
      resolve(res)
    })
      .catch((err) => {
        reject(err)
      })
  })
}
