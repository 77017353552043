<template>
  <div class="pa-4 campaign-setting">
    <v-row>
      <v-col cols="12">
        <v-form ref="campaignForm" v-model="valid">
          <v-row>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '2' : ''"> </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '8' : ''">
              <v-container class="pa-0" fluid>
                <div class="text--primary text-h5">Campaign Settings</div>
                <!-- General -->
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
                    <div class="text--primary mt-3 font-weight-black">
                      General
                    </div>
                    <p class="mt-3 mb-0 campaign-font-light">
                      This is the general configuration of your campaign.
                    </p>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '8' : ''">
                    <v-card class="form-card mb-3">
                      <v-card-text>
                        <div class="text--primary">
                          Campaign Name
                        </div>
                        <v-text-field
                          v-model="formData.campaign_name"
                          :rules="[
                            (value) => !!value || 'Campaign Name is required.',
                          ]"
                          label="Campaign Name"
                          outlined
                          dense
                          flat
                          hide-details="auto"
                          solo
                        ></v-text-field>
                        <div class="text--primary mt-3">
                          Campaign Domain
                        </div>
                        <v-text-field
                          v-model="formData.domain"
                          :rules="[
                            (value) => !!value || 'Domain Link is required.',
                          ]"
                          label="domain"
                          outlined
                          dense
                          flat
                          hide-details="auto"
                          solo
                        ></v-text-field>
                        <div class="text--primary mt-3">
                          Campaign Tags
                        </div>
                        <v-combobox
                          multiple
                          v-model="formData.tags"
                          chips
                          deletable-chips
                          outlined
                          dense
                          flat
                        >
                        </v-combobox>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-divider class="my-5"></v-divider>
                <!-- Theme -->
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
                    <div class="text--primary font-weight-black">
                      Current Theme
                    </div>
                    <p class="mt-3 mb-0 campaign-font-light">
                      Theme that is currently selected for current campaign.
                    </p>
                    <p class="mt-3 mb-0 campaign-font-light">
                      To change current theme, pick and apply themes from the
                      Theme library.
                    </p>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '8' : ''">
                    <v-card class="form-card mb-3">
                      <v-card-text>
                        <div class="text--primary text-subtitle-2">
                          Funnel Booster v2
                        </div>
                        <p class="mt-1 mb-0 campaign-font-light">
                          Last updated 20 January 2021
                        </p>
                        <v-divider class="my-3"></v-divider>
                        <div class="text--primary text-subtitle-2">
                          Theme Library
                        </div>
                        <p class="mt-1 mb-0 campaign-font-light">
                          Last updated 20 January 2021
                        </p>
                        <v-row class="campaign-card-row">
                          <v-col
                            v-for="membership in memberships"
                            :key="membership"
                            class="px-2 my-1"
                            :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
                          >
                            <v-card
                              class="mx-auto"
                              max-width="400"
                              elevation="0"
                              color="transparent"
                            >
                              <v-img
                                class="white--text align-end rounded-lg elevation-2"
                                height="150px"
                                :src="membership.image"
                              >
                              </v-img>
                              <div>
                                <v-row class="mt-1">
                                  <v-col>
                                    <div class="text-subtitle-2 pb-0">
                                      <div>{{ membership.title }}</div>
                                    </div>
                                    <div
                                      class="py-0 text-caption grey--text text-decoration-underline"
                                    >
                                      {{ membership.text }}
                                    </div>
                                  </v-col>
                                  <v-col class="d-flex justify-end">
                                    <v-menu offset-y>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          class="campaign-card-option-btn"
                                          absolute
                                          elevation="2"
                                          fab
                                          icon
                                          color="white"
                                          outlined
                                          text
                                          width="40px"
                                          v-bind="attrs"
                                          v-on="on"
                                          @click="editItem(item)"
                                        >
                                          <v-icon color="black"
                                            >mdi-dots-horizontal</v-icon
                                          >
                                        </v-btn>
                                      </template>
                                      <v-list dense>
                                        <v-list-item link>Apply</v-list-item>
                                        <v-list-item link
                                          >Duplicate</v-list-item
                                        >
                                      </v-list>
                                      <v-divider></v-divider>
                                      <v-list dense>
                                        <v-list-item link>
                                          <v-icon class="mr-2" color="red">
                                            mdi-delete
                                          </v-icon>
                                          <div style="color:red;">Delete</div>
                                        </v-list-item>
                                      </v-list>
                                    </v-menu>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-divider class="my-5"></v-divider>
                <!-- Timezones -->
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
                    <div class="text--primary font-weight-black">
                      Timezones
                    </div>
                    <p class="mt-3 mb-0 campaign-font-light">
                      For delay timer calculation and more.
                    </p>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '8' : ''">
                    <v-card class="form-card mb-3">
                      <v-card-text>
                        <div class="text--primary">
                          Timezones
                        </div>
                        <v-autocomplete
                          v-model="formData.timezone_id"
                          :items="getTimezones"
                          item-text="name"
                          item-value="id"
                          outlined
                          dense
                          flat
                          hide-details="auto"
                        ></v-autocomplete>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-divider class="my-5"></v-divider>
                <!-- Payments Method -->
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
                    <div class="text--primary font-weight-black">
                      Payments Method
                    </div>
                    <p class="mt-3 mb-0 campaign-font-light">
                      This is the currency your customer will see when viewing
                      your products or in your website.
                    </p>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '8' : ''">
                    <v-card class="form-card mb-3">
                      <v-card-text>
                        <div class="text--primary">
                          Payments Method Available
                        </div>
                        <v-checkbox
                          class="text-h6 font-weight-medium"
                          hide-details="auto"
                          label="Bilplz"
                        ></v-checkbox>
                        <v-checkbox
                          class="text-h6 font-weight-medium"
                          hide-details="auto"
                          label="Stripe"
                        ></v-checkbox>
                        <v-checkbox
                          class="text-h6 font-weight-medium"
                          hide-details="auto"
                          label="Paypal"
                        ></v-checkbox>
                        <p class="mt-3 mb-0 campaign-font-light">
                          To add more payment methods, please go to
                          <a class="text-decoration-underline"
                            >Payment Setting</a
                          >
                          in your dashboard.
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-divider class="mt-0"></v-divider>
                    <div class="d-flex align-center justify-end mt-5">
                      <div>
                        <v-btn
                          @click="saveCampaign"
                          class="ml-3"
                          color="primary"
                          dark
                          depressed
                        >
                          Save
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '2' : ''"> </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";

  export default {
    data: () => ({
      valid: false,
      isLoading: false,
      formData: {
        id: null,
        campaign_name: null,
        tags: [],
        domain: null,
        timezone_id: null,
      },
      memberships: [
        {
          title: "Facebook Ads 101",
          image: "https://cdn.vuetifyjs.com/images/cards/house.jpg",
          text: "facebookads101.fusion.io",
        },
        {
          title: "Marketing",
          image: "https://cdn.vuetifyjs.com/images/cards/road.jpg",
          text: "marketingbook.fusion.io",
        },
        {
          title: "Plans",
          image: "https://cdn.vuetifyjs.com/images/cards/house.jpg",
          text: "plan.fusion.io",
        },
      ],
    }),
    mounted() {
      this.loadCampaign();
      this.formData.id = this.$route.query.campaign_id;
    },
    computed: {
      ...mapGetters("country", ["getTimezones"]),
      dateRangeText() {
        return this.datePick.join(" - ");
      },
      extension() {
        return this.file ? this.file.name.split(".").pop() : "";
      },
    },
    methods: {
      ...mapActions("campaign", ["fetchCampaign", "updateCampaign"]),
      loadCampaign() {
        this.fetchCampaign(this.$route.query.campaign_id).then((res) => {
          this.formData.campaign_name = res.data.campaign.campaign_name;
          this.formData.domain = res.data.campaign.campaign_domain;
          this.formData.tags = res.data.campaign.tags;
          this.formData.timezone_id = res.data.campaign.timezone_id;
        });
      },
      saveCampaign() {
        this.isLoading = true;

        this.$refs.campaignForm.validate();

        if (!this.valid) {
          window.toastr["error"]("Please fill up the required field");
          return true;
        }

        this.updateCampaign(this.formData)
          .then((res) => {
            if (res.data.success) {
              this.isLoading = false;
              this.$router.push({ name: "dashboard.index" });
            }
          })
          .catch((err) => {
            if (
              typeof err.response != "undefined" &&
              err.response.status === 400 &&
              err.response.data.message
            ) {
              // eslint-disable-next-line no-unused-vars
              Object.keys(err.response.data.message).forEach((key, index) => {
                if (typeof err.response.data.message[key] !== "undefined") {
                  window.toastr["error"](err.response.data.message[key]);
                }
              });
            }
            this.isLoading = false;
          });
      },
      getColor(type) {
        if (type == "Archived") return "grey";
        else return "green";
      },
      onChange(e) {
        var files = e.target.files || e.dataTransfer.files;

        if (!files.length) {
          this.dragging = false;
          return;
        }

        this.createFile(files[0]);
      },
      createFile(file) {
        if (file.size > 5000000) {
          alert("please check file size no over 5 MB.");
          this.dragging = false;
          return;
        }

        this.file = file;
        console.log(this.file);
        this.dragging = false;
      },
      removeFile() {
        this.file = "";
      },
      addTag() {
        this.allTags.push(this.tagValue);
        this.tagValue = null;
      },
    },
  };
</script>

<style lang="scss">
  .campaign-setting {
    .mdi-menu-down {
      display: none;
    }
    .v-select.v-input--dense .v-chip {
      margin: 2px 2px 0px 2px;
      background: #28a745;
      color: #ffffff !important;
      .v-icon {
        color: #ffffff !important;
      }
    }
    .v-input__append-inner {
      width: 0px;
    }
  }
</style>
