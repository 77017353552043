<template>
  <div>
    <v-row>
      <v-col class="mx-auto register-plan-main">
        <stepper-dot :current-step="currentStep" class="mb-9"></stepper-dot>
        <div class="text-h5 text-center mb-11">Pick a plan</div>

        <v-row class="d-flex flex-row justify-center">
          <v-col v-for="(plan, index) in getPlans"
                 :key="index"
                 class="plan-card md-4"
                 v-bind:class="{'featured-card': plan.plan_is_featured }"
          >
            <div v-if="plan.plan_is_featured" class="popular-banner">MOST POPULAR</div>
            <h4>{{ plan.plan_name }}</h4>
            <p>{{ plan.plan_short_des }}</p>
            <div>
              <span class="text-h4">{{ plan.plan_price }}</span
              ><span class="text-body">/month</span>
            </div>

            <v-btn :loading="isLoading" block class="button" @click="selectPlan(plan.id)"> Select </v-btn>

            <p class="text-body-1">All featured included:</p>
            <ul class="text-body-2">
              <li v-for="(feature, index) in plan.features" :key="index">{{ getFeatureText(feature) }}</li>
            </ul>

            <div class="my-4">
              <router-link :to="{ name: 'login' }" class="text-decoration-none"
                >See all features</router-link
              >
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import StepperDot from "@/components/StepperDot";
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "Plan",
  components: {
    StepperDot,
  },
  data() {
    return {
      currentStep: 2,
      isLoading: false
    };
  },
  computed: {
    ...mapGetters('plan', [
      'getPlans'
    ]),
  },
  mounted() {

  },
  methods: {
    ...mapActions('auth', [
      'signPlan'
    ]),
    selectPlan(plan_id) {
      this.isLoading = true

      this.signPlan({plan_id : plan_id})
        .then((res) => {
          this.isLoading = false
          if(res.data.success) {
            this.$router.push({name : 'register.payment'})
          } else {
            window.toastr['error']('Failed to create account')
          }
        })
    },
    getFeatureText(feature) {
      let desc = feature.feature_desc;
      if(desc.indexOf('%s')) {
        return desc.replace('%s', feature.feature_value)
      }
      return desc
    }
  }
};
</script>

<style lang="scss" scoped>
.button {
  background: #0075ff !important;
  color: white;
  padding: 10px;
  border-radius: 4px;
  margin: 15px 0;
}

.featured-card {
}

.plan-card {
  background: white;
  width: 255px;
  max-width: 300px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  margin: 10px 15px;
  padding: 10px 20px;
  box-sizing: border-box;
  position: relative;

  ul {
    list-style: none;
    padding-left: 1em;

    li {
      &:before {
        content: "";
        display: inline-block;
        height: 10px;
        width: 14px;
        background-image: url("/img/vector-stroke.svg");
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 0.5em;
      }
    }
  }

  &.featured-card {
    border-color: #12dbab;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    .popular-banner {
      position: absolute;
      font-size: 0.875rem;
      font-weight: bold;
      padding: 3px;
      background-color: #12dbab;
      color: white;
      letter-spacing: 4px;
      top: 0;
      left: -1px;
      width: calc(100% + 2px);
      border: 1px solid #12dbab;
      text-align: center;
      transform: translateY(-100%);
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
}
</style>
