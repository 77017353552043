<template>
  <div class="blank-page-configurator-component">
    <div class="d-flex justify-space-between align-center">
      <div class="text-subtitle-1">Configure Blank Page</div>
      <v-btn x-small color="grey lighten-2" @click="$emit('closeSetting')" fab>
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-divider class="my-3"></v-divider>

    <div class="website-card mt-3" v-show="!showCreatePageForm">
      <div class="website-url-title">
        <div class="title-block">
          {{ showData.page.url }}
        </div>
      </div>
      <div class="website-content-block">
        <div v-if="showData.page.id !== null">
          <div class="mb-3">
            <b>Name : </b> {{ showData.page.name }}
          </div>
          <div class="mb-3">
          <b>Status : </b> {{ showData.page.status_name }}
          </div>
          <v-btn dark block @click="goToPageBuilder" class="rounded-lg">Page Builder</v-btn>
        </div>
        <div v-else>
          No page setup yet.
        </div>
        
      </div>
    </div>

    <v-btn text color="primary" block @click="showCreatePageForm = true" v-if="!showCreatePageForm && !showData.page.id">
      Create a new page
    </v-btn>
    <v-btn text color="primary" block @click="editPageForm" v-if="!showCreatePageForm && showData.page.id">
      Edit page
    </v-btn>

    <!-- Page Form -->
    <div v-show="showCreatePageForm" class="mt-3">
      <v-btn color="black" dark @click="cancelPageForm" class="mb-3">Back</v-btn>
      <label for="page-selection" class="d-block">Page Name</label>
      <v-text-field
        id="page-name"
        outlined
        dense
        :hide-details="true"
        v-model="formData.page_name"
        :loading="createLoading"
      ></v-text-field>


      <label for="page-selection" class="d-block">Generated Slug</label>
      <v-text-field
        id="page-name"
        outlined
        dense
        :hide-details="true"
        v-model="formData.page_slug"
        :loading="createLoading"
      ></v-text-field>

      <label for="page-selection">Status</label>
      <v-select 
        :items="ref.status" 
        :hide-details="true" 
        v-model="formData.page_status_id" 
        dense 
        outlined
        :loading="createLoading"
      ></v-select>

      <label for="page-selection">Type</label>
      <v-select 
        :items="ref.pageType" 
        :hide-details="true" 
        v-model="formData.page_type_id" 
        dense 
        outlined
        :loading="createLoading"
      ></v-select>


      <v-btn 
        text 
        color="primary" 
        block 
        @click="createNewPage"
        :loading="createLoading"
      >
        Submit Page
      </v-btn>

    </div> <!--/end Page Form -->

    <v-divider class="my-3"></v-divider>
  </div>
</template>

<script>
export default {
  name: "BlankPage",
  data() {
    return {
      createLoading: false,
      currentNodeId: null,
      ref: {
        status: [],
        pageType: []
      },
      showCreatePageForm: false,
      showData: {
        page: {
          id: null,
          campaign_id: null,
          url: null,
          name: null,
          status_name: null,
        },
      },
      formData: {
        page_id: null,
        page_slug: null,
        page_name: null,
        page_status_id: null,
        page_type_id: null,
      },
    };
  },
  computed: {
    formPageName () {
      return this.formData.page_name
    }
  },
  watch: {
    formPageName () {
      if (! this.formData.page_name) return 

      this.formData.page_slug = '/' + this.formData.page_name
        .toLowerCase()
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'')
    }
  },
  async mounted () {
    await new Promise ((resolve, reject) => {
      window.axios.post('/api/funnel/node/ref/blank_page')
        .then(res => {
          this.ref.status = res.data.ref_status
          this.ref.pageType = res.data.ref_page_type
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  methods: {
    // please prepare this methods in other configuration
    // this method has to fire before the drawer appear
    loadSettingStubs (nodeId) {
      this.currentNodeId = nodeId
      this.formData.page_id = null
    },
    async loadNodeSetting () {
      return await new Promise((resolve, reject) => {
        window.axios.post('/api/funnel/node/show/blank_page', {
          node_id: this.currentNodeId
        })
          .then(res => {
            if (res.data.setting) {
              if (res.data.setting.page) {
                this.showData = res.data.setting
                this.formData.page_id = this.showData.page.id
                this.formData.page_slug = this.showData.page.slug
                this.formData.page_name = this.showData.page.name
                this.formData.page_status_id = this.showData.page.status
                this.formData.page_type_id = this.showData.page.page_type
              } else {
                this.showData = {...this.setDefaultShowDataStub()}
                this.formData.page_id = null
                this.formData.page_slug = null
                this.formData.page_name = null
                this.formData.page_status_id = null
                this.formData.page_type_id = null
              }
            } else {
              this.showData = {...this.setDefaultShowDataStub()}
              this.formData.page_id = null
              this.formData.page_slug = null
              this.formData.page_name = null
              this.formData.page_status_id = null
              this.formData.page_type_id = null
            }
            resolve(res)
          })
           .catch((err) => {
            reject(err)
          })
      })
    },
    // this method to include
    async setCurrentNode (nodeId, settings) { // eslint-disable-line no-unused-vars
      this.currentNodeId = nodeId
      await this.loadNodeSetting()
    },
    pushUpdates () {
      this.$emit('UpdateConfiguration', {
        nodeId: this.currentNodeId,
        settings: {...this.formData}
      })
    },
    cancelPageForm () {
      this.formData.page_id = null
      this.formData.page_slug = null
      this.formData.page_name = null
      this.formData.page_status_id = null
      this.formData.page_type_id = null
      this.showCreatePageForm = false
    },
    editPageForm () {
      this.formData.page_id = this.showData.page.id
      this.formData.page_slug = this.showData.page.slug
      this.formData.page_name = this.showData.page.name
      this.formData.page_status_id = this.showData.page.status
      this.formData.page_type_id = this.showData.page.page_type
      this.showCreatePageForm = true
    },
    async createNewPage () {
      this.createLoading = true
      return await new Promise ((resolve, reject) => {
        window.axios.post('/api/funnel/node/store/blank_page', {
          node_id: this.currentNodeId,
          ...this.formData
        })
          .then(res => {
            if (res.data.success) {
              window.toastr['success']('Successfully store page')
              this.showData.page = res.data.page
              this.showCreatePageForm = false
              this.pushUpdates()
            } else {
              window.toastr['error'](res.data.msg)
            }
            this.createLoading = false
            resolve(res)
          })
          .catch(err => {
            this.createLoading = false
            reject(err)
          })
      })
      
    },
    goToPageBuilder () {
      if (this.showData.page.campaign_id && this.showData.page.id) {
        window.location.href = `/web/wb?page_id=${this.showData.page.id}&campaign_id=${this.showData.page.campaign_id}`
      }
    },
    setDefaultShowDataStub () {
      return {
        page: {
          id: null,
          campaign_id: null,
          url: null,
          name: null,
          status_name: null,
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.blank-page-configurator-component {
  opacity: 0.85;
}
.website-card {
  border-radius: 20px;
  border: 1px solid rgb(51, 51, 51);
  min-height: 100px;
}
.website-url-title {
  background-color: #eaeaea;
  width: 100%;
  border-bottom: 1px solid rgb(51, 51, 51);
  padding: 8px 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  .title-block {
    border-radius: 30px;
    width: 100%;
    color: rgb(51, 51, 51);
    font-size: 0.85rem;
    background-color: white;
    min-height: 1rem;
    padding: 0 1rem;
  }
}

.website-content-block {
  padding: 10px 15px;
}
</style>