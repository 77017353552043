<template>
  <div class="funnel-builder-main">
    <v-overlay :value="isDataLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <!-- first menu -->
    <div class="first-menu d-flex align-start z-2" v-if="!isDataLoading">
      <v-card
        width="120px"
        class="rounded-lg"
        elevation="6"
        origin="center left"
      >
        <v-card-text class="pa-6">
          <div class="text-caption">PAGES</div>

          <v-row class="mt-2">
            <!-- Page Nodes -->
            <v-col
              v-for="pageNode in pageNodes"
              :key="'node_' + pageNode.type"
              cols="12"
              class="text-center pa-1"
            >
              <v-btn
                outlined
                class="page-button"
                small
                @click="addNode(pageNode)"
              >
                <v-icon>{{ pageNode.icon }}</v-icon>
              </v-btn>
              <div class="text-center text-caption">{{ pageNode.label }}</div>
            </v-col>
          </v-row>

          <v-divider class="my-4"></v-divider>

          <div class="text-caption">WIDGETS</div>

          <v-row class="mt-2">
            <!-- Widget Nodes -->
            <v-col
              v-for="widgetNode in widgetNodes"
              :key="'node_' + widgetNode.type"
              cols="12"
              class="text-center pa-1"
            >
              <v-btn
                outlined
                class="page-button"
                small
                @click="addNode(widgetNode)"
              >
                <v-icon>{{ widgetNode.icon }}</v-icon>
              </v-btn>
              <div class="text-center text-caption">{{ widgetNode.label }}</div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>

    <!-- bottom menu -->
    <v-card class="rounded-lg bottom-right-menu z-1" elevation="6">
      <v-card-text class="pa-1">
        <div class="d-flex align-center">
          <v-btn
            text
            small
            class="px-2 py-5 text-transform-unset"
            @click="openDrawer"
          >
            <v-icon small class="mr-2">$appLayerIcon</v-icon>
            Auto Arrange
          </v-btn>
          <v-divider class="mx-1" vertical></v-divider>
          <v-btn text x-small class="px-2 py-5">
            <v-icon small>$appZoomOutIcon</v-icon>
          </v-btn>
          <div class="text-body-2">100%</div>
          <v-btn text x-small class="px-2 py-5">
            <v-icon small>$appZoomInIcon</v-icon>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>

    <!-- Setting right drawer (modal) -->
    <v-navigation-drawer
      v-model="settingDrawer"
      right
      absolute
      class="elevation-1"
      width="350px"
      :permanent="settingDrawer"
      temporary
    >
      <v-container>
        <email-configuration
          @UpdateConfiguration="nodeUpdateConfiguration"
          @closeSetting="settingDrawer = false"
          ref="configure_email_component"
          v-show="showingSettings === 'email_widget'"
        ></email-configuration>
        <delay-timer
          @UpdateConfiguration="nodeUpdateConfiguration"
          @closeSetting="settingDrawer = false"
          ref="configure_delay_timer_component"
          v-show="showingSettings === 'delay_timer_widget'"
        ></delay-timer>
        <blank-page-configuration
          @UpdateConfiguration="nodeUpdateConfiguration"
          @closeSetting="settingDrawer = false"
          ref="configure_blank_page_component"
          v-show="showingSettings === 'blank_page'"
        ></blank-page-configuration>
        <sales-page-configuration
          @UpdateConfiguration="nodeUpdateConfiguration"
          @closeSetting="settingDrawer = false"
          ref="configure_sales_page_component"
          v-show="showingSettings === 'sale_page'"
        ></sales-page-configuration>
        <checkout-page-configuration
          @UpdateConfiguration="nodeUpdateConfiguration"
          @closeSetting="settingDrawer = false"
          ref="configure_checkout_page_component"
          v-show="showingSettings === 'checkout_page'"
        ></checkout-page-configuration>
        <!-- <v-btn color="primary" block @click="settingDrawer = false">Save</v-btn> -->
      </v-container>
    </v-navigation-drawer>

    <div>
      <funnel-main-chart
        :scene.sync="scene"
        @nodeClick="nodeClick"
        @nodeDelete="nodeDelete"
        @linkBreak="linkBreak"
        @linkAdded="linkAdded"
        @canvasClick="canvasClick"
        @openConfigure="openConfigure"
        height="94vh"
      />
    </div>
  </div>
</template>

<script>
import FunnelMainChart from "./parts/FunnelMainChart";
import EmailConfiguration from "./configuration/Email";
import BlankPageConfiguration from "./configuration/BlankPage";
import SalesPageConfiguration from "./configuration/SalesPage";
import CheckoutPageConfiguration from "./configuration/CheckoutPage";
import DelayTimer from "./configuration/DelayTimer.vue";

export default {
  name: "Builder",
  components: {
    FunnelMainChart,
    EmailConfiguration,
    DelayTimer,
    BlankPageConfiguration,
    SalesPageConfiguration,
    CheckoutPageConfiguration,
  },
  data() {
    return {
      isDataLoading: true,
      campaign_id: null,
      showingSettings: "email_widget",
      refValues: {
        blank_page: {
          icon: "$appFilePlusIcon",
          label: "Blank",
        },
        checkout_page: {
          icon: "$appFilePlusIcon",
          label: "Checkout",
        },
        opt_in_page: {
          icon: "$appFilePlusIcon",
          label: "Opt In",
        },
        sale_page: {
          icon: "$appFilePlusIcon",
          label: "Sales",
        },
        email_widget: {
          icon: "$appMailIcon",
          label: "Email",
        },
        delay_timer_widget: {
          icon: "$appClockIcon",
          label: "Email",
        },
        split_test_widget: {
          icon: "$appFileIcon",
          label: "Split Test",
        },
        tagging_widget: {
          icon: "$appTagIcon",
          label: "Tagging",
        },
        sms_widget: {
          icon: "$appSendIcon",
          label: "SMS",
        },
        order_bump_widget: {
          icon: "$appPackageIcon",
          label: "Order Bump",
        },
        membership_widget: {
          icon: "$appUsersIcon",
          label: "Membership",
        },
        upsell_widget: {
          icon: "$appPackageIcon",
          label: "Upsell",
        },
        affiliate_widget: {
          icon: "$appLinkIcon",
          label: "Affiliate",
        },
        chatbot_widget: {
          icon: "$appMessageCircleIcon",
          label: "Chatbot",
        },
      },
      pageNodes: [
        {
          type: "blank_page",
          label: "Blank",
          icon: "$appFilePlusIcon",
          requiredSetup: true,
        },
        {
          type: "checkout_page",
          label: "Checkout",
          icon: "$appFilePlusIcon",
          requiredSetup: true,
        },
        // {
        //   type: "opt_in_page",
        //   label: "Opt In",
        //   icon: "$appFilePlusIcon",
        //   requiredSetup: true,
        // },
        {
          type: "sale_page",
          label: "Sales",
          icon: "$appFilePlusIcon",
          requiredSetup: true,
        },
      ],
      widgetNodes: [
        {
          type: "email_widget",
          label: "Email",
          icon: "$appMailIcon",
          requiredSetup: true,
        },
        {
          type: "delay_timer_widget",
          label: "Delay Timer",
          icon: "$appClockIcon",
          requiredSetup: true,
        },
        // {
        //   type: "split_test_widget",
        //   label: "Split Test",
        //   icon: "$appFileIcon",
        //   requiredSetup: true,
        // },
        // {
        //   type: "tagging_widget",
        //   label: "Tagging",
        //   icon: "$appTagIcon",
        //   requiredSetup: true,
        // },
        {
          type: "sms_widget",
          label: "SMS",
          icon: "$appSendIcon",
          requiredSetup: true,
        },
        // {
        //   type: "order_bump_widget",
        //   label: "Order Bump",
        //   icon: "$appPackageIcon",
        //   requiredSetup: true,
        // },
        // {
        //   type: "membership_widget",
        //   label: "Membership",
        //   icon: "$appUsersIcon",
        //   requiredSetup: true,
        // },
        // {
        //   type: "upsell_widget",
        //   label: "Upsell",
        //   icon: "$appPackageIcon",
        //   requiredSetup: true,
        // },
        // {
        //   type: "affiliate_widget",
        //   label: "Affiliate",
        //   icon: "$appLinkIcon",
        //   requiredSetup: true,
        // },
        // {
        //   type: "chatbot_widget",
        //   label: "Chatbot",
        //   icon: "$appMessageCircleIcon",
        //   requiredSetup: true,
        // },
      ],
      groupNodes: [
        {
          type: "sequence_group",
          label: "Sequence",
          icon: "$appZapIcon",
          requiredSetup: true,
        },
      ],
      plusMenu: false,
      settingDrawer: false,
      scene: {
        centerX: 200,
        centerY: 150,
        scale: 1,
        nodes: [
          {
            id: 2,
            x: 0,
            y: 200,
            type: "sale_page",
            label: "Sales Page",
            icon: "$appFilePlusIcon",
            requiredSetup: true,
            settings: null,
          },
          {
            id: "test1234",
            x: 200,
            y: 80,
            type: "checkout_page",
            label: "Checkout",
            icon: "$appFilePlusIcon",
            requiredSetup: true,
            settings: null,
          },
          {
            id: this.generateRandomUuid(),
            x: 400,
            y: 80,
            type: "email_widget",
            label: "Email",
            icon: "$appMailIcon",
            requiredSetup: true,
            settings: null,
          },
        ],
        links: [
          {
            id: 3,
            from: 2, // node id the link start
            to: "test1234", // node id the link end
          },
        ],
      },
      newNodeType: 0,
      newNodeLabel: "",
      nodeCategory: ["rule", "action", "script", "decision", "fork", "join"],
      componentRefs: {
        email_widget: "configure_email_component",
        delay_timer_widget: "configure_delay_timer_component",
        blank_page: "configure_blank_page_component",
        checkout_page: "configure_checkout_page_component",
        sale_page: "configure_sales_page_component",
      },
    };
  },
  computed: {
    computedNodes() {
      return this.scene.nodes;
    },
  },
  watch: {
    computedNodes: {
      deep: true,
      handler() {
        window.vBus.$emit("listenFunnelIsDirty");
      },
    },
  },
  async mounted() {
    this.campaign_id = this.$route.query.campaign_id;
    window.vBus.$on("eventGlobalSaveClicked", () => {
      this.submitFunnelForm();
    });
    await this.loadFunnel();
  },
  methods: {
    async loadFunnel() {
      await window.axios
        .post("/api/funnel/show", { campaign_id: this.campaign_id })
        .then((res) => {
          if (res.data.success) {
            if (res.data.funnel) {
              this.scene.nodes = res.data.funnel.funnel_nodes;
              this.scene.nodes.forEach((item) => {
                item.icon = this.refValues[item.type]['icon'];
                item.label = this.refValues[item.type]['label'];
                if(item.type === 'blank_page') {
                  item.label = item.settingable.page.name;
                }
              });
              this.scene.links = res.data.links;
            }
          }
        })
        .finally(() => (this.isDataLoading = false));
    },
    generateRandomUuid() {
      return (
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15)
      );
    },
    openDrawer() {
      this.settingDrawer = true;
    },
    openPlusMenu() {
      this.plusMenu = !this.plusMenu;
    },
    addNode(node) {
      console.log(node)
      this.scene.nodes.push({
        id: this.generateRandomUuid(),
        x: 120,
        y: -100,
        settings: null,
        ...node, 
      });
      this.submitFunnelForm()
    },
    nodeClick(id) {
      console.log("node click", id);
    },
    nodeDelete(id) {
      console.log("node delete", id);
    },
    linkBreak(id) {
      console.log("link break", id);
    },
    linkAdded(link) {
      console.log("new link added:", link);
    },
    canvasClick() {
      // console.log("canvas Click, event:", e);
    },
    async openConfigure(nodeId) {
      let nodeIndex = this.scene.nodes.findIndex((item) => item.id === nodeId);
      let node = this.scene.nodes[nodeIndex];
      if (node.settings === null) {
        this.$refs[this.componentRefs[node.type]].loadSettingStubs(nodeId);
      } else {
        this.$refs[this.componentRefs[node.type]].setCurrentNode(
          nodeId,
          node.settings
        );
      }
      this.showingSettings = node.type;
      this.settingDrawer = true;
    },

    nodeUpdateConfiguration(payload) {
      let nodeIndex = this.scene.nodes.findIndex(
        (item) => item.id === payload.nodeId
      );
      this.scene.nodes[nodeIndex].settings = payload.settings;
      this.scene.nodes[nodeIndex].label = payload.settings.page_name
    },

    async submitFunnelForm() {
      // todo: perform lock

      window.vBus.$emit('listenFunnelIsDirty')

      const updateResult = await new Promise((resolve, reject) => {
        window.axios
          .post("/api/funnel/store", {
            campaign_id: this.campaign_id,
            nodes: this.scene.nodes,
            links: this.scene.links,
          })
          .then((res) => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {
            window.vBus.$emit("finishedFunnelSaved");
          });
      })

      if (updateResult) {
        await this.loadFunnel()
      }
      
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
