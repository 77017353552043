<template>
  <div class="pa-4">
    <v-row>
      <v-col :cols="$vuetify.breakpoint.smAndUp ? '' : '8'">
        <div class="d-flex align-center">
          <v-btn
            class="mr-5 pa-1 form-button"
            href="/affiliates/Index"
            height="auto"
            min-width="auto"
          >
            <v-icon>
              mdi-arrow-left
            </v-icon>
          </v-btn>
          <h2>Project 1</h2>
        </div>
      </v-col>
      <v-col>
        <div class="d-flex align-center justify-end">
          <div>
            <v-btn href="/contacts/Create" class="form-button ml-3" depressed>
              <v-icon :left="$vuetify.breakpoint.mdAndUp"
                >mdi-cog-outline</v-icon
              >
              <div v-if="$vuetify.breakpoint.mdAndUp">
                Affiliates Settings
              </div>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col> <div class="text-h5">Earning Summary</div></v-col>
    </v-row>
    <v-container class="pa-0" fluid>
      <v-row class="campaign-card-row">
        <v-col
          v-for="card in cards"
          :key="card"
          :cols="12"
          :sm="4"
          :md="4"
          :lg="3"
          class="px-5"
        >
          <v-card class="form-card">
            <v-row no-gutters>
              <v-col md="2" cols="4" class="campaign-card-icon">
                <v-btn fab icon class="grey lighten-5 ml-2" width="40px">
                  <v-icon :color="card.color">{{ card.icon }}</v-icon>
                </v-btn>
              </v-col>
              <v-col md="10" cols="8">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-card-title class="text-h5 pb-0" v-text="card.text">
                    </v-card-title>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col>
                        <v-card-subtitle
                          class="font-weight-bold pt-0"
                          v-text="card.title"
                        >
                        </v-card-subtitle>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-row class="my-3">
      <v-col :cols="$vuetify.breakpoint.smAndUp ? '' : '8'">
        <div class="d-flex align-center">
          <div class="text-h5">Earning Summary</div>
        </div>
      </v-col>
      <v-col>
        <div class="d-flex align-center justify-end">
          <div>
            <v-btn
              href="/contacts/Create"
              class="blue form-button ml-3"
              depressed
              dark
            >
              <v-icon v-if="!$vuetify.breakpoint.mdAndUp">
                mdi-account-multiple-plus
              </v-icon>
              <div v-else>
                Add Referrals
              </div>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-card class="form-card">
      <div>
        <v-tabs class="px-4" v-model="list">
          <v-tab class="d-flex" v-for="tab in tabs" :key="tab">
            {{ tab.name }}
          </v-tab>
        </v-tabs>
      </div>
      <v-divider></v-divider>
      <div class="pa-4">
        <v-row
          no-gutters
          :class="$vuetify.breakpoint.smAndUp ? 'mb-5' : 'mb-2'"
        >
          <v-col
            :cols="$vuetify.breakpoint.smAndUp ? '10' : '12'"
            :class="$vuetify.breakpoint.smAndUp ? 'mr-2' : ''"
          >
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Search"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col :class="$vuetify.breakpoint.smAndUp ? '' : 'mt-3'">
            <v-select
              dense
              outlined
              label="Filters"
              hide-details="auto"
            ></v-select>
          </v-col>
        </v-row>
        <v-row :class="$vuetify.breakpoint.smAndUp ? 'ma-0' : ''">
          <v-col :cols="$vuetify.breakpoint.smAndUp ? '' : '12'">
            <div class="align-center">
              <v-checkbox
                class="mx-0 my-1"
                label="Showing 10 of 100 affiliates"
                hide-details="auto"
              >
              </v-checkbox>
            </div>
          </v-col>
          <v-col>
            <div
              v-if="$vuetify.breakpoint.smAndUp"
              class="d-flex align-center justify-end"
            >
              <div>Sort By:</div>
              <div class="w-50">
                <v-select
                  dense
                  outlined
                  class="ml-3 white"
                  label="Newest first"
                  hide-details="auto"
                ></v-select>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="customers"
          :search="search"
          :page.sync="page"
          show-select
          hide-default-footer
          @page-count="pageCount = $event"
        >
          <template v-slot:item.name="{ item }">
            <div class="font-weight-regular">{{ item.name }}</div>
            <div class="font-weight-regular email-style">
              {{ item.email }}
            </div>
          </template>
          <template v-slot:item.action="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="2"
                  fab
                  icon
                  color="white"
                  outlined
                  width="40px"
                  v-bind="attrs"
                  v-on="on"
                  @click="editItem(item)"
                >
                  <v-icon color="black">mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item link>Edit</v-list-item>
                <v-list-item link>Archive</v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-list dense>
                <v-list-item link>
                  <v-icon class="mr-2" color="red">
                    mdi-delete
                  </v-icon>
                  <div style="color:red;">Delete</div>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
  export default {
    data: () => ({
      list: null,
      page: 1,
      pageCount: 0,
      projectPerPage: 10,
      tabs: [{ name: "All" }, { name: "New" }],
      cards: [
        {
          title: "Lifetime",
          icon: "mdi-currency-usd",
          text: "RM 140,394.90",
          color: "green",
        },
        {
          title: "Last 365 days",
          icon: "mdi-currency-usd",
          text: "RM 32,394.90",
          color: "green",
        },
        {
          title: "Last 30 days",
          icon: "mdi-currency-usd",
          text: "RM 4,394.90",
          color: "green",
        },
        {
          title: "Last 7 days",
          icon: "mdi-currency-usd",
          text: "RM 1,394.90",
          color: "green",
        },
        {
          title: "Today",
          icon: "mdi-currency-usd",
          text: "RM 594.90",
          color: "green",
        },
        {
          title: "Yesterday",
          icon: "mdi-currency-usd",
          text: "RM 321.90",
          color: "green",
        },
        {
          title: "Unpaid Referrals",
          icon: "mdi-currency-usd",
          text: "RM 4,322.40",
          color: "gray",
        },
        {
          title: "Affiliates Program",
          icon: "mdi-share-variant",
          text: "100",
          color: "blue",
        },
      ],
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        { text: "Date Joined", value: "date_joined", width: "18%" },
        { text: "Earnings", value: "earning", width: "11%" },
        { text: "Paid Earnings", value: "paid_earning", width: "11%" },
        { text: "Pending", value: "pending", width: "11%" },
        { text: "Action", value: "action", sortable: false, width: "5%" },
      ],
      customers: [
        {
          name: "Barry Jackson",
          email: "barryjackson@gmail.com",
          date_joined: "01 January 2021 16:31",
          earning: "RM203.00",
          paid_earning: "RM100",
          pending: "RM100",
        },
        {
          name: "Barry Jackson",
          email: "barryjackson@gmail.com",
          date_joined: "01 January 2021 16:31",
          earning: "RM203.00",
          paid_earning: "RM100",
          pending: "RM100",
        },
      ],
    }),
    computed: {
      dateRangeText() {
        return this.datePick.join(" - ");
      },
    },
    methods: {
      // getColor(type) {
      //   if (type == "Archived") return "grey";
      //   else return "green";
      // },
    },
  };
</script>

<style scoped></style>
