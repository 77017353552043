<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.5 2.5L8.39167 16.6417L10.4833 10.4833L16.6417 8.39167L2.5 2.5Z" stroke="#0075FF" stroke-width="1.38889" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.8333 10.8335L15.8333 15.8335" stroke="#0075FF" stroke-width="1.38889" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "app-mouse-pointer-icon"
});
</script>

<style scoped>

</style>
