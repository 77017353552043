<template>
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 17.1668V8.8335" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 17.1668V3.8335" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5 17.1665V12.1665" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "app-bar-chart-icon"
});
</script>

<style scoped>

</style>
