//import * as types from './mutation-types'

// eslint-disable-next-line no-unused-vars
export const fetchAffiliates = ({ commit, dispatch, state }, filter) => {
    return new Promise((resolve, reject) => {
        window.axios.get('/api/affiliate' + filter).then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const fetchAffiliate = ({ commit, dispatch, state }, affiliate_id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('/api/affiliate/'+affiliate_id).then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
// eslint-disable-next-line no-unused-vars
export const fetchReferences = ({ commit, dispatch, state }, affiliate_id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('/api/affiliate/references').then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const postAffiliate = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('/api/affiliate/store', data).then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const updateAffiliate = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.put('/api/affiliate/'+data.id, data).then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const deleteAffiliate = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete(`/api/affiliate/${id}`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const updateAffiliateArchive = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('/api/affiliate/archive', data).then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
