<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
       width="256.000000pt" height="256.000000pt" viewBox="0 0 256.000000 256.000000"
       preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
       fill="#1773ea" stroke="none">
      <path d="M1153 2550 c-566 -64 -1007 -465 -1124 -1023 -30 -142 -30 -352 0
-494 68 -326 250 -606 513 -794 131 -93 349 -186 501 -213 l37 -7 0 446 0 445
-165 0 -165 0 0 185 0 185 165 0 165 0 0 193 c0 139 4 209 15 250 44 170 164
287 334 327 54 13 332 9 394 -6 l27 -6 0 -158 0 -157 -112 -5 c-127 -6 -171
-20 -210 -66 -35 -42 -48 -109 -48 -252 l0 -120 180 0 181 0 -6 -32 c-3 -18
-16 -100 -29 -183 l-22 -150 -152 -3 -152 -3 0 -445 0 -445 38 7 c151 27 369
120 500 213 263 188 445 468 513 794 30 142 30 352 0 494 -94 450 -401 804
-829 957 -157 56 -391 84 -549 66z"/>
    </g>
  </svg>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "app-facebook-icon"
});
</script>

<style scoped>

</style>
