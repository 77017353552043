<template>
    <div>
        <!-- when test and questions is readied -->
        <div v-if="!isLoading && !isParticipated">
            <div v-if="!isReady">
                <v-row justify="center" class="mt-5">
                    <v-col cols="8">
                        <v-container fluid class="main-container-bg pa-6">
                            <h1>Hello User!</h1>
                            <h2>Welcome to your Test Skill assessment for <!-- Test Title Here --></h2><br>

                            <h3>Before you start:</h3>
                            <div class="pa-2">
                                <ol>
                                    <li>Once you click the "Start The Test" button, you will not be able to pause or retake the test. Make sure that you will not be interrupted.</li>
                                    <li>If you exit your browser by accident, no worries. Use the invitation link to return to your assessment.</li>
                                    <li>All answer will be saved when time expires <b>except</b> code written for a programming task. You must explicitly save your code or build & run tests before the time runs out during programming tasks.</li>
                                    <li>For programming tasks, you may
                                        <ol type="a">
                                            <li>Use our in-browser IDE.</li>
                                            <li>Download or clone the project to your favorite IDE.</li>
                                        </ol>
                                    </li>
                                    <li>Remember to upload your completed solution before the time is up.</li>
                                    <li>Check the <a>help</a> section before starting the assessment or find it in the top-right corner of the page during the test.</li>
                                </ol>
                            </div><br>

                            <v-row>
                                <v-col>
                                    <div class="text-center">
                                        <h3 v-if="!isRejoinTest">Total test time: {{ String(Math.floor(totalDuration / 60)).padStart(2, '0') }} minutes</h3>
                                        <h3 v-else>Total time left: {{ timeLeft }}</h3>
                                    </div>
                                </v-col>
                                <!-- <v-col cols="6">
                                    <h3>Watch demo assessment</h3>
                                </v-col> -->
                            </v-row>
                            <div class="text-h6 mb-2 v-input__slot">
                                <v-checkbox v-model="checked">
                                    <template v-slot:label>
                                        <div>
                                            I have read and accept the
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <a
                                                            target="_blank"
                                                            href="#"
                                                            @click.stop
                                                            v-on="on"
                                                    >
                                                        terms of the assessment
                                                    </a>
                                                </template>
                                                Opens in new window
                                            </v-tooltip>
                                        </div>
                                    </template>
                                </v-checkbox>
                            </div>
                            <div class="text-h6 text-center mb-2">
                                <v-btn
                                        large
                                        class="primary"
                                        @click="startTest"
                                        :disabled="!checked"
                                >
                                   {{ !isRejoinTest ? 'Start Test' : 'Resume Test'}}
                                </v-btn>
                            </div>
                        </v-container>
                    </v-col>
                </v-row>
            </div>

            <!-- Being Test div -->

            <div v-else>
                <!-- Final step: Finished display -->
                <div v-if="questions.length + 1 === numAnswered">
                    <!-- finished -->
                    <v-row class="text-center mt-10">
                        <v-col>
                            <v-container>
                                <!-- saving request is on-going-->
                                <div v-if="isRequestSubmitting">
                                    <v-progress-circular
                                            :size="70"
                                            :width="7"
                                            color="blue darken-1"
                                            indeterminate
                                    ></v-progress-circular>

                                    <h2 class="pa-2">Hold on, submitting your answers</h2>
                                    <p>Please do not close or refresh this page while we submitting your answers.</p>
                                </div> <!--/end saving request is on-going -->

                                <div v-else-if="totalDuration !== 0">
                                    <v-img
                                            width="100"
                                            class="center"
                                            src="https://images.vexels.com/media/users/3/157931/isolated/preview/604a0cadf94914c7ee6c6e552e9b4487-curved-check-mark-circle-icon-by-vexels.png"
                                    ></v-img>

                                    <h2 class="pa-2">You have finished your test!</h2>
                                    <p>Thank you for participating! Now you can relax and <br> wait for feedback from the recruiter.</p>
                                </div>
                                <div v-else>
                                    <v-img
                                            width="100"
                                            class="center"
                                            src="https://cdn4.iconfinder.com/data/icons/time-line/512/wake_up-512.png"
                                    ></v-img>

                                    <h2 class="pa-2">Times up!</h2>
                                    <p>Thank you for participating! Your answer has been record, <br> please wait for feedback from the recruiter.</p>
                                </div>
                            </v-container>
                        </v-col>
                    </v-row>
                </div>

                <!--/end Final step: Finished display -->

                <div v-else>
                    <!-- Slide Start Here -->
                    <div v-if="questions[index].question_type_id == '4'">
                        <v-row>
                            <v-col>
                                <v-container>
                                    <v-row>
                                        <v-col md="6">
                                            <h3> Slide {{numAnswered}} / {{questions.length}} </h3>
                                        </v-col>
                                        <v-col md="6" style="text-align:right;">
                                            <h3> {{ timeLeft }} </h3>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-container fluid class="main-container-bg">
                                    <div class="pa-4">
                                        <div>
                                            <h2>
                                                <div v-if="questions[index].editor_type_id === 1">
                                                    <div v-html="questions[index].description" class="html-container"></div>
                                                </div>
                                                <div v-else>
                                                    <Editor
                                                            mode="viewer"
                                                            :render-config="renderConfig"
                                                            v-model="questions[index].description"
                                                    ></Editor>
                                                </div>
                                            </h2>
                                        </div>
                                    </div>
                                </v-container>
                                <v-btn
                                        @click="next"
                                        large
                                        class="float-right primary my-8"
                                        :loading="isSavingCurrentTestAnswer"
                                >
                                    {{ questions.length === numAnswered ? 'Finish' : 'Next' }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- Slide End Here -->
                    <!-- MCQ Start Here -->
                    <div v-else-if="questions[index].question_type_id == '5' || questions[index].question_type_id == '1'">
                        <v-row>
                            <v-col cols="7">
                                <v-container>
                                    <h3>Questions {{ numAnswered }}/{{ questions.length }}</h3>
                                </v-container>
                                <v-container fluid class="main-container-bg">
                                    <div class="pa-4">
                                        <div v-if="questions[index].editor_type_id === 1">
                                            <div v-html="questions[index].description" class="html-container"></div>
                                        </div>
                                        <div v-else>
                                            <Editor
                                                    mode="viewer"
                                                    :render-config="renderConfig"
                                                    v-model="questions[index].description"
                                            ></Editor>
                                        </div>
                                    </div>
                                </v-container>
                            </v-col>
                            <v-col cols="5">
                                <v-container fluid>
                                    <h3> {{ timeLeft }} </h3>
                                    <div v-if="questions[index].question_type_id == '1'">
                                        <div class="grey--text text-caption">* Multiple Answers </div>
                                        <div
                                                v-for="(answer, answerIndex) in questions[index].answers"
                                                :key="answerIndex"
                                                @click="selectMultipleAnswer(answer, answerIndex, index)"
                                        >
                                            <div>
                                                <v-row align="center">
                                                    <v-col cols="1">
                                                        <v-checkbox
                                                                :input-value="questions[index].selected_answers.includes(answer)"
                                                        >
                                                        </v-checkbox>
                                                    </v-col>
                                                    <v-col cols="10">
                                                        <div
                                                                class="pa-8 my-4 answer-card answer-hover"
                                                                :class="{ active: questions[index].selected_answers.includes(answer) }"
                                                                outlined
                                                                style="cursor:pointer;"
                                                        >
                                                            {{ answer.content }}
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="questions[index].question_type_id == '5'">
                                        <div class="grey--text text-caption">* Single Answer </div>
                                        <div
                                                v-for="(answer, answerIndex) in questions[index].answers"
                                                :key="answerIndex"
                                                @click="selectAnswer(answer, index)"
                                        >
                                            <div>
                                                <v-row align="center">
                                                    <v-col cols="1">
                                                        <v-radio-group :value="questions[index].selected_answers[0]">
                                                            <v-radio
                                                                    :value="answer"
                                                            >
                                                            </v-radio>
                                                        </v-radio-group>
                                                    </v-col>
                                                    <v-col cols="10">
                                                        <div
                                                                class="pa-8 my-4 answer-card answer-hover"
                                                                :class="[questions[index].selected_answers.includes(answer) ? 'active' : '']"
                                                                outlined
                                                                style="cursor:pointer;"
                                                        >
                                                            {{ answer.content }}
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                    </div>
                                    <v-btn
                                            @click="next"
                                            large
                                            class="float-right primary"
                                            :loading="isSavingCurrentTestAnswer"
                                            :disabled="!questions[index].selected_answers.length > 0"
                                    >
                                        {{ questions.length === numAnswered ? 'Finish' : 'Next' }}
                                    </v-btn>
                                </v-container>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- MCQ End Here -->
                    <!-- Essay Start Here -->
                    <div v-else-if="questions[index].question_type_id == '2'">
                        <v-row>
                            <v-col>
                                <v-container>
                                    <v-row>
                                        <v-col md="6">
                                            <h3> Essay {{numAnswered}} / {{questions.length}} </h3>
                                        </v-col>

                                        <v-col md="6" style="text-align:right;">
                                            <h3> {{ timeLeft }} </h3>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-container fluid class="main-container-bg">
                                    <div class="pa-4">
                                        <div>
                                            <h2>
                                                <div v-if="questions[index].editor_type_id === 1">
                                                    <div v-html="questions[index].description" class="html-container"></div>
                                                </div>
                                                <div v-else>
                                                    <Editor
                                                            mode="viewer"
                                                            :render-config="renderConfig"
                                                            v-model="questions[index].description"
                                                    ></Editor>
                                                </div>
                                            </h2>
                                        </div>
                                    </div>
                                </v-container>
                                <v-container fluid class="main-container">
                                    <div class="pa-4">
                                        <v-textarea
                                                outlined
                                                clearable
                                                rows="5"
                                                auto-grow
                                                v-model="questions[index].selected_answers[0]"
                                                label="Please write here"
                                        >
                                        </v-textarea>
                                    </div>
                                </v-container>
                                <v-btn
                                        @click="next"
                                        large
                                        class="float-right primary my-8"
                                        :disabled="typeof questions[index].selected_answers[0] === 'undefined' || (typeof questions[index].selected_answers[0] !== 'undefined' && !questions[index].selected_answers[0].length > 0)"
                                >
                                    {{ questions.length === numAnswered ? 'Finish' : 'Next' }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- Essay End Here -->
                  <!-- Programming Start here -->
                  <div v-else>
                    <v-row>
                      <v-col>
                        <v-container>
                          <v-row>
                            <v-col md="6">
                              <h3> Programming {{numAnswered}} / {{questions.length}} </h3>
                            </v-col>

                            <v-col md="6" style="text-align:right;">
                              <h3> {{ timeLeft }} </h3>
                            </v-col>
                          </v-row>
                        </v-container>
                        <v-container fluid class="main-container-bg">
                          <div class="pa-4">
                            <div>
                              <h2>
                                <div v-if="questions[index].editor_type_id === 1">
                                  <div v-html="questions[index].description" class="html-container"></div>
                                </div>
                                <div v-else>
                                  <Editor
                                      mode="viewer"
                                      :render-config="renderConfig"
                                      v-model="questions[index].description"
                                  ></Editor>
                                </div>
                              </h2>
                            </div>
                          </div>
                        </v-container>
                        <v-container fluid class="main-container">
                          <div class="pa-4">
                            <programming-component
                                v-model="questions[index].selected_answers[0]"
                                :programming-type="questions[index].programming_lang_type"
                                is-actual
                            />
                          </div>
                        </v-container>
                        <v-btn
                            @click="next"
                            large
                            class="float-right primary my-8"
                            :disabled="typeof questions[index].selected_answers[0] === 'undefined' || (typeof questions[index].selected_answers[0] !== 'undefined' && typeof questions[index].selected_answers[0] !== 'object')"
                        >
                          {{ questions.length === numAnswered ? 'Finish' : 'Next' }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                  <!-- Programming end here -->

                </div>
            </div>
        </div> <!--/end test -->


        <!-- Loading div -->
        <div v-if="isLoading">
            <v-row justify="center" class="mt-5">
                <v-col cols="5">
                    <div class="text-h6 text-center mb-2">
                        Readying up the test
                    </div>
                    <v-progress-linear
                            color="deep-purple accent-4"
                            indeterminate
                            rounded
                            height="6"
                    ></v-progress-linear>
                </v-col>
            </v-row>
        </div> <!--/end Loading div -->

        <!-- User already participated in this test -->
        <div v-if="isParticipated && !isLoading">
            <v-row justify="center" class="mt-5">
                <v-col cols="8">
                    <v-img
                            width="100"
                            class="center"
                            src="https://images.vexels.com/media/users/3/157931/isolated/preview/604a0cadf94914c7ee6c6e552e9b4487-curved-check-mark-circle-icon-by-vexels.png"
                    ></v-img>
                    <h2 class="text-center mb-2">
                        This test has been taken.
                    </h2>
                    <p class="text-center">Thank you for the time! <br> please wait for the feedback from our recruiter.</p>
                </v-col>
            </v-row>
        </div> <!--/end User already participated -->
    </div>

</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { Editor } from "vuetify-markdown-editor"
  import ProgrammingComponent from "./programming"

  export default {
    components: { Editor, ProgrammingComponent },
    data () {
      return {
        isLoading: false,
        isReady: false,
        isParticipated: false,
        isRejoinTest: false,
        isSavingCurrentTestAnswer: false,
        candidate: null,
        test: null,
        invitation: null,
        totalDuration: 0,
        questions: [],
        answered: false,
        index: 0,
        selectedIndex: null,
        numAnswered: 1,
        timer: null ,
        essayAnswer:[],
        checked: false,
        startTime: null,
        renderConfig: {
          // Mermaid config
          mermaid: {
            theme: "dark"
          }
        },
        requestingNode: []
        //will be added is_answered, time_remained
      }
    },

    mounted () {
      this.fetchCandidateAction()
    },

    computed:{
      ...mapGetters('candidate', [
        'getCandidate'
      ]),
      timeLeft () {
        return `${this.minutes}m ${this.seconds}s`
      },
      minutes () {
        return String(Math.floor(this.totalDuration / 60)).padStart(2, '0')
      },
      seconds () {
        return String(this.totalDuration % 60).padStart(2, '0')
      },
      isRequestSubmitting () {
        return this.requestingNode.length > 0
      }
    },

    methods: {
      ...mapActions('candidate', [
        'fetchCandidate',
        'saveCurrentTestAnswer'
      ]),
      async fetchCandidateAction () {
        this.isLoading = true
        try {
          let response = await this.fetchCandidate(this.$route.params.token)
          this.candidate = response.data.candidate
          this.test = response.data.test
          this.questions = response.data.test.questions
          this.invitation = response.data.invitation
          if (parseInt(response.data.time_remained) > 0) {
            // resume duration
            this.totalDuration = parseInt(response.data.time_remained)
            response.data.questions_answered.forEach(_answered => {
              if (_answered) {
                this.index++
                this.numAnswered++
              } else {
                return false
              }
            })
            this.isRejoinTest = true
          } else {
            // beginning duration
            this.totalDuration = this.questions.map(qust => parseInt(qust.duration)).reduce((acc, curr) => acc + curr) * 60
          }
          this.isParticipated = [3,5,6].includes(this.invitation.status_id) // Invitation::INVITATION_COMPLETED, INVITATION_HIRED, INVITATION_REJECTED
          this.isLoading = false
        } catch (e) {
          this.isLoading = false
        }
      },

      startTest(){
        this.isReady = true
        if(this.questions[this.index].question_type_id ==  '4'){
          clearInterval(this.timer)
          this.startTime = false
        }
        else{
          this.startTime = true
          this.timer = setInterval(this.decrementOrAlert, 1000)
        }
      },

      selectAnswer(answer, questionIndex){
        // this.selectedIndex = index
        this.questions[questionIndex].selected_answers.pop()
        this.questions[questionIndex].selected_answers.push(answer)
      },

      selectMultipleAnswer(answer, answerIndex, questionIndex){
        if (this.questions[questionIndex].selected_answers.includes(answer)) {
          // es6
          this.questions[questionIndex].selected_answers.splice(this.questions[questionIndex].selected_answers.findIndex(selAns => selAns === answer), 1)
        } else {
          this.questions[questionIndex].selected_answers.push(answer)
        }
      },

      // submitEssayAnswer(answer, questionIndex){
      //   this.questions[questionIndex].selected_answers.includes(answer)
      //   this.index++
      //   this.numAnswered++
      //   if(this.questions[this.index].question_type_id ==  '4' && this.startTime == true){
      //       this.startTime = false
      //       clearInterval(this.timer)
      //   }
      //   if(this.questions[this.index].question_type_id !=  '4' && this.startTime == false){
      //       this.startTime = true
      //       this.timer = setInterval(this.decrementOrAlert, 1000)
      //   }
      // },

      submitAnswer(){
        this.answered = true
      },

      async saveCurrentTestAnswerAction() {
        this.requestingNode.push('submitting')
        await this.saveCurrentTestAnswer({
          ...this.questions[this.index],
          candidate: this.candidate,
          time_remained_sec: this.totalDuration,
          is_finished: this.questions.length === this.numAnswered,
          invitation_id: this.invitation.id
        })
        this.requestingNode.pop()
      },

      next(){
        // is not finish
        this.saveCurrentTestAnswerAction()
        this.index++
        this.numAnswered++
        this.selectedIndex = null

        if (this.questions.length + 1 === this.numAnswered) {
          this.startTime = false
          clearInterval(this.timer)

        } else {
          if (this.questions[this.index].question_type_id == '4' && this.startTime == true) {
            this.startTime = false
            clearInterval(this.timer)
          }
          if (this.questions[this.index].question_type_id != '4' && this.startTime == false) {
            this.startTime = true
            this.timer = setInterval(this.decrementOrAlert, 1000)
          }
        }
      },

      decrementOrAlert () {
        if (this.totalDuration > 0) {
          this.totalDuration--
          return
        }
        this.numAnswered = this.questions.length + 1
        clearInterval(this.timer)
        alert("Time is up!")
      }
    }
  }
</script>

<style scoped>
    .active{
        background-color:lightblue;
        color:black;
    }

    .highlight{
        background-color: black;
        color:black;
    }

    .answer-card{
        border: 1px solid grey;
        border-radius:5px;
    }

    .answer-hover:hover{
        box-shadow: 5px 5px 15px grey;
    }
    .display-control{
        display:none;
    }

    .v-input__slot {
        align-items: center;
        justify-content: center;
    }

    .center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }
</style>
