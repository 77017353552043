<template>
  <v-app style="">
    <!-- Top App Bar -->
    <v-app-bar app clipped-left color="white" dense>
      <img src="/assets/app/img/logo.png" alt="fusion-logo" />
      <v-divider vertical  class="mx-5"></v-divider>
      <v-btn text>
        <v-icon>$appSearchIcon</v-icon>
        <div class="ml-2">Search to Funnels, Campaigns and more...</div>
      </v-btn>

      <v-spacer></v-spacer>
      <v-badge
          overlap
          bordered
          color="red"
          content="9"
          class="mx-2"
      >
        <v-btn text small>
          <v-icon>$appLifeBuoyIcon</v-icon>
          <div class="ml-2 text-body-1">Support</div>
        </v-btn>
      </v-badge>
      <v-badge
          overlap
          bordered
          color="red"
          content="9"
          class="mx-2"
      >
        <v-btn icon small>
          <v-icon>$appBellIcon</v-icon>
        </v-btn>
      </v-badge>

      <v-divider vertical class="mx-2"></v-divider>
      <!-- Account dropdown section-->
      <v-menu offset-y left>
        <template v-slot:activator="{on, attrs}">
          <v-btn
              text
              v-bind="attrs"
              v-on="on"
          >
            <v-avatar color="grey" size="35">TN</v-avatar>
            <div class="mx-3">Tester Name</div>
            <v-icon>
              $appChevronDownIcon
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-sign-out</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sign Out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!--/end Top App Bar -->

    <v-main class="main-app-bg">
      <!-- Provides the application the proper gutter -->
      <v-container fluid class="">
        <router-view></router-view>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  data () {
    return {
      isLoading: false,
    }
  },
  methods: {
    ...mapActions('auth', [
      'logout'
    ]),
    proceedLogout () {
      this.isLoading = true
      this.logout()
          .then((res) => {
            if (res.data.success) {
              this.$router.push({name: 'login'})
            }
            this.isLoading = false
          })
          .catch(() => {
            window.toastr['error']('There was an error. Please try again later.')
            this.isLoading = false
          })
    }
  }
}
</script>

<style scoped>

</style>
