<template>
  <v-row>
    <v-col md="4" lg="4" sm="12" class="mx-auto register-main">
      <stepper-dot :current-step="currentStep" class="mb-9"></stepper-dot>
      <div class="text-h5 text-center mb-9">
        Create your account
      </div>
      <v-form ref="registrationForm" v-model="valid" lazy-validation class="mt-5" @submit.prevent="submitRegistration">
        <v-text-field
            dense
            outlined
            v-model="formData.first_name"
            :rules="formValidations.first_name"
            hide-details="auto"
            class="grey--text text--lighten-1 my-3"
            placeholder="First Name"
        />

        <v-text-field
            dense
            outlined
            v-model="formData.last_name"
            :rules="formValidations.last_name"
            hide-details="auto"
            class="grey--text text--lighten-1 my-3"
            placeholder="Last Name"
        />

        <v-text-field
            dense
            outlined
            v-model="formData.email"
            :rules="formValidations.email"
            hide-details="auto"
            class="grey--text text--lighten-1 my-3"
            placeholder="Email"
        />

        <v-text-field
            v-model="formData.password"
            :rules="formValidations.password"
            label="Password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            class="my-3"
            hide-details="auto"
            outlined
            dense
            required
        />

        <div class="text-caption text-center my-7 grey--text darken-3">
          By creating an account, I agree to the Terms of Service and <br>
          have read and understood the Privacy Policy
        </div>

        <v-row>
          <v-col>
            <v-btn
                block
                class="theme-btn-color text-transform-unset"
                :loading="isLoading"
                type="submit"
            >
              Sign Up
            </v-btn>
          </v-col>
        </v-row>

        <div class="my-7 text-caption text-center grey--text darken-3">
          Already have an account? <router-link :to="{name: 'login'}">Sign In</router-link>
        </div>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import StepperDot from '@/components/StepperDot'
import { mapActions } from 'vuex'
export default {
  name: 'Register',
  components: {
    StepperDot
  },
  data() {
    return {
      valid: true,
      isLoading: false,
      currentStep: 1,
      showPassword: false,
      formData: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
      },
      formValidations: {
        first_name: [
          v => !!v || 'First Name is required',
        ],
        last_name: [
          v => !!v || 'Last Name is required',
        ],
        email: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        password: [
          v => !!v || 'Password is required',
          v => ( v && v.length >= 8 ) || "Password required at least 8 characters",
        ],
      }
    }
  },
  watch: {
    formData: {
      deep: true,
      handler () {
        this.$refs.registrationForm.validate()
      }
    }
  },
  methods : {
    ...mapActions('auth', [
      'register'
    ]),
    submitRegistration () {
      this.$refs.registrationForm.validate()

      if (!this.valid) {
        return true
      }

      this.isLoading = true
      this.register(this.formData)
          .then((res) => {
            this.isLoading = false
            if (res.data.success) {
              this.$router.push({name : 'register.plan'})
            } else {
              window.toastr['error']('Failed to create account')
            }
          })
          .catch((err) => {
            if (typeof err.response != 'undefined' && err.response.status === 422) {
              // eslint-disable-next-line no-unused-vars
              Object.keys(this.errors).forEach((key,index) => {
                if (typeof err.response.data[key] !== 'undefined') {
                  window.toastr['error'](err.response.data[key])
                }
              });
            } else if(err.response.data.message) {
              window.toastr['error'](err.response.data.message)
            }
            this.isLoading = false
          })

    }
  }
}
</script>

<style scoped>

</style>
