<template>
  <div>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    //
  }),
};
</script>

<style scoped>
  .header {
    padding:20px 30px;
    background-color: white;
    border: none !important;
    outline: none;
  }
</style>
