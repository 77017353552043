<template>
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.6667 5.5L7.50001 14.6667L3.33334 10.5" stroke="#4BCE6E" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "app-check-icon"
});
</script>

<style scoped>

</style>
