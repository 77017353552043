<template>
  <div class="pa-4">
    <v-row>
      <v-col>
        <h2>Products</h2>
      </v-col>
      <v-col>
        <div
          :class="
            $vuetify.breakpoint.mdAndUp
              ? 'd-flex align-center justify-end'
              : 'd-flex align-center'
          "
        >
          <div>
            <v-btn href="#" text class="form-button mr-3">
              <v-icon v-if="!$vuetify.breakpoint.mdAndUp">
                mdi-home-export-outline
              </v-icon>
              <div v-else>
                Export
              </div>
            </v-btn>
          </div>
          <div>
            <v-btn href="#" text class="form-button"
              ><v-icon v-if="!$vuetify.breakpoint.mdAndUp">
                mdi-home-import-outline
              </v-icon>
              <div v-else>Import Products</div>
            </v-btn>
          </div>
          <div>
            <v-btn
              href="/products/Create"
              class="ml-3"
              depressed
              color="primary"
              dark
              ><v-icon v-if="!$vuetify.breakpoint.mdAndUp">
                mdi-plus
              </v-icon>
              <div v-else>
                Add Product
              </div>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-container class="pa-0" fluid>
      <v-row class="campaign-card-row">
        <v-col
          v-for="card in cards"
          :key="card"
          :cols="12"
          :sm="4"
          :md="4"
          :lg="3"
          class="px-5"
        >
          <v-card class="form-card">
            <v-row no-gutters>
              <v-col md="2" cols="4" class="campaign-card-icon">
                <v-btn fab icon class="blue lighten-5 ml-2" width="40px">
                  <v-icon color="blue">{{ card.icon }}</v-icon>
                </v-btn>
              </v-col>
              <v-col md="10" cols="8">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-card-title class="text-h5 pb-0" v-text="card.text">
                    </v-card-title>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col>
                        <v-card-subtitle
                          class="font-weight-bold pt-0"
                          v-text="card.title"
                        >
                        </v-card-subtitle>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-card class="mt-10 form-card">
      <div>
        <v-tabs class="px-4" v-model="list">
          <v-tab class="d-flex" @click="loadProducts()">
            All
          </v-tab>
          <v-tab
            class="d-flex"
            v-for="tab in getProductTypes"
            :key="tab"
            @click="loadProducts(tab.id)"
          >
            {{ tab.name }}
          </v-tab>
        </v-tabs>
      </div>
      <v-divider></v-divider>
      <div class="pa-4">
        <v-row no-gutters class="mb-5">
          <v-col :cols="$vuetify.breakpoint.smAndUp ? '10' : '12'" class="mr-2">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Search"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            :cols="$vuetify.breakpoint.smAndUp ? '' : '12'"
            :class="$vuetify.breakpoint.smAndUp ? '' : 'mt-3'"
          >
            <v-select
              dense
              outlined
              label="Filters"
              hide-details="auto"
            ></v-select>
          </v-col>
        </v-row>
        <v-row :class="$vuetify.breakpoint.smAndUp ? 'ma-0' : 'pa-0'">
          <v-col :cols="$vuetify.breakpoint.smAndUp ? '' : '12'">
            <div class="align-center">
              <v-checkbox
                class="mx-0 my-1"
                label="Showing 10 of 100 products"
                hide-details="auto"
              >
              </v-checkbox>
            </div>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.smAndUp">
            <div class="d-flex align-center justify-end">
              <div>Sort By:</div>
              <div class="w-50">
                <v-select
                  dense
                  outlined
                  class="ml-3 white"
                  label="Date Modified"
                  hide-details="auto"
                ></v-select>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="products"
          :search="search"
          :page.sync="page"
          :loading="isLoading"
          show-select
          hide-default-footer
          @page-count="pageCount = $event"
        >
          <template v-slot:item.action="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="2"
                  fab
                  icon
                  color="white"
                  outlined
                  width="40px"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="black">mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  link
                  :to="{
                    name: 'products.create',
                    query: { product_id: item.id },
                  }"
                  >Edit</v-list-item
                >
                <v-list-item link @click="archiveProduct(item.id)"
                  >Archive</v-list-item
                >
              </v-list>
              <v-divider></v-divider>
              <v-list dense>
                <v-list-item link @click="removeProduct(item.id)">
                  <v-icon class="mr-2" color="red">
                    mdi-delete
                  </v-icon>
                  <div style="color:red;">Delete</div>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:item.product_type="{ item }">
            <v-chip :color="getColor(item.product_type)" dark>
              {{ item.product_type }}
            </v-chip>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";

  export default {
    data: () => ({
      list: null,
      isLoading: false,
      page: 1,
      pageCount: 0,
      productPerPage: 10,
      tab_type_id: null,
      cards: [
        { name: 'all_count', title: "Total", icon: "mdi-arrow-left", text: "12" },
        { name: 'digital_count', title: "Digital", icon: "mdi-thumb-down", text: "8" },
        { name: 'physical_count', title: "Physical", icon: "mdi-thumb-up", text: "4" },
        { name: 'archived_count', title: "Archived", icon: "mdi-minus-circle", text: "2" },
      ],
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        { text: "Type", value: "product_type", width: "15%" },
        { text: "Date Modified", value: "updated_at", width: "20%" },
        { text: "Inventory", value: "quantity", width: "13%" },
        { text: "Atr 2", value: "status", width: "8%" },
        { text: "Action", value: "action", sortable: false, width: "5%" },
      ],
      products: [],
    }),
    computed: {
      ...mapGetters("product", ["getProductTypes"]),
      dateRangeText() {
        return this.datePick.join(" - ");
      },
    },
    mounted() {
      this.loadProducts();
    },
    methods: {
      ...mapActions("product", [
        "fetchProducts",
        "updateProductArchive",
        "deleteProduct",
      ]),
      loadProducts(type_id = null) {
        let filterObj = {};
        let filter = "";
        this.tab_type_id = type_id;
        if (this.tab_type_id) {
          filterObj.product_type_id = this.tab_type_id;
          filter = "?" + new URLSearchParams(filterObj).toString();
        }
        this.isLoading = true;
        this.fetchProducts(filter).then((res) => {
          this.isLoading = false;
          this.products = res.data.products;
          this.cards.forEach((obj, key) => {
            this.cards[key].text = res.data.analytics[obj.name]
          });
        });
      },
      getColor(type) {
        if (type == "Archived") return "grey";
        else return "green";
      },
      archiveProduct(id) {
        this.isLoading = true;

        this.updateProductArchive({ id: id })
          .then((res) => {
            if (res.data.success) {
              this.isLoading = false;
              window.toastr["success"]("Product archived successfully.");
              this.loadProducts(this.tab_type_id);
            } else {
              window.toastr["error"]("Failed to archived successfully.");
            }
          })
          .catch((err) => {
            console.log(err);
            window.toastr["error"]("Failed to archived successfully.");
            this.isLoading = false;
          });
      },
      removeProduct(id) {
        this.$swal({
          title: "Delete this product?",
          text: "Are you sure? You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Yes, Delete it!",
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.deleteProduct(id)
              .then((res) => {
                if (res.data.success) {
                  this.isLoading = false;
                  window.toastr["success"]("Product deleted successfully.");
                  this.loadProducts(this.tab_type_id);
                } else {
                  window.toastr["error"]("Failed to delete product.");
                }
              })
              .catch((err) => {
                console.log(err);
                window.toastr["error"]("Failed to archived successfully.");
                this.isLoading = false;
              });
          }
        });
      },
    },
  };
</script>

<style scoped></style>
