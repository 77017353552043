<template>
  <v-app style="">
    <!-- Top App Bar -->
    <v-app-bar
      app
      clipped-left
      color="white"
      dense
      :hide-on-scroll="!$vuetify.breakpoint.mdAndUp"
    >
      <v-app-bar-nav-icon
        v-if="!$vuetify.breakpoint.mdAndUp"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <img
        :src="
          $vuetify.breakpoint.smAndUp ? '/img/logo.png' : '/img/logo-small.png'
        "
        alt="fusion-logo"
      />
      <v-divider vertical class="mx-5"></v-divider>
      <div v-if="$vuetify.breakpoint.mdAndUp" style="width:100%;">
        <v-autocomplete
          class="mt-6"
          clearable
          solo
          flat
          dense
          hide-no-data
          placeholder="Search to Funnels, Campaigns and more option"
          prepend-inner-icon="mdi-magnify"
          append-icon="none"
          return-object
        ></v-autocomplete>
      </div>

      <v-spacer></v-spacer>
      <v-badge overlap bordered color="red" content="9" class="mx-2">
        <v-btn text small>
          <v-icon>$appLifeBuoyIcon</v-icon>
          <div v-if="$vuetify.breakpoint.mdAndUp" class="ml-2 text-body-1">
            Support
          </div>
        </v-btn>
      </v-badge>
      <v-badge overlap bordered color="red" content="9" class="mx-2">
        <v-btn icon small>
          <v-icon>$appBellIcon</v-icon>
        </v-btn>
      </v-badge>

      <v-divider vertical class="mx-2"></v-divider>
      <!-- Account dropdown section-->
      <v-menu offset-y left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            <v-avatar color="grey" size="35">TN</v-avatar>
            <div v-if="$vuetify.breakpoint.smAndUp" class="mx-3">
              {{ getUser.first_name }}
            </div>
            <v-icon>
              $appChevronDownIcon
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item :loading="isLoading" @click="proceedLogout">
            <v-list-item-icon>
              <v-icon>mdi-sign-out</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sign Out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <template v-if="!$vuetify.breakpoint.mdAndUp" v-slot:extension>
        <v-autocomplete
          class="mt-6"
          clearable
          solo
          flat
          dense
          allow-overflow
          hide-no-data
          placeholder="Search to Funnels, Campaigns and more option"
          prepend-inner-icon="mdi-magnify"
          append-icon="none"
          return-object
        ></v-autocomplete>
      </template>
    </v-app-bar>
    <!--/end Top App Bar -->

    <!-- Left bar -->
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      :permanent="$vuetify.breakpoint.mdAndUp"
      color="#fafafa"
    >
      <v-list flat>
        <v-list-item-group v-model="group">
          <template v-for="(item, index) in menu">
            <!-- menu link -->
            <v-list-item
              :key="index + '_menu'"
              v-if="item.title !== 'v-divider'"
              link
              :to="item.route"
              exact
              class="nav-theme-menu"
              :class="{
                'nav-theme-title-sub-level': item.isSub,
                'mb-0': item.hasChildren,
              }"
              color="primary"
              active-class="nav-theme-active"
            >
              <v-list-item-icon>
                <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="nav-theme-title">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- divider -->
            <v-divider
              v-else
              :key="index + '_menu'"
              class="nav-menu-divider my-2"
            ></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!--/end Left bar -->

    <v-main class="main-app-bg">
      <!-- Provides the application the proper gutter -->
      <v-container fluid class="">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";

  export default {
    data() {
      return {
        drawer: false,
        group: null,
        menuIndex: 1,
        isLoading: false,
        menu: [
          {
            title: "Campaign",
            icon: "$appHomeIcon",
            route: { name: "dashboard.index" },
          },
          {
            title: "Products",
            icon: "$appPackageIcon",
            route: { name: "products.index" },
          },
          {
            title: "Contacts",
            icon: "$appUsersIcon",
            //route: { name: "contacts.index" },
            hasChildren: true,
          },
          {
            title: "Customers",
            icon: null,
            route: { name: "contacts.index" },
            isSub: true,
          },
          {
            title: "Affiliates",
            icon: null,
            route: { name: "job.index" },
            isSub: true,
          },
          // {
          //   title: "Membership",
          //   icon: null,
          //   route: { name: "job.index" },
          //   isSub: true,
          // },
          { title: "v-divider", icon: null, route: null },
          {
            title: "Affiliates",
            icon: "$appShareIcon",
            route: { name: "affiliates.index" },
            hasChildren: true,
          },
          {
            title: "Affiliates",
            icon: null,
            route: { name: "affiliates.index" },
            isSub: true,
          },
          {
            title: "Groups",
            icon: null,
            route: { name: "job.index" },
            isSub: true,
          },
          // {
          //   title: "Membership",
          //   icon: "$appUsersIcon",
          //   route: { name: "membership.index" },
          // },
          // {
          //   title: "Integrations",
          //   icon: "$appZapIcon",
          //   route: { name: "discover.index" },
          // },
          // {
          //   title: "Domains",
          //   icon: "$appLinkIcon",
          //   route: { name: "discover.index" },
          // },
          // {
          //   title: "Billing",
          //   icon: "$appCreditCardIcon",
          //   route: { name: "discover.index" },
          // },
          // { title: "v-divider", icon: null, route: null },
          // {
          //   title: "Team Members",
          //   icon: "$appUsersIcon",
          //   route: { name: "discover.index" },
          // },
          {
            title: "Settings",
            icon: "$appSettingIcon",
            route: { name: "dashboard.setting" },
          },
        ],
      };
    },
    computed: {
      ...mapGetters("auth", ["getUser"]),
    },
    methods: {
      ...mapActions("auth", ["logout"]),
      proceedLogout() {
        this.isLoading = true;
        this.logout()
          .then((res) => {
            if (res.data.success) {
              this.$router.push({ name: "login" });
              window.toastr["success"]("Logout successfully");
            }
            this.isLoading = false;
          })
          .catch(() => {
            window.toastr["error"](
              "There was an error. Please try again later."
            );
            this.isLoading = false;
          });
      },
    },
  };
</script>

<style scoped></style>
