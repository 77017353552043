<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.99996 1.6665L1.66663 5.83317L9.99996 9.99984L18.3333 5.83317L9.99996 1.6665Z" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.66663 14.1665L9.99996 18.3332L18.3333 14.1665" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.66663 10L9.99996 14.1667L18.3333 10" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "app-layer-icon"
});
</script>

<style scoped>

</style>
