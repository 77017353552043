<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5002 3.33337H2.50016C1.57969 3.33337 0.833496 4.07957 0.833496 5.00004V15C0.833496 15.9205 1.57969 16.6667 2.50016 16.6667H17.5002C18.4206 16.6667 19.1668 15.9205 19.1668 15V5.00004C19.1668 4.07957 18.4206 3.33337 17.5002 3.33337Z" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M0.833496 8.33337H19.1668" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "app-credit-card-icon"
});
</script>

<style scoped>

</style>
