<template>
  <div>
    <v-container>
      <v-card>
        <v-card-text>
          <map-chart
            :countryData="analytics.geo_chart"
            highColor="#ff0000"
            lowColor="#aaaaaa"
            countryStrokeColor="#909090"
            defaultCountryFillColor="#dadada"
            />
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import MapChart from 'vue-map-chart'

export default {
  name: 'FunnelAnalytic',
  components: {
    'map-chart': MapChart,
  },
  data () {
    return {
      isDataLoading: false,
      campaign_id: null,
      analytics: {
        geo_chart: {'US': 4, 'CA': 7, 'GB': 8, 'IE': 14, 'ES': 21}
      }
    }
  },
  async mounted() {
    this.campaign_id = this.$route.query.campaign_id
    await this.loadAnalytic()
  },
  methods: {
     async loadAnalytic() {
      await window.axios
        .post("/api/funnel/analytic/show", { campaign_id: this.campaign_id })
        .then((res) => {
          if (res.data.success) {
            if (res.data.analytic) {
              
              this.analytics.geo_chart = res.data.analytic.geo_chart
            }
          }
        })
        .finally(() => (this.isDataLoading = false));
    },
  }
}
</script>