<template>
  <v-app>
    <!-- Top App Bar -->
    <v-app-bar app clipped-left color="white" dense>
      <v-btn text small class="funnel-home-button" :to="{name: 'dashboard.index'}">
        <v-icon>$appHomeIcon</v-icon>
      </v-btn>
      <v-divider vertical  class="mx-5"></v-divider>
      <div>Campaign:</div>
      <div class="funnel-campaign-title ">Marketing Book</div>

      <v-tabs>
        <v-tab class="text-transform-unset" :to="{name: 'funnel.builder', query: {campaign_id: $route.query.campaign_id}}">
          <v-icon small class="mr-2">$appFilterIcon</v-icon>
          Funnel Builder
        </v-tab>
        <v-tab class="text-transform-unset" :to="{name: 'funnel.analytic', query: {campaign_id: $route.query.campaign_id}}">
          <v-icon small class="mr-2">$appBarChartIcon</v-icon>
          Analytics
        </v-tab>
      </v-tabs>
      <v-spacer></v-spacer>

      <v-btn text @click="triggerGlobalSave" :loading="isSaveLoading">
        <v-icon class="mr-2" v-if="!isDirty">$appCheckIcon</v-icon>
        <v-icon class="mr-2" v-else small>save</v-icon>
        <span v-if="!isDirty">Saved</span>
        <span v-else>Save</span>
      </v-btn>
      <v-btn text>
        <v-icon small class="mr-2">$appHelpCircleIcon</v-icon>
        Help
      </v-btn>
      <v-btn text>
        <v-icon small class="mr-2">$appSettingIcon</v-icon>
        Settings
      </v-btn>

    </v-app-bar>
    <!--/end Top App Bar -->

    <v-main class="main-app-bg">
      <!-- Provides the application the proper gutter -->
      <v-container fluid class="pa-0">
        <router-view></router-view>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>
export default {
  name: "LayoutFunnel",
  data () {
    return {
      isDirty: false,
      isSaveLoading: false,
    }
  },
  mounted () {
    window.vBus.$on('listenFunnelIsDirty', () => { this.isDirty = true })
    window.vBus.$on('finishedFunnelSaved', () => {
      this.isDirty = false
      this.isSaveLoading = false
    })
    window.vBus.$on('triggerGlobalSaveButton', () => {
      this.triggerGlobalSave()
    })
  },
  methods: {
    triggerGlobalSave () {
      this.isSaveLoading = true
      window.vBus.$emit('eventGlobalSaveClicked')
    }
  }
}
</script>

<style lang="scss">
.funnel-campaign-title {
  font-weight: bold;
  white-space: nowrap;
  margin-left: 0.5rem;
  margin-right: 1rem;
}

.funnel-home-button {
  color: black;
  stroke: black;

  svg {
    path {
      stroke: black;
    }
  }
}
</style>
