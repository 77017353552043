//import * as types from './mutation-types'
// eslint-disable-next-line no-unused-vars
export const fetchProducts = ({ commit, dispatch, state }, filter) => {
    return new Promise((resolve, reject) => {
        window.axios.get('/api/product'+filter).then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const fetchProduct = ({ commit, dispatch, state }, product_id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('/api/product/'+product_id).then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const postProduct = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('/api/product/store', data).then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const updateProduct = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('/api/product/update/', data).then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const updateProductStatus = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('/api/product/status/update', data).then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const deleteProduct = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete(`/api/product/${id}`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const updateProductArchive = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('/api/product/archive', data).then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
