<template>
    <v-container>
      <div class="text-h5 my-12 text-center">Sign in</div>
      <v-form ref="loginForm" lazy-validation v-model="valid" class="mt-5" @submit.prevent="submitLogin">
        <div class="d-flex-flex-column">
          <v-text-field
              v-model="formData.email"
              :rules="formValidations.emailRules"
              label="Email"
              outlined
              dense
              required
          ></v-text-field>
          <v-text-field
              v-model="formData.password"
              :rules="formValidations.passwordRules"
              label="Password"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              outlined
              dense
              required
          ></v-text-field>

        <v-btn type="submit"
               block class="theme-btn"
               :loading="isLoading">
          Sign In
        </v-btn>
        </div>
      </v-form>

      <div class="my-10 text-center">OR</div>

      <!-- Google Login -->
      <v-btn class="white w-100 mb-4">
        <v-icon left class="absolute-left">
          $appGoogleIcon
        </v-icon>
        Sign in with Google
      </v-btn>

      <!-- Facebook Login -->
      <v-btn class="white w-100 mb-6">
        <v-icon left class="absolute-left">
          $appFacebookIcon
        </v-icon>
        Sign in with Facebook
      </v-btn>

      <div class="text-center">
        New? <router-link :to="{name: 'register.index'}">Sign up</router-link> a new account
      </div>
<!--        <v-row>-->
<!--            <v-col class="d-flex flex-column">-->
<!--                <h2 class="text-center">Create your account</h2>-->
<!--                <input type="text" placeholder="First Name" />-->
<!--                <input type="text" placeholder="Last Name" />-->
<!--                <input type="email" placeholder="Email" />-->
<!--                <input type="password" placeholder="Password" />-->

<!--                <p class="text-caption">-->
<!--                  By creating an account, I agree to the Term of Service and have read and understood the Privacy Policy-->
<!--                </p>-->

<!--                <button>Sign up</button>-->

<!--                <p class="text-caption">-->
<!--                  Already have an account? Sign In-->
<!--                </p>-->
<!--            </v-col>-->
<!--        </v-row>-->
    </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LoginMain',
  data () {
    return {
      isLoading: false,
      valid: true,
      showPassword: false,
      formData: {
        email: null,
        password: null,
      },
      formValidations: {
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        passwordRules: [
          v => !!v || 'Password is required'
        ]
      }

    }
  },
  watch: {
    formData: {
      deep: true,
      handler () {
        this.$refs.loginForm.validate()
      }
    }
  },
  methods: {
    ...mapActions('auth', [
      'login'
    ]),
    submitLogin () {
      this.$refs.loginForm.validate()

      if (!this.valid) {
        return true
      }

      this.isLoading = true
      this.login(this.formData)
          .then((res) => {

            // check is it management level
            // if (res.data.results.user.is_management) {
            //   // route user to admin (v1) site
            //   window.location.href = '/v1'
            //   return;
            // }

            if (res.data.token) {
              this.$router.push({name : 'dashboard.index'})
              window.toastr['success']('Login successfully')

            } else {
              window.toastr['error']('Failed to Login')
            }
            this.isLoading = false

          })
          .catch((err) => {
            if (err.response.data.error === 'invalid_credentials') {
              window.toastr['error']('Invalid Credentials')
            } else if(err.response.data.error === 'not_verified') {
              window.toastr['error']('Account not verified!')
            } else {
              window.toastr['error']('There was an error. Please try again later.')
            }
            this.isLoading = false
          })

    }
  }
}
</script>

<style lang="scss" scoped>
    input {
      background: white;
      padding: 10px;
      border:1px solid #E5E5E5;
      border-radius: 4px;
      display:block;
      margin: 10px 0;
    }

</style>
