<template>
  <div class="pa-4 campaign-setting">
    <v-row>
      <v-col cols="12">
        <v-form ref="campaignForm" v-model="valid">
          <v-row>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '2' : ''"> </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '8' : '12'">
              <v-container class="pa-0" fluid>
                <div class="text--primary text-h5">Settings</div>
                <!-- Timezones -->
                <v-row class="mt-4">
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
                    <div class="text--primary font-weight-black">
                      Timezones
                    </div>
                    <p class="mt-3 mb-0 campaign-font-light">
                      For delay timer calculation and more.
                    </p>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '8' : ''">
                    <v-card class="form-card mb-3">
                      <v-card-text>
                        <div class="text--primary">
                          Timezones
                        </div>
                        <v-autocomplete
                          v-model="formData.timezone_id"
                          :items="getTimezones"
                          item-text="name"
                          item-value="id"
                          outlined
                          dense
                          flat
                          hide-details="auto"
                        ></v-autocomplete>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-divider class="my-5"></v-divider>
                <!-- Payments Providers -->
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
                    <div class="text--primary font-weight-black">
                      Payments Providers
                    </div>
                    <p class="mt-3 mb-0 campaign-font-light">
                      Add third party payments.
                    </p>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '8' : '12'">
                    <v-card class="form-card mb-3">
                      <v-card-text>
                        <v-row>
                          <v-col>
                            <div class="d-flex align-center">
                              <div class="text--primary">
                                Stripe
                              </div>
                            </div>
                          </v-col>
                          <v-col>
                            <div class="d-flex align-center justify-end">
                              <a>
                                Manage
                              </a>
                            </div>
                          </v-col>
                        </v-row>
                        <p class="mt-3 mb-0 campaign-font-light">
                          Accept payments
                        </p>
                      </v-card-text>
                    </v-card>
                    <v-card class="form-card mb-3">
                      <v-card-text>
                        <v-row>
                          <v-col>
                            <div class="d-flex align-center">
                              <div class="text--primary">
                                Add new payment methods
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                        <p class="mt-3 mb-0 campaign-font-light">
                          Add new payment methods
                        </p>
                        <div class="d-flex align-center justify-end">
                          <v-btn
                            @click="addPayment"
                            class="ml-3 form-button"
                            depressed
                            small
                          >
                            Add new payments
                          </v-btn>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-divider class="mt-0"></v-divider>
                    <div class="d-flex align-center justify-end mt-5">
                      <div>
                        <v-btn
                          @click="saveCampaign"
                          class="ml-3"
                          color="primary"
                          dark
                          depressed
                        >
                          Save
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '2' : ''"> </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";

  export default {
    data: () => ({
      valid: false,
      isLoading: false,
      formData: {
        id: null,
        campaign_name: null,
        tags: [],
        domain: null,
        timezone_id: null,
      },
      memberships: [
        {
          title: "Facebook Ads 101",
          image: "https://cdn.vuetifyjs.com/images/cards/house.jpg",
          text: "facebookads101.fusion.io",
        },
        {
          title: "Marketing",
          image: "https://cdn.vuetifyjs.com/images/cards/road.jpg",
          text: "marketingbook.fusion.io",
        },
        {
          title: "Plans",
          image: "https://cdn.vuetifyjs.com/images/cards/house.jpg",
          text: "plan.fusion.io",
        },
      ],
    }),
    mounted() {
      this.loadCampaign();
      this.formData.id = this.$route.query.campaign_id;
    },
    computed: {
      ...mapGetters("country", ["getTimezones"]),
      dateRangeText() {
        return this.datePick.join(" - ");
      },
      extension() {
        return this.file ? this.file.name.split(".").pop() : "";
      },
    },
    methods: {
      ...mapActions("campaign", ["fetchCampaign", "updateCampaign"]),
      loadCampaign() {
        this.fetchCampaign(this.$route.query.campaign_id).then((res) => {
          this.formData.campaign_name = res.data.campaign.campaign_name;
          this.formData.domain = res.data.campaign.campaign_domain;
          this.formData.tags = res.data.campaign.tags;
          this.formData.timezone_id = res.data.campaign.timezone_id;
        });
      },
      saveCampaign() {
        this.isLoading = true;

        this.$refs.campaignForm.validate();

        if (!this.valid) {
          window.toastr["error"]("Please fill up the required field");
          return true;
        }

        this.updateCampaign(this.formData)
          .then((res) => {
            if (res.data.success) {
              this.isLoading = false;
              this.$router.push({ name: "dashboard.index" });
            }
          })
          .catch((err) => {
            if (
              typeof err.response != "undefined" &&
              err.response.status === 400 &&
              err.response.data.message
            ) {
              // eslint-disable-next-line no-unused-vars
              Object.keys(err.response.data.message).forEach((key, index) => {
                if (typeof err.response.data.message[key] !== "undefined") {
                  window.toastr["error"](err.response.data.message[key]);
                }
              });
            }
            this.isLoading = false;
          });
      },
      getColor(type) {
        if (type == "Archived") return "grey";
        else return "green";
      },
      onChange(e) {
        var files = e.target.files || e.dataTransfer.files;

        if (!files.length) {
          this.dragging = false;
          return;
        }

        this.createFile(files[0]);
      },
      createFile(file) {
        if (file.size > 5000000) {
          alert("please check file size no over 5 MB.");
          this.dragging = false;
          return;
        }

        this.file = file;
        console.log(this.file);
        this.dragging = false;
      },
      removeFile() {
        this.file = "";
      },
      addTag() {
        this.allTags.push(this.tagValue);
        this.tagValue = null;
      },
    },
  };
</script>

<style lang="scss">
  .campaign-setting {
    .mdi-menu-down {
      display: none;
    }
    .v-select.v-input--dense .v-chip {
      margin: 2px 2px 0px 2px;
      background: #28a745;
      color: #ffffff !important;
      .v-icon {
        color: #ffffff !important;
      }
    }
    .v-input__append-inner {
      width: 0px;
    }
  }
</style>
