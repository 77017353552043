<template>
    <div class = "pa-4">
        <h2>Welcome back</h2>
        <h3>Complete your profile</h3>
        <h3>Profile image</h3>
        <v-avatar
                class = "profile"
                color = "grey"
                size  = "100"
                tile
        >
            <v-img src = "https://picsum.photos/510/300?random"></v-img>
        </v-avatar>
        <v-form
                ref     = "form"
                v-model = "valid"
                lazy-validation
        >
            <v-container>
                <v-divider></v-divider>
            </v-container>
            <!------------------------------------------------------------------------------->
            <h3>Developer Information</h3>
            <v-row>
                <v-col
                        cols = "12"
                        md   = "6"
                >
                    <v-text-field
                            v-model = "firstName"
                            :rules    = "firstNameRules"
                            label   = "First Name"
                            dense
                            outlined
                            hide-details = "auto"
                            class        = "rounded-lg grey--text text--lighten-1"
                            required
                    >
                    </v-text-field>
                </v-col>

                <v-col
                        cols = "12"
                        md   = "6"
                >
                    <v-text-field
                            v-model = "lastName"
                            :rules    = "lastNameRules"
                            label   = "Last Name"
                            dense
                            outlined
                            hide-details = "auto"
                            class        = "rounded-lg grey--text text--lighten-1"
                            required
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                        cols = "12"
                        md   = "6"
                >
                    <v-select
                            v-model = "selectCountry"
                            :items    = "countries"
                            :rules    = "[v => !!v || 'Country is required']"
                            label   = "Country of residence"
                            dense
                            outlined
                            hide-details = "auto"
                            class        = "rounded-lg grey--text text--lighten-1"
                            required
                    >
                    </v-select>
                </v-col>
                <v-col
                        cols = "12"
                        md   = "6"
                >
                    <v-text-field
                            v-model = "email"
                            :rules    = "emailRules"
                            label   = "E-mail"
                            dense
                            outlined
                            hide-details = "auto"
                            class        = "rounded-lg grey--text text--lighten-1"
                            required
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col
                        cols = "12"
                        md   = "6"
                >
                    <v-text-field
                            v-model = "phoneNo"
                            :rules    = "phoneNoRules"
                            label   = "Phone Number"
                            dense
                            outlined
                            hide-details = "auto"
                            class        = "rounded-lg grey--text text--lighten-1"
                            required
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-container>
                <v-divider></v-divider>
            </v-container>
            <!--------------------------------------------------------------------------------->
            <h3>Preferred Salary</h3>
            <v-row>
                <v-col
                        cols = "12"
                        md   = "6"
                >
                    <v-select
                            v-model = "selectCurrency"
                            :items    = "currencies"
                            :rules    = "[v => !!v || 'Currency is required']"
                            label   = "Currency"
                            dense
                            outlined
                            hide-details = "auto"
                            class        = "rounded-lg grey--text text--lighten-1"
                            required
                    >
                    </v-select>
                </v-col>
                <v-col
                        cols = "12"
                        md   = "6"
                >
                    <v-text-field
                            v-model = "salary"
                            :rules    = "salaryRules"
                            label   = "Amount"
                            suffix="per Year"
                            dense
                            outlined
                            hide-details = "auto"
                            class        = "rounded-lg grey--text text--lighten-1"
                            required
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-container>
                <v-divider></v-divider>
            </v-container>
            <!--------------------------------------------------------------------------------->
            <h3>Preferred Role & Experience</h3>
            <v-row>
                <v-col
                        cols = "12"
                        md   = "6"
                >
                    <v-select
                            v-model = "selectRole"
                            :items    = "role"
                            :rules    = "[v => !!v || 'Prefered Role is required']"
                            label   = "Prefered Role"
                            dense
                            outlined
                            hide-details = "auto"
                            class        = "rounded-lg grey--text text--lighten-1"
                            required
                    >
                    </v-select>
                </v-col>
                <v-col
                        cols = "12"
                        md   = "6"
                >
                    <v-text-field
                            v-model = "years"
                            :rules    = "yearsRules"
                            label   = "Years of experience"
                            dense
                            outlined
                            hide-details = "auto"
                            class        = "rounded-lg grey--text text--lighten-1"
                            required
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-container>
                <v-divider></v-divider>
            </v-container>
            <!--------------------------------------------------------------------------------->
            <h3>Skills</h3>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">Prefered Role</th>
                        <th class="text-left">Competency</th>
                        <th class="text-left">Year of Experience</th>
                        <td></td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="index in 3" :key="index">
                        <td>
                            <v-select
                                    v-model = "tblselectRole"
                                    :items    = "tblRole"
                                    :rules    = "[v => !!v || 'Prefered Role is required']"
                                    label   = "Full stack"
                                    dense
                                    outlined
                                    hide-details = "auto"
                                    class        = "rounded-lg grey--text text--lighten-1"
                                    required
                            >
                            </v-select>
                        </td>
                        <td>
                            <v-select
                                    v-model = "tblselectCompetency"
                                    :items    = "tblCompetency"
                                    :rules    = "[v => !!v || 'Prefered Role is required']"
                                    label   = "Select the level"
                                    dense
                                    outlined
                                    hide-details = "auto"
                                    class        = "rounded-lg grey--text text--lighten-1"
                                    required
                            >
                            </v-select>
                        </td>

                        <td>
                            <v-menu
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                            v-model="date"
                                            label="Picker without buttons"
                                            prepend-icon="event"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker>
                            </v-menu>
                        </td>
                        <td>
                            <a v-on:click="removeElement(index);" style="cursor: pointer">Remove</a>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <div>
                <v-btn class="button btn-primary" @click="addRow">Add row</v-btn>
            </div>
            <v-container>
                <v-divider></v-divider>
            </v-container>
            <!------------------------------------------------------------------------------------>
            <v-btn
                    :disabled = "!valid"
                    color   = "success"
                    class   = "mr-4"
                    @click  = "validate"
            >
                Submit
            </v-btn>

            <v-btn
                    color  = "error"
                    class  = "mr-4"
                    @click = "reset"
            >
                Reset Form
            </v-btn>
        </v-form>
    </div>
</template>

<script>
  export default {
    data: () => ({
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      valid         : true,
      firstName     : '',
      firstNameRules: [
        v => !!v || 'First Name is required',
      ],
      lastName     : '',
      lastNameRules: [
        v => !!v || 'Last Name is required',
      ],
      email     : '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      select: null,
      countries : [
        'Country 1',
        'Country 2',
        'Country 3',
        'Country 4',
      ],
      phoneNo     : '',
      phoneNoRules: [
        v => !!v || 'Phone Number is required',
        v => /^\d+$/.test(v) || 'Phone Number must be number only',
      ],
      currencies:[
        'Currency 1',
        'Currency 2',
        'Currency 3',
        'Currency 4',
      ],
      salary     : '',
      salaryRules: [
        v => !!v || 'Amount is required',
        v => /^\d+$/.test(v) || 'Amount must be number only',
      ],
      role:[
        'Role 1',
        'Role 2',
        'Role 3',
        'Role 4',
      ],
      yearsRules: [
        v => !!v || 'Years is required',
        v => /^\d+$/.test(v) || 'Years must be number only',
      ],
      tblRole:[
        'tblRole 1',
        'tblRole 2',
        'tblRole 3',
        'tblRole 4',
      ],
      tblCompetency:[
        'tblCompetency 1',
        'tblCompetency 2',
        'tblCompetency 3',
        'tblCompetency 4',
      ],
    }),

    methods: {
      validate () {
        this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      }
    }
  }
</script>

<style scoped>
    .grey--text{
        background-color:white;
    }
</style>
