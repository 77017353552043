import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'
// import Ls from '@/services/ls'

const initialState = {
  plans: []
}

export default {
  namespaced: true,

  state: initialState,

  getters: getters,

  actions: actions,

  mutations: mutations
}
