<template>
  <div
    class="flowchart-node"
    :style="nodeStyle"
    @mousedown="handleMousedown"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave"
    v-bind:class="{ selected: options.selected === id }"
  >
    <div
      class="node-port node-input"
      @mousedown="inputMouseDown"
      @mouseup="inputMouseUp"
    ></div>
    <div class="node-main">
      <v-icon class="node-type">{{ icon }}</v-icon>
      <!-- <div v-text="type" class="node-type"></div> -->
    </div>
    <div v-text="label" class="node-label"></div>
    <div class="node-port node-output" @mousedown="outputMouseDown"></div>
    <div v-show="show.delete" class="node-delete">
      <span class="cross-icon">&times;</span>
    </div>
    <div v-show="show.configure" class="node-configure">
      <span class="gear-icon">&#9881;</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "FunnelNode",
  props: {
    id: {
      type: [String, Number],
      default: '1000'
    },
    x: {
      type: Number,
      default: 0,
      validator(val) {
        return typeof val === "number";
      },
    },
    y: {
      type: Number,
      default: 0,
      validator(val) {
        return typeof val === "number";
      },
    },
    type: {
      type: String,
      default: "Default",
    },
    icon: {
      type: String,
      default: '$appZapIcon',
    },
    requiredSetup: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "input name",
    },
    options: {
      type: Object,
      default() {
        return {
          centerX: 1024,
          scale: 1,
          centerY: 140,
        };
      },
    },
  },
  data() {
    return {
      show: {
        delete: false,
        configure: false,
      },
    };
  },
  mounted() {},
  computed: {
    nodeStyle() {
      return {
        top: this.options.centerY + this.y * this.options.scale + "px", // remove: this.options.offsetTop +
        left: this.options.centerX + this.x * this.options.scale + "px", // remove: this.options.offsetLeft +
        transform: `scale(${this.options.scale})`,
      };
    },
  },
  methods: {
    handleMousedown(e) {
      const target = e.target || e.srcElement;
      if (
        typeof target.className === 'string' &&
        target.className.indexOf("node-input") < 0 &&
        target.className.indexOf("node-output") < 0
      ) {
        this.$emit("nodeSelected", e);
      }
      e.preventDefault();
    },
    handleMouseOver() {
      this.show.delete = true;
      this.show.configure = true;
    },
    handleMouseLeave() {
      this.show.delete = false;
      this.show.configure = false;
    },
    outputMouseDown(e) {
      this.$emit("linkingStart");
      e.preventDefault();
    },
    inputMouseDown(e) {
      e.preventDefault();
    },
    inputMouseUp(e) {
      this.$emit("linkingStop");
      e.preventDefault();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$themeColor: rgb(241, 241, 241);
$portSize: 12;
.flowchart-node {
  margin: 0;
  width: 80px;
  height: 80px;
  position: absolute;
  box-sizing: border-box;
  border: none;
  z-index: 1;
  opacity: 0.9;
  cursor: move;
  transform-origin: top left;
  .node-main {
    border: 3px solid #c5c5c5;
    border-radius: 10px;
    text-align: center;
    width: 80px;
    height: 80px;
    background: $themeColor;
    display: flex;
    align-items: center;
    justify-content: center;
    .node-type {
      color: black;
      font-size: 15px;
      padding: 6px;
    }
  }
  .node-label {
    padding-top: 5px;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
  }
  .node-port {
    position: absolute;
    width: #{$portSize}px;
    height: #{$portSize}px;
    left: 50%;
    transform: translate(-50%);
    border: 1px solid #ccc;
    border-radius: 100px;
    background: white;
    &:hover {
      background: $themeColor;
      border: 1px solid $themeColor;
    }
  }
  .node-input {
    left: #{-2 + $portSize/-2}px;
    top: 50%;
    transform: translateY(-50%);
  }
  .node-output {
    right: #{-2 + $portSize/-2}px;
    left: unset;
    top: 50%;
    transform: translateY(-50%);
  }
  .node-delete {
    position: absolute;
    right: -6px;
    top: -6px;
    font-size: 14px;
    width: 19px;
    height: 19px;
    color: white;
    cursor: pointer;
    background: #828282;
    border: 1.5px solid #ffffff;
    border-radius: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    &:hover {
      background: #4e4e4e;
    }
  }
  .node-configure {
    position: absolute;
    right: -6px;
    bottom: -6px;
    font-size: 14px;
    width: 19px;
    height: 19px;
    color: white;
    cursor: pointer;
    background: #3855b3;
    border: 1.5px solid #ffffff;
    border-radius: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    &:hover {
      background: #0c2d97;
    }
  }
}
.selected {
  // box-shadow: 0 0 0 2px $themeColor;
}
</style>