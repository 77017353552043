import VueRouter from 'vue-router'
import Vuex from 'vuex'
import Vue from 'vue'
import App from './App.vue'
import Vuelidate from 'vuelidate'
import vuetify from './plugins/vuetify';
import jwtToken from './helpers/jwt-token'
import VueSweetalert2 from 'vue-sweetalert2';

import store from './store'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Ls from '@/services/ls'
// scss
import './scss/app.scss'


library.add(faTimesCircle)

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');
window.axios.defaults.baseURL = (process.env.NODE_ENV !== 'production') ? process.env.VUE_APP_LOCAL_BASE_URL : ''
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';


// Axios setup
window.axios.interceptors.request.use(config => {
  config.headers['X-Requested-With'] = 'XMLHttpRequest';
  if (jwtToken.getToken()) {
    config.headers['Authorization'] = 'Bearer ' + jwtToken.getToken();
  }

  return config;
}, error => {
  return Promise.reject(error);
});

// Axios response token verification
window.axios.interceptors.response.use(response => {
  return response;
}, error => {
  let errorResponseData = error.response;

  if (errorResponseData.data && errorResponseData.data.message === "Unauthenticated." && errorResponseData.status === 401 ) {
    store.dispatch('auth/unsetUser')
      .then(() => {
        router.push({name: 'login'});
      });
  }

  return Promise.reject(error);
});

Vue.use(Vuelidate)
Vue.use(VueSweetalert2)
Vue.config.productionTip = false

Vue.component('font-awesome-icon', FontAwesomeIcon)

/**
 * Global plugins
 */
window.toastr = require('toastr')
window.Vue = require('vue')

Vue.use(VueRouter)
Vue.use(Vuex)

window.Ls = Ls
global.$ = global.jQuery = require('jquery')

window.vBus = new Vue()

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
