<template>
    <v-app style="max-width: 1200px; margin: 0 auto; margin-top: 20px;">
        <v-main>
            <v-row app absolute floating>
                <v-col cols="8">
                    <v-list-item>
                        <v-list-item-avatar left width="50">
                            <v-img src="/img/getcto_icon.png"/>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <div class="text-h4">GetCTO</div>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="4">
                    <!-- Provides the application the proper gutter -->
                    <v-row>
                        <v-col>
                            <v-toolbar flat dense>
                                <v-spacer></v-spacer>
                                <div>
                                    <v-list-item-title>User Name</v-list-item-title>
                                    <v-list-item-subtitle>Position</v-list-item-subtitle>
                                </div>
                                <v-list-item-avatar width="50">
                                    <v-img src="/img/getcto_icon.png" max-width="70px"/>
                                </v-list-item-avatar>
                            </v-toolbar>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <router-view></router-view>
        </v-main>
    </v-app>
</template>

<script>
  export default {
    data () {
      return {
        menuIndex: 1,
        menu: [
          {title: 'Dashboard', icon: 'dashboard', route: {name: 'dashboard.index'}},
          {title: 'Test', icon: 'description', route: {name: 'test.index'}},
          {title: 'Jobs', icon: 'card_travel', route: {name: 'job.index'}},
          {title: 'Discover', icon: 'person_outline', route: {name: 'discover.index'}}
        ]
      }
    }
  }
</script>

<style scoped>

</style>
