import * as types from './mutation-types'

export default {
    [types.SET_USER](state, user) {
        state.user = user;
    },

    [types.SET_TOKEN](state, token) {
        state.token = token;
    },

    [types.UNSET_USER](state) {
        state.user = null;
    },

    [types.UNSET_TOKEN](state) {
        state.token = null;
    },

    [types.SET_USER_PLAN](state, plan) {
        state.user.plan = plan;
        state.user.plan_id = plan.id;
        console.log(state.user.plan)
    },

    [types.UNSET_USER_PLAN](state) {
        state.user.plan = null;
        state.user.plan_id = null;
    },

    [types.SET_USER_BILLING_INFO](state, billing_info) {
        console.log(billing_info, 'billing info')
        state.user.billing_info = billing_info;
    },

    [types.SET_STRIPE_INTENT](state, intent) {
        state.user.stripe_intent = intent;
    }

}
