<template>
  <div>
    <v-row>
      <v-col class="mx-auto register-payment-main">
        <stepper-dot :current-step="currentStep" class="mb-9"></stepper-dot>
        <div class="text-h5 text-center mb-11">Payment</div>

        <v-row class=" payment-form-container justify-center">
          <!-- Left Form input -->
          <v-col md="5" lg="5" sm="12">
            <div class="text-subtitle-1">
              Billing Information
            </div>
            <v-form ref="paymentForm" v-model="valid" lazy-validation class="mt-5" @submit.prevent="submitPaymentForm">
              <v-text-field
                  v-model="formData.address"
                  :rules="formValidations.address"
                  background-color="white"
                  class="my-4"
                  placeholder="Full Address"
                  outlined
                  dense
                  hide-details="auto"/>
              <v-text-field
                  v-model="formData.city"
                  :rules="formValidations.city"
                  background-color="white"
                  class="my-4"
                  placeholder="City Name"
                  outlined
                  dense
                  hide-details="auto"/>

              <v-row class="my-4">
                <v-col md="9" class="py-0">
                  <v-text-field
                      v-model="formData.state"
                      :rules="formValidations.state"
                      background-color="white"
                      placeholder="State / Province"
                      outlined
                      dense
                      hide-details="auto"/>
                </v-col>
                <v-col md="3" class="py-0">
                  <v-text-field
                      v-model="formData.postcode"
                      :rules="formValidations.postcode"
                      type="number"
                      background-color="white"
                      placeholder="Zip Code"
                      outlined
                      dense
                      class="number-input"
                      hide-details="auto"/>
                </v-col>
              </v-row>

              <v-autocomplete
                  v-model="formData.country_id"
                  :rules="formValidations.country_id"
                  :items="getCountries"
                  item-text="name"
                  item-value="id"
                  background-color="white" class="my-4" placeholder="Country" outlined dense hide-details="auto"/>
              <v-text-field
                  v-model="formData.contact_no"
                  :rules="formValidations.contact_no"
                  background-color="white"
                  class="my-4"
                  placeholder="Phone"
                  outlined
                  dense
                  hide-details="auto"/>

              <div class="text-subtitle-1 mb-4">
                Payment
              </div>

              <v-card color="#e5e5e5" class="mb-7">
                <v-card-text>
                  <v-text-field
                      background-color="white"
                      class="mb-4"
                      placeholder="Credit Card"
                      outlined
                      dense
                      hide-details="auto"
                      filled/>
                  <v-text-field
                      background-color="white"
                      class="my-4"
                      placeholder="Name on card"
                      outlined
                      dense
                      hide-details="auto"
                      filled/>
                  <div id="card-element"></div>
                  <div v-if="stripe_error">{{ stripe_error }}</div>
                  <!-- <inline-credit-card-field v-model="formData.payment.card"></inline-credit-card-field> -->
                </v-card-text>
              </v-card>

              <div class="d-flex justify-space-between">
                <v-btn color="#e5e5e5" @click="backToPlanSelection">
                  <v-icon small left>$appArrowBackIcon</v-icon>
                  Change Plan
                </v-btn>

                <v-btn color="primary"
                       :loading="isLoading"
                       type="submit"
                >
                  Proceed to Pay
                </v-btn>
              </div>
            </v-form>
          </v-col>
          <!-- Right amount display -->
          <v-col md="4" lg="4" sm="12">
            <div class="text-subtitle-1 mb-4">
              Order Information
            </div>

            <div class="order-container pa-3 rounded" >
              <div class="d-flex justify-space-between">
                <div>
                  <div class="font-weight-bold">
                    {{ getPlan.plan_name }} Plan Subscription (Monthly)
                  </div>
                  <div class="text-body-2 grey--text">
                    Starting {{ getDatePeriod }}
                  </div>
                </div>
                <div>
                  {{ getPlan.plan_price }}
                </div>
              </div>

              <v-divider class="my-5"></v-divider>

              <div class="d-flex justify-space-between mb-2">
                <div>Sub-total</div>
                <div>{{ getPlan.plan_price }}</div>
              </div>

              <div class="d-flex justify-space-between">
                <div>Estimated tax</div>
                <div>RM 0.00</div>
              </div>

              <v-divider class="my-5"></v-divider>


              <div class="d-flex justify-space-between">
                <div class="font-weight-bold">Total</div>
                <div class="font-weight-bold">{{ getPlan.plan_price }}</div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import StepperDot from "@/components/StepperDot";
// import * as VCCF from 'vue-credit-card-field';
import {mapActions, mapGetters} from "vuex";

export default {
  name: "RegistrationPayment",
  components: {
    StepperDot,
    // 'inline-credit-card-field': VCCF.default.InlineCreditCardField,
  },
  data() {
    return {
      currentStep: 3,
      valid: true,
      isLoading: false,
      formData: {
        address: null,
        city: null,
        state: null,
        postcode: null,
        country_id: null,
        contact_no: null,
        payment: {
          method: null,
          name_on_card: null,
          card: null,
        },
        payment_method_signature: null,
      },
      formValidations: {
        address: [
          v => !!v || 'Full Address is required',
        ],
        city: [
          v => !!v || 'City is required',
        ],
        state: [
          v => !!v || 'State is required',
        ],
        postcode: [
          v => !!v || 'Zip Code is required',
          v => ( v && v.length >= 5) || "Zip Code required at least 5 characters",
          v => ( v && v.length <= 10) || "Zip Code at most 10 characters",
        ],
        country_id: [
          v => !!v || 'Country is required',
        ],
        contact_no: [
          v => !!v || 'Phone number is required',
        ],
      },
      stripeKey: 'pk_test_51J5OY0IzrjfehmrWwPFltq2HTs3q94YNLbZ96fxDwqzYsINbqno19sXB9PKvpOjL7LCSkDxM8a3AW20jqrWmeyfw005MvRzOZE',
      intent: {},
      stripe: null,
      stripe_error: null,
    }
  },
  watch: {

  },
  computed: {
    ...mapGetters('auth', [
      'getPlan'
    ]),...mapGetters('country', [
      'getCountries'
    ]),
    getDatePeriod() {
      let today = new Date();
      return today.getFullYear()+'/'+today.getMonth()+'/'+today.getDate()
    }
  },
  mounted() {
    this.updatePaymentMethod();
    this.initializeStripe();

  },
  methods: {
    ...mapActions('auth', [
      'storeBillingInfo',
      'deselectPlan',
      'stripeSetupIntent',
    ]),
    initializeStripe() {
      this.stripe = window.Stripe(this.stripeKey);

      const elements = this.stripe.elements();
      const cardElement = elements.create('card');
      this.cardElement = cardElement;

      cardElement.mount('#card-element');
    },
    async submitPaymentForm() {
      // this.$refs.paymentForm.validate();

      if (!this.$refs.paymentForm.validate()) {
        return false;
      }
      
      const clientSecret = this.intent.client_secret;

      // sign payment method via stripe
      const { setupIntent, error } = await this.stripe.confirmCardSetup(
          clientSecret, {
              payment_method: {
                  card: this.cardElement,
                  billing_details: { name: 'test fusion' }
              }
          }
      );

      if (error) {
          // Display "error.message" to the user...
          console.log(error.message);
          this.stripe_error = error.message;
      } else {
        // The card has been verified successfully...
          this.formData.payment_method_signature = setupIntent.payment_method
      }

      this.isLoading = true
      this.storeBillingInfo(this.formData)
          .then((res) => {
            this.isLoading = false
            if (res.data.success) {
              this.$router.push({ name: 'dashboard.index'})
            } else {
              window.toastr['error']('Failed to Pay')
            }
          })
          .catch((err) => {
            if (typeof err.response != 'undefined' && err.response.status === 422) {
              // eslint-disable-next-line no-unused-vars
              Object.keys(this.errors).forEach((key,index) => {
                if (typeof err.response.data[key] !== 'undefined') {
                  window.toastr['error'](err.response.data[key])
                }
              });
            }
            this.isLoading = false
          })
    },
    backToPlanSelection() {
      this.deselectPlan().then(() => {
        this.$router.go(-1)
      })
    },
    addStripeScript() {
      let script = document.createElement('script')
      script.setAttribute('src', 'https://js.stripe.com/v3/')
      document.head.appendChild(script)
    },
    errorHandler(e) {
      this.errorMessage = e.error ? e.error.message : '';
    },
    updatePaymentMethod() {
      this.stripeSetupIntent()
        .then((res) => {
          this.intent = res.data.data.intent;
          console.log(this.intent);
        })
    },
  }

};
</script>

<style lang="scss">
.payment-form-container {
  .credit-card-field-wrapper {
    .form-control {
      &.credit-card-field {
        border: 1px solid #9e9e9e;
        border-radius: 4px;

        &.is-focused {
          border: 2px solid #1976d2;
          box-shadow: unset;
        }
      }
    }
  }
}
.order-container {
  border: 1px solid #eeeeee;
}
.number-input input[type='number'] {
  -moz-appearance:textfield;
}
.number-input input::-webkit-outer-spin-button,
.number-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

</style>
