<template>
  <div class="pa-4">
    <v-row>
      <v-col cols="6">
        <div class="d-flex align-center">
          <v-btn
            class="mr-5 pa-1 form-button"
            href="/contacts/Index"
            height="auto"
            min-width="auto"
          >
            <v-icon>
              mdi-arrow-left
            </v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="4"> </v-col>
    </v-row>
    <v-container class="pa-0" fluid>
      <div>
        <v-row>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
            <v-row>
              <v-col>
                <v-card>
                  <!-- Dialog Header -->
                  <v-card-title>
                    NEW CUSTOMER
                  </v-card-title>

                  <v-card-text class="overflow-auto">
                    <v-form ref="customerForm" v-model="customerValidated">
                      <v-row>
                        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
                          <div class="text--primary">
                            First Name
                          </div>
                          <v-text-field
                            v-model="formData.first_name"
                            :rules="[(v) => !!v || 'First Name is required']"
                            label="First Name"
                            outlined
                            dense
                            flat
                            hide-details="auto"
                            solo
                          ></v-text-field>
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
                          <div class="text--primary">
                            Last Name
                          </div>
                          <v-text-field
                            v-model="formData.last_name"
                            :rules="[(v) => !!v || 'Last Name is required']"
                            label="Last Name"
                            outlined
                            dense
                            flat
                            hide-details="auto"
                            solo
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <div class="text--primary">
                            Email
                          </div>
                          <v-text-field
                            v-model="formData.email"
                            :rules="[
                              (v) => !!v || 'Email is required',
                              (v) =>
                                /.+@.+\..+/.test(v) || 'E-mail must be valid',
                            ]"
                            label="Email"
                            outlined
                            dense
                            flat
                            hide-details="auto"
                            solo
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <div class="text--primary">
                            Password
                          </div>
                          <v-text-field
                            v-model="formData.password"
                            :rules="[
                              (v) => !!v || 'Password is required',
                              (v) =>
                                (v && v.length >= 8) ||
                                'Password required at least 8 characters',
                            ]"
                            label="Password"
                            outlined
                            dense
                            flat
                            type="password"
                            hide-details="auto"
                            solo
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <div class="text--primary">
                            Phone Number
                          </div>
                          <v-text-field
                            v-model="formData.contact_no"
                            :rules="[
                              (v) => !!v || 'Contact No is required',
                              (v) =>
                                (v && v.length >= 8 && v.length <= 12) ||
                                'Contact No not valid',
                            ]"
                            label="Phone Number"
                            outlined
                            dense
                            flat
                            hide-details="auto"
                            solo
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <div class="text--primary font-weight-black mb-3">
                          Email Marketing
                        </div>
                      </v-col>
                      <v-col>
                        <div class="d-flex align-center justify-end">
                          <a>
                            Edit Status
                          </a>
                        </div>
                      </v-col>
                    </v-row>
                    <v-chip
                      :color="formData.email_marketing_status_id ? 'green' : ''"
                      :text-color="
                        formData.email_marketing_status_id ? 'white' : ''
                      "
                      @click="
                        formData.email_marketing_status_id = !formData.email_marketing_status_id
                      "
                    >
                      <v-icon v-if="formData.email_marketing_status_id" left>
                        mdi-check
                      </v-icon>
                      {{
                        formData.email_marketing_status_id
                          ? "Subscribed"
                          : "Subscribe"
                      }}
                    </v-chip>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <div class="text--primary font-weight-black">
                          Tags
                        </div>
                      </v-col>
                      <v-col>
                        <div class="d-flex align-center justify-end">
                          <a>
                            View all tags
                          </a>
                        </div>
                      </v-col>
                    </v-row>
                    <v-text-field
                      v-model="tagValue"
                      class="mb-5"
                      label="VIP, sale, segment, etc"
                      outlined
                      dense
                      flat
                      solo
                      hide-details="auto"
                      @keydown.enter="addTag"
                    ></v-text-field>
                    <v-chip
                      close
                      class="mr-2"
                      outlined
                      v-for="(tag, index) in formData.tags"
                      :key="tag"
                      @click:close="formData.tags.splice(index, 1)"
                    >
                      <span>{{ tag }}</span>
                    </v-chip>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? '10' : '12'">
            <v-divider class="mt-0"></v-divider>
            <div class="d-flex align-center justify-end mt-5">
              <v-btn
                class="mr-2"
                color="primary"
                @click="onSubmit"
                :loading="isLoading"
              >
                Save
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
  import { mapActions } from "vuex";

  export default {
    data: () => ({
      isLoading: false,
      tagValue: null,
      customerValidated: true,
      allTags: [],
      formData: {
        first_name: null,
        last_name: null,
        email: null,
        contact_no: null,
        password: null,
        email_marketing_status_id: false,
        tags: [],
      },
    }),
    computed: {},
    methods: {
      ...mapActions("customer", ["postCustomer"]),
      onSubmit() {
        this.$refs.customerForm.validate();

        if (!this.customerValidated) {
          return true;
        }

        this.isLoading = true;

        this.postCustomer(this.formData)
          .then((res) => {
            this.isLoading = false;
            if (res.data.success) {
              this.$router.push({ name: "contacts.index" });
              window.toastr["success"]("Customer created successfully");
            } else {
              window.toastr["error"]("Failed to create customer");
            }
          })
          .catch((err) => {
            if (
              typeof err.response != "undefined" &&
              err.response.status === 422
            ) {
              // eslint-disable-next-line no-unused-vars
              Object.keys(this.errors).forEach((key, index) => {
                if (typeof err.response.data[key] !== "undefined") {
                  window.toastr["error"](err.response.data[key]);
                }
              });
            } else if (err.response.data.message) {
              window.toastr["error"](err.response.data.message);
            }
            this.isLoading = false;
          });
      },
      getColor(type) {
        if (type == "Archived") return "grey";
        else return "green";
      },
      addTag() {
        this.formData.tags.push(this.tagValue);
        this.tagValue = null;
      },
    },
  };
</script>

<style scoped></style>
