<template>
  <div class="pa-4">
    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : ''">
        <div class="d-flex align-center">
          <v-btn
            class="mr-5 pa-1 form-button"
            href="/contacts/Index"
            height="auto"
            min-width="auto"
          >
            <v-icon>
              mdi-arrow-left
            </v-icon>
          </v-btn>
          <div class="text--primary text-h5">Barry Jackson</div>
        </div>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : ''">
        <div class="d-flex align-center justify-end mr-3">
          <div class="mr-3">
            <v-btn href="#" class="form-button">
              <v-icon left>
                mdi-arrow-left
              </v-icon>
              Previous
            </v-btn>
          </div>
          <v-btn href="#" class="form-button">
            Next
            <v-icon right>
              mdi-arrow-right
            </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-container class="pa-0" fluid>
      <form>
        <v-row>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : ''">
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <v-row no-gutters justify>
                      <v-col class="px-0 mr-0 d-flex">
                        <v-avatar color="grey">
                          <v-icon dark>
                            mdi-account-circle
                          </v-icon>
                        </v-avatar>
                        <div class="ml-3">
                          <div class="text--primary text-h6 font-weight-black">
                            Barry Jackson
                          </div>
                          <div>
                            barryjackson@gmail.com
                          </div>
                          <div>
                            Affiliates for 3 months
                          </div>
                        </div>
                      </v-col>
                      <v-col>
                        <div class="d-flex justify-end">
                          <v-dialog
                            scrollable
                            v-model="editCustomerDialog"
                            max-width="555"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="btn-small-letter ma-0"
                                text
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                              >
                                Edit Customer
                              </v-btn>
                            </template>
                            <v-card>
                              <!-- Dialog Header -->
                              <v-card-title>
                                EDIT CUSTOMER
                              </v-card-title>

                              <v-card-text class="overflow-auto">
                                <v-form
                                  ref="customer_form"
                                  v-model="customerValidated"
                                >
                                  <v-row>
                                    <v-col cols="6">
                                      <div class="text--primary">
                                        First Name
                                      </div>
                                      <v-text-field
                                        label="First Name"
                                        outlined
                                        dense
                                        flat
                                        hide-details="auto"
                                        solo
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                      <div class="text--primary">
                                        Last Name
                                      </div>
                                      <v-text-field
                                        label="Last Name"
                                        outlined
                                        dense
                                        flat
                                        hide-details="auto"
                                        solo
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col>
                                      <div class="text--primary">
                                        Email
                                      </div>
                                      <v-text-field
                                        label="Email"
                                        outlined
                                        dense
                                        flat
                                        hide-details="auto"
                                        solo
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col>
                                      <div class="text--primary">
                                        Phone Number
                                      </div>
                                      <v-text-field
                                        label="Phone Number"
                                        outlined
                                        dense
                                        flat
                                        hide-details="auto"
                                        solo
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-card-text>
                              <v-divider class="mx-6"></v-divider>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  class="mr-2"
                                  @click="cancelCustomerForm"
                                  text
                                >
                                  Close
                                </v-btn>
                                <v-btn
                                  class="mr-2"
                                  color="primary"
                                  @click="submitCustomerForm"
                                  :loading="customerLoading"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <div class="text--primary font-weight-black mb-3">
              Historical Activity
            </div>
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <div v-for="item in history" :key="item">
                      <div>
                        {{ item.date }}
                      </div>
                      <v-divider class="my-3"></v-divider>
                      <v-timeline dense class="ml-0 mb-3">
                        <v-timeline-item
                          small
                          color="grey"
                          v-for="detail in item.details"
                          :key="detail"
                        >
                          <v-row justify="space-between">
                            <v-col cols="8">
                              <div class="text--primary">
                                {{ detail.text }}
                              </div>
                            </v-col>
                            <v-col class="text-right" cols="4">
                              {{ detail.time }}
                            </v-col>
                          </v-row>
                        </v-timeline-item>
                      </v-timeline>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : ''">
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <div class="text--primary font-weight-black">
                      Earning Summary
                    </div>
                    <v-row no-gutters justify class="mt-3">
                      <v-col class="px-0 mr-0 d-flex">
                        <v-avatar color="grey lighten-5">
                          <v-icon color="green" dark>
                            mdi-currency-usd
                          </v-icon>
                        </v-avatar>
                        <div class="ml-3">
                          <div class="text--primary text-h5 font-weight-black">
                            RM 203.00
                          </div>
                          <div>
                            Total Earnings
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider class="my-3"></v-divider>
                    <v-row no-gutters justify class="mt-3">
                      <v-col class="px-0 mr-0 d-flex">
                        <v-icon color="green" dark>
                          mdi-currency-usd
                        </v-icon>
                        <div class="ml-3">
                          <div class="text--primary">
                            RM100.00
                          </div>
                          <div>
                            Paid earnings
                          </div>
                        </div>
                      </v-col>
                      <v-col class="px-0 mr-0 d-flex">
                        <v-icon color="green" dark>
                          mdi-currency-usd
                        </v-icon>
                        <div class="ml-3">
                          <div class="text--primary">
                            RM0.00
                          </div>
                          <div>
                            Pending payout
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <div class="text--primary font-weight-black mb-3">
                          Top Performing Affiliates
                        </div>
                      </v-col>
                    </v-row>
                    <v-row no-gutters justify class="mt-3">
                      <v-col class="px-0 mr-0 d-flex mb-3">
                        <v-icon color="green" dark>
                          mdi-share-variant
                        </v-icon>
                        <div class="ml-3">
                          <div class="text--primary font-weight-black">
                            Project 1
                          </div>
                          <div>
                            https://project1.fusion.io/register
                          </div>
                        </div>
                      </v-col>
                      <v-col class="px-0 mr-0 d-flex mb-3">
                        <v-icon color="green" dark>
                          mdi-share-variant
                        </v-icon>
                        <div class="ml-3">
                          <div class="text--primary font-weight-black">
                            Amazon
                          </div>
                          <div>
                            https://amazonaffiliates.com
                          </div>
                        </div>
                      </v-col>
                      <v-col class="px-0 mr-0 d-flex mb-3">
                        <v-icon color="green" dark>
                          mdi-share-variant
                        </v-icon>
                        <div class="ml-3">
                          <div class="text--primary font-weight-black">
                            Marketing Book
                          </div>
                          <div>
                            https://marketingbook.fusion.io
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <div class="text--primary font-weight-black mb-3">
                          Email Marketing
                        </div>
                      </v-col>
                      <v-col>
                        <div class="d-flex align-center justify-end">
                          <a>
                            Edit Status
                          </a>
                        </div>
                      </v-col>
                    </v-row>
                    <v-chip color="green" text-color="white">
                      <v-icon left>
                        mdi-check
                      </v-icon>
                      Subscribed
                    </v-chip>
                    <div class="mt-3">
                      Subscribed on 14 January 2021
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <div class="text--primary font-weight-black">
                          Tags
                        </div>
                      </v-col>
                      <v-col>
                        <div class="d-flex align-center justify-end">
                          <a>
                            View all tags
                          </a>
                        </div>
                      </v-col>
                    </v-row>
                    <v-text-field
                      v-model="tagValue"
                      class="mb-5"
                      label="VIP, sale, segment, etc"
                      outlined
                      dense
                      flat
                      solo
                      hide-details="auto"
                      @keydown.enter="addTag"
                    ></v-text-field>
                    <v-chip
                      close
                      class="mr-2"
                      outlined
                      v-for="(tag, index) in allTags"
                      :key="tag"
                      @click:close="allTags.splice(index, 1)"
                    >
                      <span>{{ tag }}</span>
                    </v-chip>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="form-card mr-3 mb-3">
                  <v-card-text>
                    <div class="text--primary font-weight-black mb-3">
                      Blacklist
                    </div>
                    <v-row class="mb-0">
                      <v-col>
                        <div>
                          None
                        </div>
                      </v-col>
                      <v-col>
                        <div class="d-flex align-center justify-end">
                          <a>
                            Add to Blacklist
                          </a>
                        </div>
                      </v-col>
                    </v-row>
                    <div>
                      Adding this customer to blacklist will prevent them from
                      accessing the website.
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? '10' : ''">
            <v-divider class="mt-0"></v-divider>
            <div class="mt-5">
              <v-btn color="red" outlined>
                <v-icon class="mr-2" color="red">
                  mdi-delete
                </v-icon>
                <div style="color:red;">Delete Referrer</div>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </form>
    </v-container>
  </div>
</template>

<script>
  export default {
    data: () => ({
      tagValue: null,
      allTags: [],
      file: "",
      dragging: false,
      list: null,
      page: 1,
      pageCount: 0,
      projectPerPage: 10,
      tabs: [
        { name: "All" },
        { name: "Digital" },
        { name: "Physical" },
        { name: "Services" },
        { name: "Tickets" },
        { name: "Membership" },
        { name: "Archived" },
      ],
      cards: [
        { title: "Total", icon: "mdi-arrow-left", text: "12" },
        { title: "Digital", icon: "mdi-thumb-down", text: "8" },
        { title: "Physical", icon: "mdi-thumb-up", text: "4" },
        { title: "Archived", icon: "mdi-minus-circle", text: "2" },
      ],
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        { text: "Type", value: "type" },
        { text: "Date Modified", value: "date_update" },
        { text: "Inventory", value: "inventory" },
        { text: "Atr 2", value: "status" },
        { text: "Action", value: "action", sortable: false },
      ],
      projects: [
        {
          name: "Frozen Yogurt",
          type: "Digital",
          date_update: 6.0,
          inventory: 24,
          status: 4.0,
        },
        {
          name: "Ice cream sandwich",
          type: "Digital",
          date_update: 9.0,
          inventory: 37,
          status: 4.3,
        },
        {
          name: "Eclair",
          type: "Physical",
          date_update: 16.0,
          inventory: 23,
          status: 6.0,
        },
        {
          name: "Cupcake",
          type: "Physical",
          date_update: 3.7,
          inventory: 67,
          status: 4.3,
        },
        {
          name: "Gingerbread",
          type: "Services",
          date_update: 16.0,
          inventory: 49,
          status: 3.9,
        },
        {
          name: "Jelly bean",
          type: "Tickets",
          date_update: 0.0,
          inventory: 94,
          status: 0.0,
        },
        {
          name: "Lollipop",
          type: "Tickets",
          date_update: 0.2,
          inventory: 98,
          status: 0,
        },
        {
          name: "Honeycomb",
          type: "Membership",
          date_update: 3.2,
          inventory: 87,
          status: 6.5,
        },
        {
          name: "Donut",
          type: "Membership",
          date_update: 25.0,
          inventory: 51,
          status: 4.9,
        },
        {
          name: "KitKat",
          type: "Archived",
          date_update: 26.0,
          inventory: 65,
          status: 7,
        },
      ],
      history: [
        {
          date: "30 NOVEMBER 2020",
          details: [
            {
              text: "Referral earnings (RM5) credited.",
              time: "4.12 PM",
            },
            {
              text: "Registered as affiliates for Project 1.",
              time: "4.07 PM",
            },
            {
              text: "User landed on Project 1 register site.",
              time: "4.06 PM",
            },
          ],
        },
        {
          date: "16 NOVEMBER 2020",
          details: [
            {
              text:
                "Order confirmation email for order #1234 sent to this customer.",
              time: "4.12 PM",
            },
            {
              text:
                "Customer placed an order #1234 in Marketing Book, funnel Checkout.",
              time: "4.07 PM",
            },
            {
              text: "Customer attempt to make payment and failed.",
              time: "4.06 PM",
            },
          ],
        },
        {
          date: "13 NOVEMBER 2020",
          details: [
            {
              text: "Customer added email to Marketing Book's mailing list.",
              time: "11.32 AM",
            },
            {
              text: "Customer was created.",
              time: "11.06 AM",
            },
          ],
        },
      ],
    }),
    computed: {
      dateRangeText() {
        return this.datePick.join(" - ");
      },
      extension() {
        return this.file ? this.file.name.split(".").pop() : "";
      },
    },
    methods: {
      getColor(type) {
        if (type == "Archived") return "grey";
        else return "green";
      },
      onChange(e) {
        var files = e.target.files || e.dataTransfer.files;

        if (!files.length) {
          this.dragging = false;
          return;
        }

        this.createFile(files[0]);
      },
      createFile(file) {
        if (file.size > 5000000) {
          alert("please check file size no over 5 MB.");
          this.dragging = false;
          return;
        }

        this.file = file;
        console.log(this.file);
        this.dragging = false;
      },
      removeFile() {
        this.file = "";
      },
      addTag() {
        this.allTags.push(this.tagValue);
        this.tagValue = null;
      },
    },
  };
</script>

<style scoped></style>
