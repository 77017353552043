import { render, staticRenderFns } from "./appLayerIcon.vue?vue&type=template&id=60b52b32&scoped=true&"
import script from "./appLayerIcon.vue?vue&type=script&lang=js&"
export * from "./appLayerIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60b52b32",
  null
  
)

export default component.exports