//import * as types from './mutation-types'

// eslint-disable-next-line no-unused-vars
export const fetchCampaigns = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get('/api/campaign').then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const fetchCampaign = ({ commit, dispatch, state }, campaign_id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('/api/campaign/'+campaign_id).then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const postCampaign = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('/api/campaign/store', data).then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const updateCampaign = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.put('/api/campaign/'+data.id, data).then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const updateCampaignStatus = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('/api/campaign/status/update', data).then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const deleteCampaign = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete(`/api/campaign/${id}`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}