<template>
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.2396 18.8333C14.842 18.8333 18.5729 15.1023 18.5729 10.5C18.5729 5.89759 14.842 2.16663 10.2396 2.16663C5.63721 2.16663 1.90625 5.89759 1.90625 10.5C1.90625 15.1023 5.63721 18.8333 10.2396 18.8333Z" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.2396 13.8333C12.0805 13.8333 13.5729 12.3409 13.5729 10.5C13.5729 8.65901 12.0805 7.16663 10.2396 7.16663C8.39863 7.16663 6.90625 8.65901 6.90625 10.5C6.90625 12.3409 8.39863 13.8333 10.2396 13.8333Z" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.34766 4.60828L7.88099 8.14161" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.5977 12.8583L16.131 16.3916" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.5977 8.14161L16.131 4.60828" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.5977 8.14162L15.5393 5.19995" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.34766 16.3916L7.88099 12.8583" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "app-life-buoy-icon"
});
</script>

<style scoped>

</style>
