import * as types from './mutation-types'
import * as appType from '../../mutation-types'
import jwtToken from "../../../helpers/jwt-token";

// eslint-disable-next-line no-unused-vars
export const login = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post('/api/auth/login', data).then((res) => {
      let user = res.data.user;
      jwtToken.setToken(res.data.token);
      commit(types.SET_TOKEN, res.data.token);
      commit(types.SET_USER, user)
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

// eslint-disable-next-line no-unused-vars
export const register = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post('/api/auth/sign-up', data).then((res) => {
      let user = res.data.user;
      jwtToken.setToken(res.data.token);
      commit(types.SET_TOKEN, res.data.token);
      commit(types.SET_USER, user)
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

// eslint-disable-next-line no-unused-vars
export const verify = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post('/api/auth/register/verify', {token: data}).then((res) => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

// eslint-disable-next-line no-unused-vars
export const logout = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    window.axios.post('/api/auth/logout').then((res) => {
      jwtToken.removeToken();
      commit(types.UNSET_USER)
      commit(types.UNSET_TOKEN)
      commit(appType.UPDATE_APP_LOADING_STATUS, false, { root: true })
      resolve(res)
    })
      .catch((err) => {
        console.log('Error', err.message)
        reject(err)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export const unsetUser = ({ commit, dispatch, state }) => {
  jwtToken.removeToken();
  commit(types.UNSET_USER)
  commit(types.UNSET_TOKEN)
}


// eslint-disable-next-line no-unused-vars
export const signPlan = ({commit, dispatch, state}, payload) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/plans/sign-plan`, payload).then((res) => {
      commit(types.SET_USER_PLAN, res.data.user.plan)
      resolve(res)
    })
        .catch((err) => {
          reject(err)
        })
  })
}
// eslint-disable-next-line no-unused-vars
export const storeBillingInfo = ({commit, dispatch, state}, payload) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/plans/store-billing-info`, payload).then((res) => {
      commit(types.SET_USER_BILLING_INFO, res.data.billing_info)
      resolve(res)
    })
        .catch((err) => {
          reject(err)
        })
  })
}


// eslint-disable-next-line no-unused-vars
export const deselectPlan = ({commit, dispatch, state}) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    commit(types.UNSET_USER_PLAN)
    resolve(true)
  })
}


// eslint-disable-next-line no-unused-vars
export const stripeSetupIntent = ({commit, dispatch, state}) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/stripe/setup-intent`).then((res) => {
      console.log(res)
      commit(types.SET_USER_BILLING_INFO, res.data.data.intent)
      resolve(res)
    })
      .catch((err) => {
        reject(err)
      })
  })
}