<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.1584 11.1748L11.1834 17.1498C11.0286 17.3048 10.8448 17.4277 10.6424 17.5116C10.4401 17.5955 10.2232 17.6386 10.0042 17.6386C9.78516 17.6386 9.56828 17.5955 9.36595 17.5116C9.16362 17.4277 8.97981 17.3048 8.82502 17.1498L1.66669 9.99984V1.6665H10L17.1584 8.82484C17.4688 9.13711 17.643 9.55953 17.643 9.99984C17.643 10.4401 17.4688 10.8626 17.1584 11.1748V11.1748Z" stroke="black" stroke-width="1.38889" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.83331 5.8335H5.84206" stroke="black" stroke-width="1.38889" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "app-tag-icon"
});
</script>

<style scoped>

</style>
