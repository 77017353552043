<template>
  <v-app>
    <v-app-bar app>
      <img src="/assets/app/img/logo.png" alt="fusion-logo" />
    </v-app-bar>
    <v-main class="main-login-layout">
      <v-container
          fluid
      >
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script type="text/babel">
export default {
  watch: {
    $route: 'onRouteChange'
  },
  mounted () {
    this.setLayoutClasses()
  },
  methods: {
    setLayoutClasses () {
    }
  },
  onRouteChange () {
  }
}
</script>

