import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'
// import Ls from '@/services/ls'

const initialState = {
  products: [],
  types: [],
  statuses: ['Enable', 'Disable', 'Archive'],
  pricing_types: ['Price increase over time', 'None'],
  over_time: ['Every Day', 'Every 7 Days', 'Every 30 Days'],
}

export default {
  namespaced: true,

  state: initialState,

  getters: getters,

  actions: actions,

  mutations: mutations
}
