<template>
  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2803 0.21967C13.5732 0.512563 13.5732 0.987437 13.2803 1.28033L5.03033 9.53033C4.73744 9.82322 4.26256 9.82322 3.96967 9.53033L0.21967 5.78033C-0.0732233 5.48744 -0.0732233 5.01256 0.21967 4.71967C0.512563 4.42678 0.987437 4.42678 1.28033 4.71967L4.5 7.93934L12.2197 0.21967C12.5126 -0.0732233 12.9874 -0.0732233 13.2803 0.21967Z" fill="#4BCE6E"/>
  </svg>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "app-vector-stroke-icon"
});
</script>

<style scoped>

</style>
